import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api.service";

export const getAllAnnouncement = createAsyncThunk("announcement/all", async (arg) => {
    const { data } = await api.get(
        `/api/announcement/all?approval_status=${arg.approval ?? ''}&sort[created]=desc`
    );

    return data;
});

export const getListAnnouncement = createAsyncThunk("announcement/list", async (arg) => {
    const { data } = await api.get(
        `/api/announcement?approval_status=${arg.approval ?? ''}&page=${arg.id}&sort[created]=${arg.sort ?? "desc"}&search=${
            arg.keyword ?? ""
        }`
    );

    return data;
});

export const getDetailAnnouncement = createAsyncThunk("announcement/detail", async (arg) => {
    const { data } = await api.get(`/api/announcement/${arg.id}`);

    return data;
});

export const getLatestAnnouncement = createAsyncThunk("announcement/latest", async () => {
    const { data } = await api.get(`/api/announcement/latest?approval_status=4`);

    return data;
});

export const getPopUpAnnouncement = createAsyncThunk("announcement/popup", async () => {
    const { data } = await api.get(`/api/announcement/get-popup?approval_status=4`);

    return data;
});

export const deleteAnnouncement = createAsyncThunk("announcement/delete", async (arg) => {
    const { data } = await api.delete(`/api/announcement/${arg.id}`);

    return data;
});

export const addAnnouncement = createAsyncThunk("announcement/add", async (arg) => {
    const { data } = await api.post(`/api/announcement`, arg.data);

    return data;
});

export const editAnnouncement = createAsyncThunk("announcement/edit", async (arg) => {
    const { data } = await api.post(`/api/announcement/${arg.id}`, arg.data);

        return data;
    }
);

export const updateApprovalAnnouncement = createAsyncThunk("announcement/approval", async (arg) => {
    const { data } = await api.post(`/api/announcement/${arg.id}/approval`, arg.data);

        return data;
    }
);