import PropTypes from "prop-types";

function NavNextPrevDetailChapter({
    label = "Navigation",
    disableNavigation = false,
    className = "text-white bg-red-800 rounded-l hover:bg-red-900",
    disableClassName = "bg-gray-300 rounded-l hover:bg-gray-400 text-gray-800",
    onClickNavigation = (e) => {},
}) {
    return (
        <>
            <div
                onClick={(e) => {
                    !disableNavigation && onClickNavigation(e);
                }}
                className={`px-4 py-2 font-bold cursor-pointer ${
                    disableNavigation ? disableClassName : className
                }`}
            >
                {label}
            </div>
        </>
    );
}

NavNextPrevDetailChapter.prototype = {
    label: PropTypes.string,
    disableNavigation: PropTypes.bool,
    className: PropTypes.string,
    disableClassName: PropTypes.string,
    onClickNavigation: PropTypes.func.isRequired,
};

export default NavNextPrevDetailChapter;
