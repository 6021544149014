import React, { Component } from "react";
import { connect } from "react-redux";
import { SearchIcon } from "@heroicons/react/outline";
import Select from "../../component/Select";
import ListItem from "../../component/ListItem";
import Pagination from "rc-pagination";
import Layout from "../Layout";
import { getListAnnouncement } from "../../actions/announcement";
import Loading from "../../component/Loading";

class ListAnnouncement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [
                { label: "Sort Latest", value: "desc" },
                { label: "Sort Oldest", value: "asc" },
            ],
            selectedOption: { label: "Sort Latest", value: "desc" },
            optionShow: false,
            searchKeyword: "",
        };
    }

    componentDidMount() {
        this.fetchAnnouncement();
    }

    fetchAnnouncement = async (data) => {
        const { dispatch, announcementList } = this.props;
        dispatch(
            getListAnnouncement({
                id: data?.current ?? "",
                sort: data?.sort,
                keyword: data?.keyword,
                approval: 4
            })
        )
            .unwrap()
            .then((res) => {
                this.setState({
                    currentPage: data?.current,
                    totalPages: announcementList?.meta?.total,
                });
            })
            .catch((err) => {
                console.error(err);
            });
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    changeOption = async (selected) => {
        //protects too many requests for the same from the user
        if (this.state.selectedOption.value === selected.value) return;

        await this.setState({
            optionShow: false,
            selectedOption: selected,
        });

        this.fetchAnnouncement({
            current: this.state.currentPage,
            sort: selected.value,
            keyword: this.state.searchKeyword,
        });
    };

    onSearch = (e) => {
        e.preventDefault();
        this.fetchAnnouncement({
            sort: this.state.selectedOption.value,
            keyword: this.state.searchKeyword,
        });
    };

    showSelect = () => {
        this.setState({ optionShow: !this.state.optionShow });
    };
    render() {
        const { announcementList, loading } = this.props;
        return (
            <Layout>
                <div>
                    <div className="container flex justify-center py-5">
                        <div className="px-3 py-2 rounded-md bg-[#f8fafd] flex justify-center gap-5">
                            <form
                                className="flex items-center h-full gap-5"
                                onSubmit={this.onSearch}
                            >
                                <input
                                    type="text"
                                    className="w-[588px] h-full p-2 bg-white rounded focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                                    placeholder="Search by title"
                                    value={this.state.searchKeyword}
                                    onChange={(e) =>
                                        this.setState({
                                            searchKeyword: e.target.value,
                                        })
                                    }
                                />
                                <button
                                    type="submit"
                                    className="h-full px-3 py-2 text-white transition-all duration-300 bg-red-700 rounded-md hover:bg-red-800"
                                >
                                    <SearchIcon
                                        className="w-5 h-5 text-white"
                                        aria-hidden="true"
                                    />
                                </button>
                            </form>
                            <Select
                                options={this.state.options}
                                onClick={this.showSelect}
                                onChange={(selected) =>
                                    this.changeOption(selected)
                                }
                                show={this.state.optionShow}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="container px-14">
                        {loading ? (
                            <div className="flex justify-center h-screen py-12 mt-5">
                                <Loading />
                            </div>
                        ) : (
                            <>
                                <ListItem
                                    name="announcement"
                                    data={announcementList ? announcementList.data : []}
                                    itemLink="/announcement"
                                    className="h-[220px]"
                                />

                                <hr className="my-6" />
                                <div className="flex justify-center w-full pb-6">
                                    <Pagination
                                        current={
                                            announcementList?.meta?.current_page ?? 1
                                        }
                                        onChange={(current) =>
                                            this.fetchAnnouncement({
                                                current: current,
                                                sort: this.state.selectedOption
                                                    .value,
                                                keyword:
                                                    this.state.searchKeyword,
                                            })
                                        }
                                        total={announcementList?.meta?.total}
                                        showTitle={false}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    const { announcementList, loading } = state.announcement;
    return {
        announcementList,
        loading,
    };
}

export default connect(mapStateToProps)(ListAnnouncement);
