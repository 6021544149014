import { toast } from "react-toastify";

export const toastSuccess = (msg, props = {}) => {
    Object.assign(props, { position: toast.POSITION.TOP_CENTER })
    toast.success(msg, props);
}

export const toastError = (msg) =>
    toast.warning(msg, {
        position: toast.POSITION.TOP_CENTER,
    });

export const toastUnauthorized = () =>
    toast.warning("You aren't authorized to do this", {
        position: toast.POSITION.TOP_CENTER,
    });
