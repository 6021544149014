import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api.service";

export const getAllEvent = createAsyncThunk("event/all", async (arg) => {
    const { data } = await api.get(
        `/api/event/all?approval_status=${arg.approval ?? ''}&sort[created]=desc`
    );

    return data;
});

export const getListEvent = createAsyncThunk("event/list", async (arg) => {
    const { data } = await api.get(
        `/api/event?approval_status=${arg.approval ?? ''}&page=${arg.id}&sort[created]=${arg.sort ?? "desc"}&search=${
            arg.keyword ?? ""
        }`
    );

    return data;
});

export const getDetailEvent = createAsyncThunk("event/detail", async (arg) => {
    const { data } = await api.get(`/api/event/${arg.id}`);

    return data;
});

export const getRandomEvent = createAsyncThunk("event/random", async ({ limit }) => {
    const { data } = await api.get(`/api/event/random?approval_status=4&limit=${limit}`);

    return data;
});

export const deleteEvent = createAsyncThunk("event/delete", async (arg) => {
    const { data } = await api.delete(`/api/event/${arg.id}`);

    return data;
});

export const addEvent = createAsyncThunk("event/add", async (arg) => {
    const { data } = await api.post(`/api/event`, arg.data);

    return data;
});

export const editEvent = createAsyncThunk("event/edit", async (arg) => {
    const { data } = await api.post(`/api/event/${arg.id}`, arg.data);

        return data;
    }
);

export const updateApprovalEvent = createAsyncThunk("event/approval", async (arg) => {
    const { data } = await api.post(`/api/event/${arg.id}/approval`, arg.data);

        return data;
    }
);