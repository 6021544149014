import { createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { addAnnouncement, deleteAnnouncement, getAllAnnouncement, getDetailAnnouncement, getLatestAnnouncement, getListAnnouncement } from "../actions/announcement";

const initialState = {
    loading: false,
    announcementData: [],
    announcementList: {},
    announcementDetail: {},
    announcementLatest: {},
    announcementDelete: {},
    announcementAdd: {},
    error: null,
    success: false,
};

const announcementSlice = createSlice({
    name: "announcement",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllAnnouncement.fulfilled, (state, { payload }) => {
                state.announcementData = payload.data.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                );
            })
            .addCase(getListAnnouncement.fulfilled, (state, { payload }) => {
                state.announcementList = payload;
            })
            .addCase(getDetailAnnouncement.fulfilled, (state, { payload }) => {
                state.announcementDetail = payload;
            })
            .addCase(getLatestAnnouncement.fulfilled, (state, { payload }) => {
                state.announcementLatest = payload;
            })
            .addCase(deleteAnnouncement.fulfilled, (state, { payload }) => {
                state.announcementDelete = payload;
            })
            .addCase(addAnnouncement.fulfilled, (state, { payload }) => {
                state.announcementAdd = payload;
            })
            .addMatcher(isPending, (state) => {
                state.loading = true
            })
            .addMatcher(isFulfilled, (state) => {
                state.loading = false
            })
            .addMatcher(isRejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })
    },
});

export default announcementSlice.reducer;
