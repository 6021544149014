import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
// Import TinyMce
import tinymce from "tinymce";
// CSS
import "tinymce/themes/silver";
import "tinymce/skins/ui/oxide/skin.css";
// Default Icons
import "tinymce/icons/default";

// Plugins
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/advlist";
import { addNews, editNews, getListNews } from "../../../../actions/news";
import { connect, useDispatch, useSelector } from "react-redux";
import Layout from "../../../Layout";
import Loading from "../../../../component/Loading";
import { toastError, toastSuccess } from "../../../../component/Toast";
import { useHistory } from "react-router-dom";
import capitalize from "../../../../utils/Capitalize";
import defaultImage from "../../../../utils/DefaultImage";

const CreateNews = () => {
    const [statusDraft, setStatusDraft] = useState(0);
    const [draftId, setDraftId] = useState(null);
    const [picture, setPicture] = useState(null);
    const [imgData, setImgData] = useState(null);
    const [content, setContent] = useState();
    const [title, setTitle] = useState();
    const dispatch = useDispatch();
    const news = useSelector((state) => state.news);
    const history = useHistory();

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        control,
        reset,
        getValues,
        clearErrors,
        setError,
    } = useForm();

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            if (getValues("title") === "" || getValues("content") === "")
                return;
            onDraftChange(getValues());
        }, 3000);

        return () => clearTimeout(delayDebounce);
    }, [content, title]);

    //handling image change
    const handleChange = (e) => {
        clearErrors("image");

        let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp)$/i;
        let filePath = e.target.value;
        if (!allowedExtensions.exec(filePath)) {
            setError("image", {
                message: "Only JPEG, JPG, PNG & WEBP allowed!",
            });
            setImgData(null);
            return;
        }

        if (e.target?.files[0]?.size > 2000000) {
            setError("image", {
                message: "Maximum image size 2Mb",
            });
            setImgData(null);
            return;
        }

        setPicture(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setImgData(reader.result); //set img data with url so it can showed after open
        });
        reader.readAsDataURL(e.target.files[0]);
        setValue("image", e.target.files[0]); //manually set value image for form
    };

    //handling tinymce on change
    const handleEditorChange = (change) => {
        setContent(change);
        setValue("content", change);
        clearErrors("content");
    };

    const onDraftChange = async (data) => {
        let fd = new FormData();
        const image = await defaultImage();
        if (picture) {
            fd.append("image", picture);
        } else {
            fd.append("image", image);
        }

        fd.append("title", data.title);
        fd.append("content", data.content);
        fd.append("approval_status", Number(1));
        if (statusDraft === 0) {
            dispatch(addNews({ data: fd }))
                .unwrap()
                .then((res) => {
                    setDraftId(res.data.id);
                    setStatusDraft(1);
                })
                .catch((err) => {
                    toastError(
                        capitalize(
                            "Warning! News failed to auto save as a draft"
                        )
                    );
                });
        } else {
            fd.append("_method", "PUT");
            dispatch(editNews({ id: draftId, data: fd }))
                .unwrap()
                .catch((err) => {
                    toastError(
                        capitalize(
                            "Warning! News failed to auto save as a draft"
                        )
                    );
                });
        }
    };

    const onSubmit = async (data) => {
        let fd = new FormData();
        if (picture) {
            fd.append("image", picture);
        }
        fd.append("title", data.title);
        fd.append("content", data.content);
        fd.append("approval_status", Number(2));

        // dispatch create news and store data
        if (statusDraft === 0) {
            dispatch(addNews({ data: fd }))
                .unwrap()
                .then((data) => {
                    toastSuccess(
                        capitalize("the new news was added successfully"),
                        {
                            onClose: () => {
                                history.push("/admin/news");
                            },
                        }
                    );
                    reset();
                    setImgData(null);
                    dispatch(
                        getListNews(
                            news.newsList
                                ? news.newsList?.meta?.current_page
                                : 1
                        )
                    );
                })
                .catch((err) => {
                    toastError(err.message);
                });
        } else {
            fd.append("_method", "PUT");
            dispatch(editNews({ id: draftId, data: fd }))
                .unwrap()
                .then((data) => {
                    toastSuccess(
                        capitalize("the new news was added successfully"),
                        {
                            onClose: () => {
                                history.push("/admin/news");
                            },
                        }
                    );
                    reset();
                    setImgData(null);
                    dispatch(
                        getListNews(
                            news.newsList
                                ? news.newsList?.meta?.current_page
                                : 1
                        )
                    );
                })
                .catch((err) => {
                    toastError(err.message);
                });
        }
    };

    const onDeleteImage = () => {
        setImgData(null);
        setPicture(null);
        setValue("image", null);
    };

    return (
        <Layout>
            <ToastContainer />
            <div className="container min-h-screen p-12">
                <h1 className="mb-5 text-xl font-bold">Add News</h1>
                <div className="px-4 py-6 bg-gray-200 shadow-lg card">
                    <form onSubmit={handleSubmit(onSubmit)} className="w-11/12">
                        <div className="mb-6 md:w-1/2">
                            <label
                                htmlFor="division"
                                className="block mb-2 text-sm font-semibold text-blue-900"
                            >
                                Title
                            </label>
                            <input
                                type="text"
                                name="title-news"
                                id="title-news"
                                {...register("title", {
                                    required: "Title is required",
                                })}
                                onChange={(e) => setTitle(e.target.value)}
                                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                            />
                            {errors?.title && (
                                <div className="text-xs font-semibold text-red-500">
                                    {errors.title.message}
                                </div>
                            )}
                        </div>
                        <div className="mb-6 md:w-6/12">
                            <label
                                htmlFor="image"
                                className="block mb-2 text-sm font-semibold text-blue-900"
                            >
                                News Image
                            </label>
                            <p className="mb-2 text-xs italic">
                                (Max Size: 2Mb; Extension: jpeg, jpg, png, webp)
                            </p>
                            <input
                                accept="image/webp, image/png, image/jpg, image/jpeg"
                                type="file"
                                {...register("image", {
                                    required: "Image is required",
                                })}
                                name="image"
                                id="image"
                                onChange={handleChange}
                                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                            />
                            {errors?.image && (
                                <div className="mt-2 text-xs font-semibold text-red-500">
                                    {errors.image.message}
                                </div>
                            )}
                        </div>
                        {picture && (
                            <div className="flex mb-6 md:w-1/2">
                                <img
                                    className="w-11/12"
                                    src={imgData}
                                    alt={getValues.image}
                                />
                                <div className="relative w-8">
                                    <button
                                        onClick={() => onDeleteImage()}
                                        className="absolute top-0 right-0 flex items-center justify-center p-1 text-red-700 border border-black bg-opacity-80"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={3.5}
                                            stroke="currentColor"
                                            className="w-4 h-4"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        )}
                        <div className="w-full mb-6">
                            <label
                                htmlFor="content"
                                className="block mb-2 text-sm font-semibold text-blue-900"
                            >
                                Content
                            </label>
                            <Controller
                                name="content"
                                control={control}
                                defaultValue=""
                                render={({ onChange, value }) => (
                                    <Editor
                                        {...register("content", {
                                            required: "Please fill the content",
                                        })}
                                        cloudChannel="dev"
                                        name="content"
                                        init={{
                                            selector: "textarea",
                                            height: "480",
                                            skin: false,
                                            plugins:
                                                "link image textpattern lists advlist anchor",
                                            menubar:
                                                "file edit view insert format",
                                            toolbar:
                                                "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link image",
                                            statusbar: false,
                                            /* enable title field in the Image dialog*/
                                            image_title: true,
                                            /* enable automatic uploads of images represented by blob or data URIs*/
                                            automatic_uploads: true,
                                            file_picker_types: "image",
                                            /* and here's our custom image picker*/

                                            file_picker_callback: function (
                                                cb,
                                                value,
                                                meta
                                            ) {
                                                let input =
                                                    document.createElement(
                                                        "input"
                                                    );
                                                input.setAttribute(
                                                    "type",
                                                    "file"
                                                );
                                                input.setAttribute("accept", [
                                                    "image/webp",
                                                    "image/png",
                                                    "image/jpg",
                                                    "image/jpeg",
                                                ]);

                                                input.onchange = function () {
                                                    let file = this.files[0];
                                                    clearErrors("content");

                                                    let filePath = this.value;
                                                    let allowedExtensions =
                                                        /(\.jpg|\.jpeg|\.png|\.webp)$/i;
                                                    if (
                                                        !allowedExtensions.exec(
                                                            filePath
                                                        )
                                                    ) {
                                                        setError("content", {
                                                            message:
                                                                "Only JPEG, JPG, PNG & WEBP allowed!",
                                                        });
                                                        toastError(
                                                            errors?.content &&
                                                                errors.content
                                                                    .message
                                                        );
                                                        return;
                                                    }

                                                    if (file.size > 2000000) {
                                                        setError("content", {
                                                            message:
                                                                "Maximum image size 2Mb",
                                                        });
                                                        toastError(
                                                            errors?.content &&
                                                                errors.content
                                                                    .message
                                                        );
                                                        return;
                                                    }

                                                    let reader =
                                                        new FileReader();
                                                    reader.onload =
                                                        function () {
                                                            let id =
                                                                "blobid" +
                                                                new Date().getTime();
                                                            let blobCache =
                                                                tinymce
                                                                    .activeEditor
                                                                    .editorUpload
                                                                    .blobCache;
                                                            let base64 =
                                                                reader.result.split(
                                                                    ","
                                                                )[1];
                                                            let blobInfo =
                                                                blobCache.create(
                                                                    id,
                                                                    file,
                                                                    base64
                                                                );
                                                            blobCache.add(
                                                                blobInfo
                                                            );

                                                            /* call the callback and populate the Title field with the file name */
                                                            cb(
                                                                blobInfo.blobUri(),
                                                                {
                                                                    title: file.name,
                                                                }
                                                            );
                                                        };
                                                    reader.readAsDataURL(file);
                                                };

                                                input.click();
                                            },
                                        }}
                                        value={content}
                                        onEditorChange={handleEditorChange}
                                    />
                                )}
                            />
                            {errors?.content && (
                                <div className="mt-2 text-xs font-semibold text-red-500">
                                    {errors?.content.message}
                                </div>
                            )}
                        </div>

                        <div className="flex justify-between">
                            <a
                                href="/admin/news"
                                className="w-24 px-4 py-2 text-sm font-semibold tracking-wider text-center text-white bg-gray-400 rounded hover:bg-gray-700"
                            >
                                Cancel
                            </a>
                            <button
                                className={`w-24 px-4 py-2 text-sm font-semibold tracking-wider text-white rounded text-center flex justify-center items-center bg-green-600 hover:bg-green-700 disabled:bg-gray-400 disabled:cursor-not-allowed`}
                                disabled={
                                    Object.keys(errors).length !== 0 ||
                                    news.loading ||
                                    statusDraft === 0
                                }
                            >
                                {news.loading ? (
                                    <Loading color="text-white" size={5} />
                                ) : (
                                    "Publish"
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

function mapStateToProps(state) {
    const { loading } = state.news;
    return {
        loading,
    };
}

export default connect(mapStateToProps)(CreateNews);
