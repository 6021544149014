import React, { Component } from "react";
import reactDom from "react-dom";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import ProtectedRoute from "./component/ProtectedRoute";

import Login from "./Login";
import Home from "./pages/Homepages";
import Division from "./pages/division/IndexDivision";
import DivisionDetail from "./pages/division/DetailDivision";
import FreshDivisionDetail from "./pages/division/FreshDetailDivision";

import adminDivision from "./pages/admin/division/Index";
import createDivision from "./pages/admin/division/Create/Index";
import infoDivision from "./pages/admin/division/Info/Index";

import adminUser from "./pages/admin/user/Index";
import createUser from "./pages/admin/user/Create/Index";
import infoUser from "./pages/admin/user/Info/Index";

import adminChapter from "./pages/admin/chapter/Index";
import createChapter from "./pages/admin/chapter/Create/Index";
import infoChapter from "./pages/admin/chapter/Info/Index";

import adminRole from "./pages/admin/role/Index";
import createRole from "./pages/admin/role/Create/Index";
import infoRole from "./pages/admin/role/Info/Index";

import adminNews from "./pages/admin/news/Index";
import createNews from "./pages/admin/news/Create/Index";
import infoNews from "./pages/admin/news/Info/Index";
import DetailNews from "./pages/news/DetailNews";
import ListNews from "./pages/news/ListNews";
import approvalNews from "./pages/admin/news/Approval/Index";

import DetailAnnouncement from "./pages/announcement/DetailAnnouncement";
import ListAnnouncement from "./pages/announcement/ListAnnouncement";

import adminAnnouncement from "./pages/admin/announcement/Index";
import createAnnouncement from "./pages/admin/announcement/Create/Index";
import infoAnnouncement from "./pages/admin/announcement/Info/Index";
import approvalAnnouncement from "./pages/admin/announcement/Approval/Index";

import DetailEvent from "./pages/event/DetailEvent";
import ListEvent from "./pages/event/ListEvent";
import adminEvent from "./pages/admin/event/Index";
import createEvent from "./pages/admin/event/Create/Index";
import InfoEvent from "./pages/admin/event/Info";

import adminDashboard from "./pages/admin/Index";
import adminConfig from "./pages/admin/config/Index";

import "./index.css";
import axios from "axios";
import { Provider } from "react-redux";
import store from "./app/store";
import "./assets/styles/rc_pagination.css";

const history = createBrowserHistory();

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        history.push("/login");
        return Promise.reject(error);
    }
);

class Index extends Component {
    render () {
        return (
            <Provider store={store}>
            <div className="min-h-screen">
                <div>
                    <Router history={history}>
                        <Switch>
                            <Route exact path="/">
                                <Redirect to="/login" />
                            </Route>

                            <Route path="/login" component={Login} />
                            <ProtectedRoute
                                path="/fresh-detail-division/:post_id"
                                component={FreshDivisionDetail}
                            />

                            <ProtectedRoute path="/home" component={Home} />

                            <ProtectedRoute exact path="/news" component={ListNews} />
                            <ProtectedRoute path="/news/:news_id" component={DetailNews} />
                            
                            <ProtectedRoute path="/division" component={Division} />
                            <ProtectedRoute
                                path="/detail-division/:post_id"
                                component={DivisionDetail}
                            />

                            <ProtectedRoute
                                path="/admin/division/create"
                                exact
                                component={createDivision}
                            />
                            <ProtectedRoute
                                path="/admin/division/:id"
                                component={infoDivision}
                            />
                            <ProtectedRoute
                                path="/admin/division"
                                component={adminDivision}
                            />

                            <ProtectedRoute
                                path="/admin/user/create"
                                exact
                                component={createUser}
                            />
                            <ProtectedRoute
                                path="/admin/user/:id"
                                component={infoUser}
                            />
                            <ProtectedRoute
                                path="/admin/user"
                                component={adminUser}
                            />

                            <ProtectedRoute
                                path="/admin/chapter/create"
                                exact
                                component={createChapter}
                            />
                            <ProtectedRoute
                                path="/admin/chapter/:id"
                                component={infoChapter}
                            />
                            <ProtectedRoute
                                path="/admin/chapter"
                                component={adminChapter}
                            />

                            <ProtectedRoute
                                path="/admin/role/create"
                                exact
                                component={createRole}
                            />
                            <ProtectedRoute
                                path="/admin/role/:id"
                                component={infoRole}
                            />
                            <ProtectedRoute
                                path="/admin/role"
                                component={adminRole}
                            />

                            {/* News */}
                            <ProtectedRoute
                                path="/admin/news/create"
                                exact
                                component={createNews}
                            />
                            <ProtectedRoute
                                exact
                                path="/admin/news/:id"
                                component={infoNews}
                            />
                            
                            <ProtectedRoute
                                path="/admin/news/approval/:id"
                                component={approvalNews}
                            />

                            <ProtectedRoute
                                path="/admin/news"
                                component={adminNews}
                            />
                            <ProtectedRoute exact path="/news" component={ListNews} />
                            <ProtectedRoute path="/news/:news_id" component={DetailNews} />

                            {/* Announcement */}
                            <ProtectedRoute exact path="/announcement" component={ListAnnouncement} />
                            <ProtectedRoute path="/announcement/:announcement_id" component={DetailAnnouncement} />
                            
                            <ProtectedRoute
                                path="/admin/announcement/create"
                                component={createAnnouncement}
                            />
                            <ProtectedRoute
                                exact
                                path="/admin/announcement/:id"
                                component={infoAnnouncement}
                            />
                            <ProtectedRoute
                                path="/admin/announcement/approval/:id"
                                component={approvalAnnouncement}
                            />
                            <ProtectedRoute
                                path="/admin/announcement"
                                component={adminAnnouncement}
                            />

                            {/* Event */}
                            <ProtectedRoute exact path="/event" component={ListEvent} />
                            <ProtectedRoute path="/event/:event_id" component={DetailEvent} />
                            <ProtectedRoute
                                path="/admin/event/create"
                                component={createEvent}
                            />
                            <ProtectedRoute
                                exact
                                path="/admin/event/:id"
                                component={InfoEvent}
                            />
                            <ProtectedRoute
                                path="/admin/event"
                                component={adminEvent}
                            />

                            <ProtectedRoute
                                path="/admin/"
                                component={adminDashboard}
                            />
                            <ProtectedRoute
                                path="/admin/config"
                                component={adminConfig}
                            />
                        </Switch>
                    </Router>
                </div>
            </div>
            </Provider>
        );
    }
}

reactDom.render(<Index />, document.getElementById("root"));
