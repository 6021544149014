import React from "react";
import { SearchIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";

const Search = (props) => {
    return (
        <form
            className="flex items-center h-full gap-5"
            onSubmit={props.onSubmit}
        >
            <input
                type="text"
                className="w-[588px] h-full p-2 bg-white rounded focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => props.onChange(e)}
            />
            <button
                type="submit"
                className="h-full px-3 py-2 text-white transition-all duration-300 bg-red-700 rounded-md hover:bg-red-800"
            >
                <SearchIcon className="w-5 h-5 text-white" aria-hidden="true" />
            </button>
        </form>
    );
};

Search.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default Search;
