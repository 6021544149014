import React, { Component } from "react";
import DashboardCard from "../../component/AdminDashboardCard";
import Layout from "../Layout";
import { getPermission } from "../../services/storage.service";
import { FaBookOpen, FaBullhorn, FaCog, FaNewspaper, FaTasks, FaUserFriends, FaUsersCog } from "react-icons/fa";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userPermission: [],
        };
    }

    componentDidMount() {
        const userPermission = getPermission();
        this.setState({ userPermission: userPermission });
    }
    render() {
        const menuList = [
            {
                title: "User Managements",
                permission: [
                    "can_create_division",
                    "can_edit_division",
                    "can_create_role",
                    "can_edit_role",
                    "can_create_user",
                    "can_edit_user",
                ],
                items: [
                    {
                        title: "Division",
                        url: "/admin/division",
                        icon: <FaUsersCog />,
                        permission: [
                            "can_create_division",
                            "can_edit_division",
                        ],
                    },
                    {
                        title: "Access Permission",
                        url: "/admin/role",
                        icon: <FaTasks />,
                        permission: ["can_create_role", "can_edit_role"],
                    },
                    {
                        title: "User List",
                        url: "/admin/user",
                        icon: <FaUserFriends />,
                        permission: ["can_create_user", "can_edit_user"],
                    },
                ],
            },
            {
                title: "Content Management",
                permission: [
                    "can_create_news",
                    "can_edit_news",
                    "can_create_announcement",
                    "can_edit_announcement",
                    "can_create_event",
                    "can_edit_event",
                    "can_create_chapter",
                    "can_edit_chapter",
                ],
                items: [
                    {
                        title: "News",
                        url: "/admin/news",
                        icon: <FaNewspaper />,
                        permission: ["can_create_news", "can_edit_news"],
                    },
                    {
                        title: "Announcement",
                        url: "/admin/announcement",
                        icon: <FaUserFriends />,
                        permission: [
                            "can_create_announcement",
                            "can_edit_announcement",
                        ],
                    },
                    {
                        title: "Event",
                        url: "/admin/event",
                        icon: <FaBullhorn />,
                        permission: ["can_create_event", "can_edit_event"],
                    },
                    {
                        title: "Chapter",
                        url: "/admin/chapter",
                        icon: <FaBookOpen />,
                        permission: ["can_create_chapter", "can_edit_chapter"],
                    },
                ],
            },
            {
                title: "Config",
                items: [
                    {
                        title: "Config",
                        url: "/admin/config",
                        icon: <FaCog />,
                        permission: "can_view_configs",
                    },
                ],
            },
        ];

        return (
            <Layout>
                <div className="bg-slate-50">
                    <div className="container flex flex-col min-h-screen gap-6 py-6 px-14">
                        {menuList.map((menu, index) => {
                            if (
                                this.state.userPermission?.some((el) =>
                                    menu.permission?.includes(el)
                                )
                            ) {
                                return (
                                    <div key={index} className="flex flex-col">
                                        <h3 className="p-2 my-6 dashboard-title-card">
                                            {menu.title}
                                        </h3>
                                        <div className="flex flex-col md:flex-row">
                                            {menu.items.map(
                                                (item, index) =>
                                                    this.state.userPermission?.some(
                                                        (el) =>
                                                            item.permission?.includes(
                                                                el
                                                            )
                                                    ) && (
                                                        <div
                                                            key={index}
                                                            className="flex w-full md:w-1/4"
                                                        >
                                                            <DashboardCard
                                                                title={
                                                                    item.title
                                                                }
                                                                url={item.url}
                                                            >
                                                                <div className="m-6 text-6xl text-center">
                                                                    {item.icon}
                                                                </div>
                                                            </DashboardCard>
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Index;
