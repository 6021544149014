import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
// Import TinyMce
import tinymce from "tinymce";
// CSS
import "tinymce/themes/silver";
import "tinymce/skins/ui/oxide/skin.css";
// Default Icons
import "tinymce/icons/default";
// Plugins
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/advlist";
import {
    getDetailAnnouncement,
    getListAnnouncement,
    updateApprovalAnnouncement,
} from "../../../../actions/announcement";
import { getUser } from "../../../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../Layout";
import Loading from "../../../../component/Loading";
import moment from "moment";
import capitalize from "../../../../utils/Capitalize";
import { toastError, toastSuccess } from "../../../../component/Toast";

const ApprovalAnnouncement = () => {
    const [announcementDetail, setAnnouncementDetail] = useState({
        picture: null,
        imgData: null,
        content: null,
        creator: "",
        roleId: null,
        oldImg: "",
        approvalStatus: null,
        popup: 0,
        created_at: "",
    });
    const dispatch = useDispatch();
    const announcement = useSelector((state) => state.announcement);
    const auth = useSelector((state) => state.auth);
    const params = useParams();

    const history = useHistory();
    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        control,
        reset,
        getValues,
        clearErrors,
        setError,
    } = useForm();

    useEffect(() => {
        if (Object.keys(auth.user).length === 0) {
            dispatch(getUser());
        }

        dispatch(getDetailAnnouncement({ id: params.id }))
            .unwrap()
            .then((res) => {
                setValue("title", res.data.title);
                setValue("content", res.data.content);
                setValue("popup", res.data.is_display_popup);
                setAnnouncementDetail((prev) => ({
                    ...prev,
                    content: res.data.content,
                    creator: res.data.user.name,
                    oldImg: res.data.image,
                    roleId: res.data.role_category.id,
                    created_at: moment(res.data.created_at).format("LL"),
                }));
            })
            .catch((err) => {});
    }, []);

    const handleChange = (value) => {
        setAnnouncementDetail((prev) => ({ ...prev, approvalStatus: value }));
    };

    const onSubmit = async (data) => {
        let fd = new FormData();
        fd.append("_method", "PUT");
        fd.append("approval_status", Number(announcementDetail.approvalStatus));
        fd.append("approval_note", data.note ?? "");

        // dispatch update announcement and store data
        dispatch(updateApprovalAnnouncement({ id: params.id, data: fd }))
            .unwrap()
            .then((data) => {
                toastSuccess(
                    capitalize(data.message),
                    {
                        onClose: () => {
                            history.push("/admin/announcement");
                        },
                    }
                );
                reset();
                dispatch(
                    getListAnnouncement(
                        announcement.announcementList
                            ? announcement.announcementList?.meta?.current_page
                            : 1
                    )
                );
            })
            .catch((err) => {
                toastError(capitalize("Error! update announcement failed"));
            });
    };

    return (
        <Layout>
            <div className="min-h-scrren bg-[#f7fafc]">
                <ToastContainer />
                <div className="container p-14">
                    <h1 className="mb-5 text-xl font-bold">
                        Approval Announcement
                    </h1>
                    <div className="overflow-hidden bg-gray-200 shadow-lg card">
                        <div className="justify-between py-3 bg-white px-7">
                            <Link
                                to="/admin/announcement"
                                className="flex text-[#385f80] text-xl items-center font-bold gap-1 w-max"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                                    />
                                </svg>
                                Back
                            </Link>
                        </div>
                        <div className="w-11/12 py-12 bg-gray-200 px-7">
                            <div className="flex flex-col mb-6 md:w-1/2">
                                <label
                                    htmlFor="division"
                                    className="block mb-2 text-sm font-semibold text-blue-900"
                                >
                                    Creator
                                </label>
                                <span className="text-gray-500">
                                    {announcementDetail.creator}
                                </span>
                            </div>
                            <div className="flex flex-col mb-6 md:w-1/2">
                                <label
                                    htmlFor="division"
                                    className="block mb-2 text-sm font-semibold text-blue-900"
                                >
                                    Created Date
                                </label>
                                <span className="text-gray-500">
                                    {announcementDetail.created_at}
                                </span>
                            </div>
                            <div className="mb-6 md:w-full">
                                <label
                                    htmlFor="division"
                                    className="block mb-2 text-sm font-semibold text-blue-900"
                                >
                                    Announcement Title
                                </label>
                                <input
                                    type="text"
                                    name="title-announcement"
                                    id="title-announcement"
                                    {...register("title", {
                                        required: "Title is required",
                                    })}
                                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                                    disabled
                                />
                            </div>
                            <div className="mb-6 md:w-1/2">
                                <label
                                    htmlFor="image"
                                    className="block mb-2 text-sm font-semibold text-blue-900"
                                >
                                    Cover Image
                                </label>
                            </div>
                            <div className="flex mb-6">
                                {announcementDetail.picture ? (
                                    <img
                                        src={announcementDetail.imgData}
                                        alt={getValues.image}
                                        className="max-w-full"
                                    />
                                ) : (
                                    announcementDetail.oldImg && (
                                        <img
                                            src={announcementDetail.oldImg}
                                            alt="Banner announcement"
                                            className="max-w-full"
                                        />
                                    )
                                )}
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="pop-up"
                                    className="block mb-2 text-sm font-semibold text-blue-900"
                                >
                                    Mark as Pop-Up
                                </label>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        id="pop-up"
                                        type="checkbox"
                                        {...register("popup")}
                                        className="sr-only peer"
                                        disabled
                                    />
                                    <div className="w-11 h-6 bg-[#b0bec5] peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 "></div>
                                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 after:content-['Off'] peer-checked:after:content-['On']" />
                                </label>
                            </div>
                            <div className="w-full mb-6">
                                <label
                                    htmlFor="content"
                                    className="block mb-2 text-sm font-semibold text-blue-900"
                                >
                                    Content
                                </label>
                                <Controller
                                    name="content"
                                    control={control}
                                    defaultValue=""
                                    render={({ onChange, value }) => (
                                        <Editor
                                            {...register("content", {
                                                required:
                                                    "Please fill the content",
                                            })}
                                            disabled
                                            cloudChannel="dev"
                                            name="content"
                                            init={{
                                                selector: "textarea",
                                                height: "480",
                                                skin: false,
                                                plugins:
                                                    "link image textpattern lists advlist anchor",
                                                menubar:
                                                    "file edit view insert format",
                                                toolbar:
                                                    "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link image",
                                                statusbar: false,
                                                /* enable title field in the Image dialog*/
                                                image_title: true,
                                                /* enable automatic uploads of images represented by blob or data URIs*/
                                                automatic_uploads: true,
                                                file_picker_types: "image",
                                                /* and here's our custom image picker*/

                                                file_picker_callback: function (
                                                    cb,
                                                    value,
                                                    meta
                                                ) {
                                                    let input =
                                                        document.createElement(
                                                            "input"
                                                        );
                                                    input.setAttribute(
                                                        "type",
                                                        "file"
                                                    );
                                                    input.setAttribute(
                                                        "accept",
                                                        [
                                                            "image/webp",
                                                            "image/png",
                                                            "image/jpg",
                                                            "image/jpeg",
                                                        ]
                                                    );

                                                    input.onchange =
                                                        function () {
                                                            let file =
                                                                this.files[0];
                                                            clearErrors(
                                                                "content"
                                                            );

                                                            let filePath =
                                                                this.value;
                                                            let allowedExtensions =
                                                                /(\.jpg|\.jpeg|\.png|\.webp)$/i;
                                                            if (
                                                                !allowedExtensions.exec(
                                                                    filePath
                                                                )
                                                            ) {
                                                                setError(
                                                                    "content",
                                                                    {
                                                                        message:
                                                                            "Only JPEG, JPG, PNG & WEBP allowed!",
                                                                    }
                                                                );
                                                                toastError(
                                                                    errors?.content &&
                                                                        errors
                                                                            .content
                                                                            .message
                                                                );
                                                                return;
                                                            }

                                                            if (
                                                                file.size >
                                                                2000000
                                                            ) {
                                                                setError(
                                                                    "content",
                                                                    {
                                                                        message:
                                                                            "Maximum image size 2Mb",
                                                                    }
                                                                );
                                                                toastError(
                                                                    errors?.content &&
                                                                        errors
                                                                            .content
                                                                            .message
                                                                );
                                                                return;
                                                            }

                                                            let reader =
                                                                new FileReader();
                                                            reader.onload =
                                                                function () {
                                                                    let id =
                                                                        "blobid" +
                                                                        new Date().getTime();
                                                                    let blobCache =
                                                                        tinymce
                                                                            .activeEditor
                                                                            .editorUpload
                                                                            .blobCache;
                                                                    let base64 =
                                                                        reader.result.split(
                                                                            ","
                                                                        )[1];
                                                                    let blobInfo =
                                                                        blobCache.create(
                                                                            id,
                                                                            file,
                                                                            base64
                                                                        );
                                                                    blobCache.add(
                                                                        blobInfo
                                                                    );

                                                                    /* call the callback and populate the Title field with the file name */
                                                                    cb(
                                                                        blobInfo.blobUri(),
                                                                        {
                                                                            title: file.name,
                                                                        }
                                                                    );
                                                                };
                                                            reader.readAsDataURL(
                                                                file
                                                            );
                                                        };

                                                    input.click();
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-full h-[1px] bg-gray-400 mb-6" />
                            <div className="flex gap-4 mb-6">
                                <button
                                    type="button"
                                    className={`flex items-center justify-center gap-1 px-4 py-2 text-sm font-semibold tracking-wider text-center duration-300 border border-green-500 rounded hover:bg-green-500 hover:text-white ${
                                        announcementDetail.approvalStatus === 4
                                            ? "bg-green-500 text-white"
                                            : "text-green-500"
                                    }`}
                                    onClick={() => handleChange(4)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                    Approve
                                </button>
                                <button
                                    type="button"
                                    className={`flex items-center justify-center gap-1 px-4 py-2 text-sm font-semibold tracking-wider text-center duration-300 border border-blue-500 rounded hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white ${
                                        announcementDetail.approvalStatus === 3
                                            ? "bg-blue-500 text-white"
                                            : "text-blue-500"
                                    }`}
                                    onClick={() => handleChange(3)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                        />
                                    </svg>
                                    Revise
                                </button>
                                <button
                                    type="button"
                                    className={`flex items-center justify-center gap-1 px-4 py-2 text-sm font-semibold tracking-wider text-center duration-300 border border-red-500 rounded hover:bg-red-500 hover:text-white focus:bg-red-500 focus:text-white ${
                                        announcementDetail.approvalStatus === 0
                                            ? "bg-red-500 text-white"
                                            : "text-red-500"
                                    }`}
                                    onClick={() => handleChange(0)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                        />
                                    </svg>
                                    Reject
                                </button>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div
                                    className={`transition-all duration-300 ${
                                        announcementDetail.approvalStatus !==
                                            4 &&
                                        announcementDetail.approvalStatus !==
                                            null
                                            ? "max-h-80 opacity-100"
                                            : "max-h-0 opacity-0"
                                    }`}
                                >
                                    {announcementDetail.approvalStatus !== 4 &&
                                        announcementDetail.approvalStatus !==
                                            null && (
                                            <div
                                                className={`flex flex-col mb-6`}
                                            >
                                                <label
                                                    htmlFor="approvalNote"
                                                    className="block mb-2 text-sm font-semibold text-blue-900 max"
                                                >
                                                    Note
                                                </label>
                                                <textarea
                                                    rows="5"
                                                    {...register("note", {
                                                        required:
                                                            "Note is required",
                                                    })}
                                                    className={`p-1 text-sm rounded `}
                                                    autoFocus
                                                    required={
                                                        announcementDetail.approvalStatus !==
                                                            4 &&
                                                        announcementDetail.approvalStatus !==
                                                            null
                                                    }
                                                    disabled={
                                                        announcementDetail.approvalStatus ===
                                                        4
                                                    }
                                                ></textarea>
                                            </div>
                                        )}
                                </div>
                                <div className="w-full h-[1px] bg-gray-400 mb-6" />
                                <button
                                    className={`mt-6 w-24 px-4 py-2 text-sm font-semibold tracking-wider text-white rounded text-center flex justify-center items-center bg-green-600 hover:bg-green-700 transition-all duration-300 ${
                                        announcementDetail.approvalStatus !==
                                        null
                                            ? "max-h-80 opacity-100"
                                            : "max-h-0 opacity-0"
                                    }`}
                                    type="submit"
                                >
                                    {announcement.loading ? (
                                        <Loading color="text-white" size={5} />
                                    ) : (
                                        "Submit"
                                    )}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ApprovalAnnouncement;
