import { createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { getAllEvent, getRandomEvent, getListEvent } from "../actions/event";

const initialState = {
    loading: false,
    eventData: [],
    eventList: {},
    error: null,
    success: false,
};

const eventSlice = createSlice({
    name: "event",
    initialState,
    reducers: {
        addItem(state, action) {
            state.eventList.push(action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllEvent.fulfilled, (state, { payload }) => {
                state.eventData = payload.data
            })
            .addCase(getListEvent.fulfilled, (state, { payload }) => {
                state.eventList = payload;
            })
            .addMatcher(isPending, (state) => {
                state.loading = true
            })
            .addMatcher(isFulfilled, (state) => {
                state.loading = false
                state.success = true
            })
            .addMatcher(isRejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })
    },
});

export default eventSlice.reducer;
