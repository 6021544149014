import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllEvent } from "../../actions/event";
import Loading from "../../component/Loading";
import SectionHeader from "../../component/SectionHeader";

class IndexAnnouncement extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        if (this.props.eventData.length === 0)
            dispatch(getAllEvent({ approval: 4 }));
    }
    render() {
        const { eventData, loading } = this.props;
        const eventDataSlice = eventData.slice(0, 3);

        return (
            <div>
                <div className="relative">
                    <SectionHeader title="Event" link="/event" />
                </div>
                {loading ? (
                    <div className="flex items-center justify-center mt-5">
                        <Loading />
                    </div>
                ) : (
                    <>
                        {eventDataSlice?.length !== 0 ? (
                            eventDataSlice?.map((item) => (
                                <div
                                    key={item.id}
                                    className={`relative flex flex-row w-full gap-5 mt-6 cursor-default h-24`}
                                >
                                    <Link
                                        className="w-4/12"
                                        to={`event/${item.id}`}
                                    >
                                        <div className="w-full h-full flex flex-col justify-center items-center bg-[#f4f4f4] rounded-md py-3 hover:bg-gray-200 transition-all duration-150">
                                            <i className="fas fa-calendar-check text-[#c22125] text-2xl"></i>
                                            <div className="flex flex-col items-center text-[#385f80] font-bold text-sm">
                                                <span>
                                                    {moment(
                                                        item?.started_at
                                                    ).format("MMMM")}
                                                </span>
                                                <span>
                                                    {moment(
                                                        item?.started_at
                                                    ).format("Do")}
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="relative flex flex-col justify-between w-7/12 gap-2 text-blue-900">
                                        <div className="flex flex-col w-full">
                                            <Link to={`/event/${item.id}`}>
                                                <h1 className="mb-2 overflow-hidden text-lg font-bold text-ellipsis line-clamp-2 hover:underline">
                                                    {item.title}
                                                </h1>
                                            </Link>
                                        </div>
                                        <span className="flex items-center gap-1 text-xs font-semibold hover:text-[#c22125] w-max cursor-pointer text-[#777777]">
                                            More Info
                                        </span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="relative flex flex-row justify-center w-full gap-5 mt-6">
                                <span>No such event was found.</span>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { eventData, loading } = state.event;
    return {
        eventData,
        loading,
    };
}
export default connect(mapStateToProps)(IndexAnnouncement);
