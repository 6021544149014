import {
    createSlice,
    isFulfilled,
    isPending,
    isRejected,
} from "@reduxjs/toolkit";
import { getAllChapterPagesByChapterId,getAllChapterPagesByDivisionAndCategory } from "../actions/chapter-page";

const initialState = {
    loading: false,
    chapterPageLists: {},
};

const chapterPageSlice = createSlice({
    name: "chapterPage",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllChapterPagesByChapterId.fulfilled, (state, { payload }) => {
                state.chapterPageLists = payload.data;
            })
            .addCase(getAllChapterPagesByDivisionAndCategory.fulfilled, (state, { payload }) => {
                state.chapterPageLists = payload.data;
            })
            .addMatcher(isPending, (state) => {
                state.loading = true;
            })
            .addMatcher(isFulfilled, (state) => {
                state.loading = false;
            })
            .addMatcher(isRejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            });
    },
});

export default chapterPageSlice.reducer
