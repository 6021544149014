import React from "react";
import { Route, Redirect } from "react-router-dom";
import useIdle from "./IdleTimer";


export const ProtectedRoute = ({ component: Component, ...rest }) => {

    const logout = () => {
            localStorage.setItem('logged', false);
            window.location.href = '/login';
    }
        
    // auto logout after 1 hours of inactifity
    useIdle({ onIdle: logout, idleTime: 60 });
        
    return(
        <Route {...rest} render={ props => {
            const curentChapter = Number(localStorage.getItem('chapter'));
            const currentRole = String(localStorage.getItem('role'))

            if (JSON.parse(localStorage.getItem('logged')) === false) {
                return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            }

            if (curentChapter !== 0 && currentRole === 'user') {
                return <Redirect to={{ pathname: '/fresh-detail-division/'+ localStorage.getItem('division') }} />
            }

            return <Component {...props} />;
        }}/>
    )
}

export default ProtectedRoute