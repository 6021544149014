import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Layout from "../Layout";

const options = {
  margin: 15,
  dots: false,
  responsive: {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

class IndexDivision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      items: [],
      isHovered: false,
    };
    this.owlRef = React.createRef();

    if (
      localStorage.getItem("is_admin") !== "true" &&
      localStorage.getItem("chapter") !== "0"
    ) {
      this.props.history.push(
        "/fresh-detail-division/" + localStorage.getItem("division")
      );
    }
  }

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  componentDidMount() {
    const AuthStr = "Bearer ".concat(localStorage.getItem("token"));
    axios
      .get(`${process.env.REACT_APP_BASE_API}/api/division/all`, {
        headers: { Authorization: AuthStr },
      })
      .then((response) => {
        this.setState({ items: response.data.data });
      });
  }

  handleMouseWheel = (e) => {
    if (this.owlRef.current) {
      if (e.deltaY > 0) {
        this.owlRef.current.next(500);
      } else {
        this.owlRef.current.prev(500);
      }
    }
  };

  render() {
    const { isHovered } = this.state;
    const bodyStyles = isHovered
      ? "{ overflow: hidden; }"
      : "{ overflow: visible }";

    return (
      <Layout>
        <div className="min-h-screen p-5 bg-grey-f7fafc">
          <div className="mt-10">
            <article className="max-w-full text-2xl font-bold text-center">
              <h2 className="text-gray-385f80">Division Information</h2>
            </article>

            <div
              className="my-8"
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onWheel={this.handleMouseWheel}
            >
              {this.state.items.length > 0 && (
                <OwlCarousel
                  className="owl-theme division"
                  {...options}
                  ref={this.owlRef}
                >
                  {this.state.items.map((item) => (
                    <Link to={"/detail-division/" + item.id} key={item.id}>
                      <div className="w-full bg-gray-200 rounded-lg item hover:shadow-xl">
                        <div className="absolute z-10 w-full p-7">
                          <div className="p-3 border-l-4 border-white">
                            <h2 className="text-3xl text-white">{item.name}</h2>
                          </div>
                        </div>
                        <img
                          className="max-w-max"
                          src={item.image}
                          alt={item.name}
                        />
                      </div>
                    </Link>
                  ))}
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
        <style>{`
        body ${bodyStyles}
        ::-webkit-scrollbar {
            display: none !important;
        }
        `}</style>
      </Layout>
    );
  }
}

export default IndexDivision;
