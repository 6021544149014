import React, { useState } from 'react';
import Header from '../../../../component/Header';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useHistory } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../../Layout';


//variable restructured division options
let permissionList = [];

const CreateRole = () => {

    const history = useHistory();

    const success = () => toast.success("Role saved!", {
        position: toast.POSITION.TOP_CENTER,
        onClose: props => history.push("/admin/role")
    });

    const unauthorized = () => toast.warning("You aren't authorized to do this", {
        position: toast.POSITION.TOP_CENTER,
    });

    //state for error catching from server
    var [err, setErr] = useState({});

    //state if form has error form server
    var [hasError, setHasError] = useState(false);

    var [permissionGet, setPermissionGet] = useState(false)
    
    //fetching permission list
    const fetchPermission = React.useCallback(() => {
        axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API}/api/permission/list`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            })
            .then(response => {
                if(response.data.code !== 401){
                    let permissionRaw = response.data.datas
                    if (permissionRaw) {
                        //reconstruct permission value and label to fit requirement
                        for(let [key, value] of Object.entries(permissionRaw)) {
                            let permissionDivide = {}
                            permissionDivide.value = key
                            permissionDivide.label = value
                            permissionList.push(permissionDivide)
                        }
                    }
                    setPermissionGet(true)
                }
            });
    }, []);

    //fetching permission list on page load
    React.useEffect(() => {
        fetchPermission()
    }, [fetchPermission])

    const { handleSubmit, register, formState: { errors }, reset } = useForm();

    const onSubmit = (data) => {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_API}/api/role/store`,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            })
            .then(function (response){
                if (response.data.code !== 401) {
                    success();
                    reset();
                }else{
                    unauthorized();
                }
            })
            .catch(error => {
                if (error.response) {
                    setErr(error.response.data.errors);
                    setHasError(true);
                }
            });
    };

    //function to render error from server
    function ErrorComponent() {
        return Object.keys(err).map((visit, index) => 
            <ul className="list-none">
                <li className="p-3 mb-4 text-white bg-red-500 radius-sm" key={index}>{err[visit]}</li>
            </ul>
        )
    };

    //create component of permission list
    const PermissionComponent = () => {
        //return mapped permission list array
        return permissionList.map(item=>( 
            <div className="mb-6">
                <label className="flex items-start justify-start">
                    <div className="flex items-center justify-center w-6 h-6 mr-2 bg-white border-2 border-blue-900 rounded shrink-0 focus-within:border-blue-500">
                        <input 
                            type="checkbox" 
                            className="absolute opacity-0" 
                            id="permission"
                            value={item.value}
                            {...register('permission')}
                        />
                        <svg className="hidden w-4 h-4 text-blue-900 pointer-events-none fill-current" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
                    </div>
                    <div className="font-semibold text-blue-900 select-none">{item.label}</div>
                </label>
            </div>
        ))
    };

    return (
        <Layout>
            <Header/>
            <ToastContainer />
            <div className="min-h-screen p-12">
                <nav className="text-black font-bold mb-5 text-xs" aria-label="Breadcrumb">
                    <ol className="list-none p-0 inline-flex">
                        <li className="flex items-center">
                            <a href="/admin/role">Access Permission</a>
                            <svg className="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
                        </li>
                        <li>
                            <p className="text-gray-500" aria-current="page">Access Permission Create</p>
                        </li>
                    </ol>
                </nav>
                <h1 className="mb-5 text-xl font-bold">
                    Access Permission Create
                </h1>
                <div className="px-4 py-6 bg-gray-200 shadow-lg card">
                    {hasError && <ErrorComponent></ErrorComponent>}
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                        <div className="w-full mb-6 md:w-1/5">
                            <label for="name" className="block mb-2 text-sm font-semibold text-blue-900">Name</label>
                            <input 
                                type="text" 
                                name="name" 
                                id="name" 
                                {...register("name", { 
                                    required: 'Name is required', 
                                })}  
                                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                            {errors?.name && <div>{errors.name.message}</div>}
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-semibold text-blue-900">Permission</label>
                        </div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                        {permissionGet && <PermissionComponent></PermissionComponent>}
                        </div>
                        <div className="flex justify-between">
                            <a href="/admin/role" className="w-24 px-4 py-2 text-sm font-semibold tracking-wider text-center text-white bg-gray-400 rounded hover:bg-gray-700">Cancel</a>
                            <button type="submit" className="w-24 px-4 py-2 text-sm font-semibold tracking-wider text-white bg-green-600 rounded hover:bg-green-700">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default CreateRole