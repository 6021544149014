import React from 'react';
import { useForm } from "react-hook-form";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../Layout';

const AdminConfig = () => {
    const { handleSubmit, register, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(data);
    }
    return (
        <Layout>
            <ToastContainer />
            <div className="min-h-screen p-12">
                <nav className="text-black font-bold mb-5 text-xs" aria-label="Breadcrumb">
                    <ol className="list-none p-0 inline-flex">
                        <li className="flex items-center">
                            <a href="/admin/">Admin Dashboard</a>
                            <svg className="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
                        </li>
                        <li>
                            <p className="text-gray-500" aria-current="page">Config</p>
                        </li>
                    </ol>
                </nav>
                <h1 className="mb-5 text-xl font-bold">
                    Config
                </h1>
                <div className="px-4 py-6 bg-gray-200 shadow-lg card">
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-1/2">
                        <div className="mb-6">
                            <label for="date" className="block mb-2 text-sm font-semibold text-blue-900 md:inline-block">Auto delete rejected content</label>
                            <input
                                type="text"
                                name="date"
                                id="date"
                                {...register("date", {
                                    required: 'Auto delete is required'
                                })}
                                className="w-16 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md md:w-16 md:ml-4 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                            <span className="ml-4">days</span>
                            {errors?.date && <div>{errors.date.message}</div>}
                        </div>
                        <div className="flex justify-between mt-16">
                            <button className="w-24 px-4 py-2 text-sm font-semibold tracking-wider text-white bg-green-600 rounded hover:bg-green-700">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default AdminConfig;