import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";
import imageRectangle from "../assets/image_rectangle.png";
import PropTypes from "prop-types";
import ExpiredImage from "../assets/expired.png";

const ListItem = (props) => {
    const { withReadMore = true, withContent = true } = props;
    const dateNow = new moment();
    const removeImage = (str) => {
        let content = str.includes("img");
        let removeNbsp = str?.replace(/&nbsp;/g, " ");
        let newStr = removeNbsp?.replace(/<\/?[^>]+(>|$)/g, "");
        if (content) {
            const replaceImage = newStr?.replace(
                /<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
                ""
            );
            return replaceImage;
        }
        return newStr;
    };
    return (
        <>
            {props.data && props.data?.length !== 0 ? (
                props.data?.map((item) => (
                    <div
                        key={item.id}
                        className={`relative flex flex-row w-full gap-5 mt-6 cursor-default ${props.className}`}
                    >
                        <Link
                            className="w-4/12 relative flex items-center justify-center"
                            to={`${props.itemLink + "/" + item.id}`}
                        >
                            {props.name.toLowerCase() === "event" &&
                                moment(item.ended_at) < dateNow && (
                                    <div className="bg-[#c6c6c6]/80 flex justify-center items-center absolute w-full h-full">
                                        <img
                                            src={ExpiredImage}
                                            alt="Expired event"
                                        />
                                    </div>
                                )}
                            <img
                                src={item.image ?? imageRectangle}
                                className=" max-h-full max-w-full"
                                alt={item.title}
                            />
                        </Link>
                        <div className="relative flex flex-col justify-between w-7/12 gap-2 text-blue-900">
                            <div className="flex flex-col w-full">
                                {props.name.toLowerCase() === "event" && (
                                    <div className="flex gap-3 items-center font-bold">
                                        <i className="fas fa-calendar-check text-[#c22125] text-2xl"></i>
                                        <span className="text-sm">
                                            {moment(item?.started_at).format(
                                                "Do MMM YY"
                                            )}{" "}
                                            -{" "}
                                            {moment(item?.ended_at).format(
                                                "Do MMM YY"
                                            )}
                                        </span>
                                    </div>
                                )}
                                <Link to={`${props.itemLink + "/" + item.id}`}>
                                    <h1 className="mb-2 overflow-hidden text-lg font-bold text-ellipsis line-clamp-2 hover:underline">
                                        {item.title}
                                    </h1>
                                </Link>
                                {withReadMore &&
                                    props.name.toLowerCase() !== "event" && (
                                        <span className="mb-3 text-xs">
                                            {item.user.name
                                                ? item.user.name +
                                                  " / " +
                                                  moment(
                                                      item.created_at
                                                  ).fromNow()
                                                : moment(
                                                      item.created_at
                                                  ).fromNow()}
                                        </span>
                                    )}
                                {item.content && withContent && (
                                    <div className="relative w-full overflow-hidden">
                                        <p
                                            className={`overflow-hidden text-sm leading-7 break-words text-ellipsis ${
                                                props?.contentRow === 2
                                                    ? "line-clamp-2"
                                                    : "line-clamp-3"
                                            }`}
                                            name="content"
                                            dangerouslySetInnerHTML={{
                                                __html: removeImage(
                                                    item.content
                                                ),
                                            }}
                                        ></p>
                                    </div>
                                )}
                            </div>
                            {withReadMore ? (
                                <Link to={`${props.itemLink + "/" + item.id}`} className="flex items-center gap-1 text-xs font-semibold hover:text-[#c22125] w-max cursor-pointer">
                                    Read More
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                        />
                                    </svg>
                                </Link>
                            ) : (
                                <>
                                    {props.name.toLowerCase() !== "event" && (
                                        <span className="text-xs">
                                            {item.user.name
                                                ? item.user.name +
                                                  " / " +
                                                  moment(
                                                      item.created_at
                                                  ).fromNow()
                                                : moment(
                                                      item.created_at
                                                  ).fromNow()}
                                        </span>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <div className="relative flex flex-row justify-center w-full gap-5 mt-6">
                    <span>No such {props.name} was found.</span>
                </div>
            )}
        </>
    );
};

ListItem.propTypes = {
    name: PropTypes.string,
    itemLink: PropTypes.string,
    data: PropTypes.array,
    withReadMore: PropTypes.bool,
    withContent: PropTypes.bool,
    contentRow: PropTypes.number,
    className: PropTypes.string,
};

export default ListItem;
