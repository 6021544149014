import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
// Import TinyMce
import tinymce from "tinymce";
// CSS
import "tinymce/themes/silver";
import "tinymce/skins/ui/oxide/skin.css";
// Default Icons
import "tinymce/icons/default";
// Plugins
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/advlist";
import {
    editEvent,
    getDetailEvent,
    getListEvent,
} from "../../../../actions/event";
import { getUser } from "../../../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../Layout";
import Loading from "../../../../component/Loading";
import capitalize from "../../../../utils/Capitalize";
import { toastError, toastSuccess } from "../../../../component/Toast";
import moment from "moment";
import { IoClose } from "react-icons/io5";
import { IoIosAttach } from "react-icons/io";

const InfoEvent = () => {
    const [eventDetail, setEventDetail] = useState({
        picture: null,
        imgData: null,
        content: null,
        creator: "",
        oldImg: "",
        approvalStatus: 2,
        started_at: "",
        ended_at: "",
        oldAttachment: "",
    });
    const dispatch = useDispatch();
    const event = useSelector((state) => state.event);
    const auth = useSelector((state) => state.auth);
    const params = useParams();

    const history = useHistory();
    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        control,
        reset,
        getValues,
        clearErrors,
        setError,
    } = useForm();

    useEffect(() => {
        if (Object.keys(auth.user).length === 0) {
            dispatch(getUser());
        }

        dispatch(getDetailEvent({ id: params.id }))
            .unwrap()
            .then((res) => {
                setValue("title", res.data.title);
                setValue("content", res.data.content);
                setValue(
                    "started_at",
                    moment(res.data.started_at).format("yyyy-MM-DD")
                );
                setValue(
                    "ended_at",
                    moment(res.data.ended_at).format("yyyy-MM-DD")
                );

                const imageSplit = res.data.image.split("/");
                const attachmentSplit = res.data.attachment.split("/");
                setEventDetail((prev) => ({
                    ...prev,
                    content: res.data.content,
                    creator: res.data.user.name,
                    approvalStatus: res.data.approval_status,
                    oldImg: {
                        name: imageSplit[imageSplit.length - 1],
                        url: res.data.image,
                    },
                    started_at: res.data.started_at,
                    ended_at: res.data.ended_at,
                    oldAttachment: {
                        name: attachmentSplit[attachmentSplit.length - 1],
                        url: res.data.attachment,
                    },
                }));
            });
    }, []);

    //handling image change
    const handleChange = (e) => {
        clearErrors("img");

        let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp)$/i;
        let filePath = e.target.value;
        if (!allowedExtensions.exec(filePath)) {
            setError("img", {
                message: "Only JPEG, JPG, PNG & WEBP allowed!",
            });
            setEventDetail((prev) => ({ ...prev, imgData: null }));

            return;
        }

        if (e.target?.files[0]?.size > 2000000) {
            setError("img", {
                message: "Maximum image size 2Mb",
            });
            setEventDetail((prev) => ({ ...prev, imgData: null }));

            return;
        }

        setValue("image", e.target.files[0]); //manually set value image for form
        setEventDetail((prev) => ({ ...prev, picture: e.target.files[0] }));
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setEventDetail((prev) => ({ ...prev, imgData: reader.result })); //set img data with url so it can showed after open
        });
        reader.readAsDataURL(e.target.files[0]);
    };

    const handleChangeAttachment = (e) => {
        clearErrors("attachment");

        let allowedExtensions =
            /(\.docx|\.doc|\.xlsx|\.xls|\.zip|\.rar|\.7z|\.ppt|\.pptx|\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
        let filePath = e.target.value;
        if (!allowedExtensions.exec(filePath)) {
            setError("attachment", {
                message: "File extension not allowed!",
            });
            // setEventDetail((prev) => ({ ...prev, attachment: null }));
            return;
        }

        setValue("attachment", e.target.files[0]);
        return;
    };

    //handling tinymce on change
    const handleEditorChange = (change) => {
        setEventDetail((prev) => ({ ...prev, content: change }));
        setValue("content", change);
        clearErrors("content");
    };

    const onSubmit = async (data) => {
        const approvalStatus = localStorage.getItem("isAdmin") ? 4 : 2;
        let fd = new FormData();
        fd.append("_method", "PUT");
        if (data.image) {
            fd.append("image", data.image);
        }
        fd.append("title", data.title);
        fd.append("content", data.content);
        fd.append("started_at", data.started_at);
        fd.append("ended_at", data.ended_at);
        fd.append(
            "attachment",
            data.attachment.length !== 0 ? data.attachment : ""
        );
        fd.append("approval_status", Number(approvalStatus));
        // dispatch create event and store data
        dispatch(editEvent({ id: params.id, data: fd }))
            .unwrap()
            .then((data) => {
                toastSuccess(capitalize(data?.message), {
                    onClose: () => {
                        history.push("/admin/event");
                    },
                });
                reset();
                setEventDetail((prev) => ({ ...prev, imgData: null }));
                dispatch(
                    getListEvent(
                        event.eventList
                            ? event.eventList?.meta?.current_page
                            : 1
                    )
                );
            })
            .catch((err) => {
                toastError(capitalize("Error! Create event failed"));
            });
    };

    const onDeleteImage = () => {
        setEventDetail((prev) => ({
            ...prev,
            oldImg: null,
            picture: null,
            imgData: null,
        }));
    };

    return (
        <Layout>
            <div className="min-h-scrren bg-[#f7fafc]">
                <ToastContainer />
                <div className="container p-14">
                    <h1 className="mb-5 text-xl font-bold">Edit Event</h1>
                    <div className="bg-gray-200 shadow-lg card">
                        <div className="justify-between py-3 bg-white px-7">
                            <Link
                                to="/admin/event"
                                className="flex text-[#385f80] text-xl items-center font-bold gap-1 w-max"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                                    />
                                </svg>
                                Back
                            </Link>
                        </div>
                        <div className="py-12 bg-gray-200 px-7">
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="w-11/12"
                            >
                                <div className="mb-6 md:w-1/2">
                                    <label
                                        htmlFor="division"
                                        className="block mb-2 text-sm font-semibold text-blue-900"
                                    >
                                        Creator
                                    </label>
                                    {auth?.loading ? (
                                        <div className="animate-pulse">
                                            <div className="h-4 rounded-full w-28 bg-slate-300"></div>
                                        </div>
                                    ) : (
                                        <span className="text-gray-500">
                                            {auth?.user?.name}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-6 md:w-1/2">
                                    <label
                                        htmlFor="division"
                                        className="block mb-2 text-sm font-semibold text-blue-900"
                                    >
                                        Event Title
                                    </label>
                                    <input
                                        type="text"
                                        name="title-event"
                                        id="title-event"
                                        {...register("title", {
                                            required: "Title is required",
                                        })}
                                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                                    />
                                    {errors?.title && (
                                        <div className="text-xs font-semibold text-red-500">
                                            {errors.title.message}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-6">
                                    <label
                                        htmlFor="image"
                                        className="block mb-2 text-sm font-semibold text-blue-900"
                                    >
                                        Cover Image
                                    </label>
                                    <p className="mb-2 text-xs italic">
                                        (Max Size: 2Mb; Extension: jpeg, jpg,
                                        png, webp)
                                    </p>
                                    <div className="flex items-center gap-3">
                                        <label
                                            htmlFor="image"
                                            className="px-6 py-3 text-sm text-white bg-green-600 rounded cursor-pointer"
                                        >
                                            File Upload
                                        </label>
                                        {eventDetail.picture ? (
                                            errors.img === undefined && (
                                                <span className="text-sm text-gray-500">
                                                    {getValues("image.name")}
                                                </span>
                                            )
                                        ) : (
                                            <span className="text-sm text-gray-500">
                                                {eventDetail.oldImg?.name}
                                            </span>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        accept="image/webp, image/png, image/jpg, image/jpeg"
                                        hidden
                                        {...register("img", {
                                            required: "Image is required",
                                        })}
                                        name="image"
                                        id="image"
                                        onChange={handleChange}
                                    />
                                    {errors?.img && (
                                        <div className="mt-2 text-xs font-semibold text-red-500">
                                            {errors.img.message}
                                        </div>
                                    )}
                                </div>
                                <div className="flex mb-6">
                                    <div className="w-auto">
                                        {eventDetail.picture ? (
                                            <img
                                                src={eventDetail.imgData}
                                                alt={getValues.image}
                                                className="max-w-full"
                                            />
                                        ) : (
                                            eventDetail.oldImg && (
                                                <img
                                                    src={eventDetail.oldImg.url}
                                                    alt="Banner announcement"
                                                    className="max-w-full"
                                                />
                                            )
                                        )}
                                    </div>
                                    {eventDetail.oldImg !== null ||
                                    (eventDetail.picture &&
                                        errors.img === undefined) ? (
                                        <button
                                            className="relative top-0 flex items-center justify-center p-1 ml-1 text-red-700 border border-red-700 w-7 h-7 bg-opacity-80"
                                            type="button"
                                            onClick={onDeleteImage}
                                        >
                                            <IoClose />
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                    {/* </div> */}
                                </div>
                                <div className="w-6/12 mb-6">
                                    <label className="block mb-2 text-sm font-semibold text-blue-900">
                                        Date
                                    </label>
                                    <div className="flex items-start">
                                        <div>
                                            <input
                                                name="started_at"
                                                type="date"
                                                {...register("started_at", {
                                                    required:
                                                        "Start date is required",
                                                })}
                                                className="w-full px-3 py-2 text-sm placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                                                placeholder="Select date start"
                                            />
                                            {errors?.started_at && (
                                                <div className="mt-2 text-xs font-semibold text-red-500">
                                                    {errors?.started_at.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex items-center py-2">
                                            <span className="mx-4">to</span>
                                        </div>
                                        <div>
                                            <input
                                                name="ended_at"
                                                type="date"
                                                {...register("ended_at", {
                                                    required:
                                                        "End date is required",
                                                })}
                                                className="w-full px-3 py-2 text-sm placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                                                placeholder="Select date end"
                                            />
                                            {errors?.ended_at && (
                                                <div className="mt-2 text-xs font-semibold text-red-500">
                                                    {errors?.ended_at.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full mb-6">
                                    <label
                                        htmlFor="content"
                                        className="block mb-2 text-sm font-semibold text-blue-900"
                                    >
                                        Content
                                    </label>
                                    <Controller
                                        name="content"
                                        control={control}
                                        defaultValue=""
                                        render={({ onChange, value }) => (
                                            <Editor
                                                {...register("content", {
                                                    required:
                                                        "Please fill the content",
                                                })}
                                                cloudChannel="dev"
                                                name="content"
                                                init={{
                                                    selector: "textarea",
                                                    height: "480",
                                                    skin: false,
                                                    plugins:
                                                        "link image textpattern lists advlist anchor",
                                                    menubar:
                                                        "file edit view insert format",
                                                    toolbar:
                                                        "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link image",
                                                    statusbar: false,
                                                    /* enable title field in the Image dialog*/
                                                    image_title: true,
                                                    /* enable automatic uploads of images represented by blob or data URIs*/
                                                    automatic_uploads: true,
                                                    file_picker_types: "image",
                                                    /* and here's our custom image picker*/

                                                    file_picker_callback:
                                                        function (
                                                            cb,
                                                            value,
                                                            meta
                                                        ) {
                                                            let input =
                                                                document.createElement(
                                                                    "input"
                                                                );
                                                            input.setAttribute(
                                                                "type",
                                                                "file"
                                                            );
                                                            input.setAttribute(
                                                                "accept",
                                                                [
                                                                    "image/webp",
                                                                    "image/png",
                                                                    "image/jpg",
                                                                    "image/jpeg",
                                                                ]
                                                            );

                                                            input.onchange =
                                                                function () {
                                                                    let file =
                                                                        this
                                                                            .files[0];
                                                                    clearErrors(
                                                                        "content"
                                                                    );

                                                                    let filePath =
                                                                        this
                                                                            .value;
                                                                    let allowedExtensions =
                                                                        /(\.jpg|\.jpeg|\.png|\.webp)$/i;
                                                                    if (
                                                                        !allowedExtensions.exec(
                                                                            filePath
                                                                        )
                                                                    ) {
                                                                        setError(
                                                                            "content",
                                                                            {
                                                                                message:
                                                                                    "Only JPEG, JPG, PNG & WEBP allowed!",
                                                                            }
                                                                        );
                                                                        toastError(
                                                                            errors?.content &&
                                                                                errors
                                                                                    .content
                                                                                    .message
                                                                        );
                                                                        return;
                                                                    }

                                                                    if (
                                                                        file.size >
                                                                        2000000
                                                                    ) {
                                                                        setError(
                                                                            "content",
                                                                            {
                                                                                message:
                                                                                    "Maximum image size 2Mb",
                                                                            }
                                                                        );
                                                                        toastError(
                                                                            errors?.content &&
                                                                                errors
                                                                                    .content
                                                                                    .message
                                                                        );
                                                                        return;
                                                                    }

                                                                    let reader =
                                                                        new FileReader();
                                                                    reader.onload =
                                                                        function () {
                                                                            let id =
                                                                                "blobid" +
                                                                                new Date().getTime();
                                                                            let blobCache =
                                                                                tinymce
                                                                                    .activeEditor
                                                                                    .editorUpload
                                                                                    .blobCache;
                                                                            let base64 =
                                                                                reader.result.split(
                                                                                    ","
                                                                                )[1];
                                                                            let blobInfo =
                                                                                blobCache.create(
                                                                                    id,
                                                                                    file,
                                                                                    base64
                                                                                );
                                                                            blobCache.add(
                                                                                blobInfo
                                                                            );

                                                                            /* call the callback and populate the Title field with the file name */
                                                                            cb(
                                                                                blobInfo.blobUri(),
                                                                                {
                                                                                    title: file.name,
                                                                                }
                                                                            );
                                                                        };
                                                                    reader.readAsDataURL(
                                                                        file
                                                                    );
                                                                };

                                                            input.click();
                                                        },
                                                }}
                                                value={eventDetail.content}
                                                onEditorChange={
                                                    handleEditorChange
                                                }
                                            />
                                        )}
                                    />
                                    {errors?.content && (
                                        <div className="mt-2 text-xs font-semibold text-red-500">
                                            {errors?.content.message}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-6">
                                    <label
                                        htmlFor="Attachment"
                                        className="block mb-2 text-sm font-semibold text-blue-900"
                                    >
                                        Attachment
                                    </label>
                                    <p className="mb-2 text-xs italic">
                                        (Extension: docx, doc, xlsx, xls, zip,
                                        rar, 7z, ppt, pptx, jpg, jpeg, png, gif,
                                        pdf)
                                    </p>
                                    <div className="flex items-center gap-3">
                                        <label
                                            htmlFor="attachment"
                                            className="px-6 py-3 text-sm text-white bg-green-600 rounded cursor-pointer"
                                        >
                                            File Upload
                                        </label>
                                        {getValues("attachment")?.length !==
                                        0 ? (
                                            errors.attachment === undefined && (
                                                <span className="text-sm text-gray-500">
                                                    {getValues(
                                                        "attachment.name"
                                                    )}
                                                </span>
                                            )
                                        ) : (
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={
                                                    eventDetail.oldAttachment
                                                        ?.url
                                                }
                                                className="flex items-center gap-1 text-sm text-gray-500 underline"
                                            >
                                                {
                                                    eventDetail.oldAttachment
                                                        ?.name
                                                }{" "}
                                                <IoIosAttach size={18} />
                                            </a>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        hidden
                                        {...register("attachment")}
                                        name="attachment"
                                        id="attachment"
                                        onChange={handleChangeAttachment}
                                    />
                                    {errors?.attachment && (
                                        <div className="mt-2 text-xs font-semibold text-red-500">
                                            {errors.attachment.message}
                                        </div>
                                    )}
                                </div>
                                <div className="flex justify-between mt-14">
                                    <a
                                        href="/admin/event"
                                        className="w-24 px-4 py-2 text-sm font-semibold tracking-wider text-center text-white bg-gray-400 rounded hover:bg-gray-700"
                                    >
                                        Cancel
                                    </a>
                                    <button
                                        className={`w-24 px-4 py-2 text-sm font-semibold tracking-wider text-white rounded text-center flex justify-center items-center bg-green-600 hover:bg-green-700 disabled:bg-gray-400 disabled:cursor-not-allowed`}
                                        disabled={
                                            Object.keys(errors).length !== 0 ||
                                            event.loading
                                        }
                                    >
                                        {event.loading ? (
                                            <Loading
                                                color="text-white"
                                                size={5}
                                            />
                                        ) : (
                                            "Publish"
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default InfoEvent;
