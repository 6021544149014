import React, { useState } from "react";
import DropDown from "../assets/icon_drop_down.svg";
import PropTypes from "prop-types";

const Select = (props) => {
    const [selected, setSelected] = useState({});
    const onChange = (option) => {
        setSelected(option);
        props.onChange(option);
    };
    return (
        <div className="relative flex items-center w-40 gap-3">
            <button
                type="button"
                className="flex items-center justify-between w-full p-2 bg-[#e8ebf1] rounded ring-1 ring-gray-300 text-[#385f80]"
                onClick={() => props.onClick()}
            >
                <span>
                    {props.defaultSelected
                        ? props.defaultSelected.label
                        : Object.keys(selected).length !== 0
                        ? selected.label
                        : props.options[0].label}
                </span>
                <img src={DropDown} alt="Drop Down"></img>
            </button>

            {props.show && (
                <ul className="absolute z-20 w-full mt-2 rounded bg-gray-50 ring-1 ring-gray-300 top-10">
                    {props.options?.map((option, index) => (
                        <li
                            key={index}
                            className="p-2 cursor-pointer select-none hover:bg-gray-200"
                            onClick={() =>
                                onChange({
                                    label: option.label,
                                    value: option.value,
                                })
                            }
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

Select.propTypes = {
    options: PropTypes.array.isRequired,
    show: PropTypes.bool.isRequired,
    defaultSelected: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Select;
