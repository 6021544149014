import React, { useState } from "react";
import Header from "../../../../component/Header";
import AsyncSelect from "react-select/async";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
// Import TinyMce
import tinymce from "tinymce";
// CSS
import "tinymce/themes/silver";
import "tinymce/skins/ui/oxide/skin.css";
// Default Icons
import "tinymce/icons/default";

// Plugins
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/advlist";

import { useParams, useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Breadcrumb from "../../../../component/Breadcrumb";
import Layout from "../../../Layout";
import { disableOnWheel } from "../../../../utils/DisableOnWheel";
import { Link } from "react-router-dom";

import NavNextPrevDetailChapter from "../Navigation/NavNextPrevDetailChapter";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllChapterPagesByDivisionAndCategory } from "../../../../actions/chapter-page";
import Loading from "../../../../component/Loading";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        display: "flex",
        background: "#fff",
        border: "1px solid rgb(209, 213, 219)",
        padding: "2px .1rem",
    }),
    menu: () => ({
        //   none of react-select's styles are passed to <Control />
        padding: "0",
        background: "#fff",
        position: "absolute",
        top: "100%",
        boxShadow:
            "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)",
        width: "100%",
        zIndex: "5",
    }),
};

//temporary response data
let responseData = [];

//options data for categories select
const categories = [
    { value: "0", label: "Division Journey" },
    { value: "1", label: "Division Chapter" },
];
//options data for division select
const options = [];

const EditChapter = () => {
    const history = useHistory();

    let [content, setContent] = useState();
    let [pagenumb, setPagenumb] = useState();
    let [selecteddivision, setSelecteddivision] = useState();
    let [selectedcategory, setSelectedcategory] = useState();
    let [chapterid, setChapterid] = useState();
    const [loadingPage, setLoadingPage] = useState();

    const dispatch = useDispatch();

    //state for error catching from server
    var [err, setErr] = useState({});

    //state if form has error form server
    var [hasError, setHasError] = useState(false);

    //get id from params
    const { id } = useParams();

    const success = () =>
        toast.success(
            <span>
                Data submitted! |{" "}
                <Link
                    to="/admin/chapter"
                    className="underline transition-all duration-150 underline-offset-4 hover:text-white/80"
                >
                    Go back
                </Link>
            </span>,
            {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                hideProgressBar: true,
                closeButton: false,
                style: {
                    borderRadius: "10px",
                    cursor: "default",
                },
                bodyStyle: {
                    borderRadius: "20px",
                    textAlign: "center",
                },
            }
        );

    const successChapter = () =>
        toast.success("Chapter saved!", {
            position: toast.POSITION.TOP_CENTER,
            onClose: (props) => history.push("/admin/chapter"),
        });

    const unauthorizedSee = () =>
        toast.warning("You aren't authorized to see this chapter page!", {
            position: toast.POSITION.TOP_CENTER,
        });

    const unauthorizedChapter = () =>
        toast.warning("You aren't authorized to edit chapter", {
            position: toast.POSITION.TOP_CENTER,
        });

    const unauthorizedPage = () =>
        toast.warning("You aren't authorized to edit chapter page", {
            position: toast.POSITION.TOP_CENTER,
        });

    const error = (message) =>
        toast.warning(message, {
            position: toast.POSITION.TOP_CENTER,
        });

    // declare fetching division list data
    const fetchData = React.useCallback(() => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_BASE_API}/api/division/all`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }).then((response) => {
            if (response.data.code !== 401) {
                responseData = response.data.data;
                if (responseData.length > 0) {
                    //restructure response for division select
                    responseData.forEach((division) => {
                        let divisionDivide = {};
                        divisionDivide.value = division.id;
                        divisionDivide.label = division.name;
                        options.push(divisionDivide);
                    });
                }
            }
        });
    }, []);

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        setError,
    } = useForm();

    //fetch chapter info based on id
    const fetchInfo = React.useCallback(() => {
        setLoadingPage(true);
        axios({
            method: "get",
            url: `${process.env.REACT_APP_BASE_API}/api/chapter/page/${id}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((response) => {
                if (response.data.code !== 401) {
                    setValue("order", Number(response.data.data.order));
                    setValue("title", response.data.data.title);
                    setValue("content", response.data.data.content);
                    setValue("category", response.data.data.chapter?.category);
                    setValue(
                        "division_id",
                        response.data.data.chapter?.division?.id
                    );
                    const defaultcategorySelect = {
                        value: response.data.data.chapter?.category,
                        label: response.data.data.chapter?.category_description,
                    };
                    const defaultdivisionSelect = {
                        value: response.data.data.chapter?.division?.id,
                        label: response.data.data.chapter?.division?.name,
                    };
                    setSelectedcategory(defaultcategorySelect);
                    setSelecteddivision(defaultdivisionSelect);

                    setOriginCategory(response.data.data.chapter?.category);
                    setOriginDivision(response.data.data.chapter?.division.id);

                    setPagenumb(response.data.data.page);
                    setChapterid(response.data.data.chapter?.id);
                    handleEditorChange(response.data.data.content);
                } else {
                    unauthorizedSee();
                }
            })
            .catch((response) => {
                setLoadingPage(false);
            })
            .finally(() => {
                setLoadingPage(false);
            });
    }, [id]);

    //declare onsubmit function, when submitting form and put category/division first before send page
    const onSubmit = (data) => {
        let fd = {
            category: data.category,
            division_id: data.division_id,
        };

        axios({
            method: "put",
            url: `${process.env.REACT_APP_BASE_API}/api/chapter/${chapterid}`,
            data: fd,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then(function (response) {
                if (response.data.code === 401) {
                    unauthorizedChapter();
                } else {
                    // successChapter()
                    sendPage(data); //send page after success
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                }
            });
    };

    const sendPage = (data) => {
        let sendpage = {
            chapter_id: chapterid,
            order: data.order,
            title: data.title,
            content: data.content,
            page: pagenumb,
        };

        axios({
            method: "put",
            url: `${process.env.REACT_APP_BASE_API}/api/chapter/page/${id}`,
            data: sendpage,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then(function (response) {
                if (response.data.code !== 401) {
                    success();
                } else {
                    unauthorizedPage();
                }
            })
            .catch((error) => {
                if (error.response.data.errors) {
                    setErr(error.response.data.errors);
                } else {
                    setErr(error);
                }
                setHasError(true);
            });
    };

    // fetching division list data
    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    // fetching user data
    React.useEffect(() => {
        fetchInfo();
    }, [fetchInfo]);

    //handling division on change
    const handleChange = (change) => {
        setSelecteddivision(change);
        setValue("division_id", change.value);
    };

    //handling category on change
    const handleChangeCategory = (change) => {
        setSelectedcategory(change);
        setValue("category", change.value);
    };

    //handling tinymce on change
    const handleEditorChange = (change) => {
        setContent(change);
        setValue("content", change);
    };

    //function to render error from server
    function ErrorComponent() {
        return Object.keys(err).map((visit, index) => (
            <ul className="list-none">
                <li
                    className="p-3 mb-4 text-white bg-red-500 radius-sm"
                    key={index}
                >
                    {err[visit]}
                </li>
            </ul>
        ));
    }

    const breadcrumb = [
        { pageTitle: "Home", pageHref: "/admin" },
        { pageTitle: "Chapter", pageHref: "/admin/chapter" },
        { pageTitle: "Edit", pageHref: "" },
    ];

    /**
     * handler logic of prev and next navigation of chapter
     */
    const onClickNavNextChapter = (event) => {
        let nextChapterPageId =
            chapterPageStore.chapterPageLists[indexChapterPage + 1]?.id ?? null;
        if (!nextChapterPageId) {
            return;
        }
        history.push(`/admin/chapter/${nextChapterPageId}`);
    };
    const onClickNavPrevChapter = (event) => {
        let prevChapterPageId =
            chapterPageStore.chapterPageLists[indexChapterPage - 1]?.id ?? null;
        if (!prevChapterPageId) {
            return;
        }
        history.push(`/admin/chapter/${prevChapterPageId}`);
    };
    // state and lifecycle control prev and next navigation of chapter
    const [disableNavNextChapter, setDisableNavNextChapter] = useState(true);
    const [disableNavPrevChapter, setDisableNavPrevChapter] = useState(true);
    const [originDivision, setOriginDivision] = useState(null);
    const [originCategory, setOriginCategory] = useState(null);

    // store current index of chapter to support logic decide next or prev chapter should be show
    const [indexChapterPage, setIndexChapterPage] = useState(0);

    // store length the lists of siblings chapter page, this to support logic for decide disable state of prev and nex navigation
    const [lengthChapterPage, setLengthChapterPage] = useState(0);

    // used to get chapterPage which had same id with current chapterPage->chapter->id
    const chapterPageStore = useSelector((state) => state.chapterPage);

    useEffect(() => {
        if (originDivision !== null && originCategory !== null) {
            dispatch(
                getAllChapterPagesByDivisionAndCategory({
                    divisionId: originDivision,
                    categoryId: originCategory,
                })
            );
        }
    }, [originDivision, originCategory]);

    useEffect(() => {
        setLengthChapterPage(chapterPageStore.chapterPageLists?.length ?? 0);
        let currentChapterPageIndex = chapterPageStore.chapterPageLists.length
            ? chapterPageStore.chapterPageLists.findIndex((chapterPage) => {
                  return String(chapterPage.id) === id;
              })
            : 0;
        setIndexChapterPage(currentChapterPageIndex);
    }, [chapterPageStore, id]);

    useEffect(() => {
        setDisableNavNextChapter(
            indexChapterPage + 1 === lengthChapterPage ||
                lengthChapterPage === 0
        );
        setDisableNavPrevChapter(
            indexChapterPage === 0 || lengthChapterPage === 0
        );
    }, [lengthChapterPage, indexChapterPage]);

    return (
        <Layout>
            <ToastContainer />
            <div className="min-h-screen p-12">
                <Breadcrumb data={breadcrumb} />
                <h1 className="mb-5 text-xl font-bold">Admin Chapter Create</h1>
                <div className="py-6 bg-gray-200 shadow-lg card">
                    {hasError && <ErrorComponent></ErrorComponent>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="px-4 mb-6">
                            <label
                                htmlFor="category"
                                className="block mb-2 text-sm font-semibold text-blue-900"
                            >
                                Category
                            </label>
                            <Controller
                                control={control}
                                name="category"
                                value="0"
                                render={({ onChange, value }) => (
                                    <AsyncSelect
                                        className="w-full md:w-1/4"
                                        styles={customStyles}
                                        defaultOptions={categories}
                                        {...register("category", {
                                            required: "Please choose category",
                                        })}
                                        value={selectedcategory}
                                        onChange={handleChangeCategory}
                                    />
                                )}
                            />
                            {errors?.category && (
                                <div className="mt-2 text-xs font-semibold text-red-500">
                                    {errors.category.message}
                                </div>
                            )}
                        </div>
                        <div className="px-4 mb-6">
                            <label
                                htmlFor="division"
                                className="block mb-2 text-sm font-semibold text-blue-900"
                            >
                                Role
                            </label>
                            <Controller
                                control={control}
                                name="division_id"
                                render={({ onChange, value }) => (
                                    <AsyncSelect
                                        className="w-full md:w-1/4"
                                        styles={customStyles}
                                        defaultOptions={options}
                                        {...register("division_id", {
                                            required: "Please choose role",
                                        })}
                                        value={selecteddivision}
                                        onChange={handleChange}
                                    />
                                )}
                            />
                            {errors?.division_id && (
                                <div className="mt-2 text-xs font-semibold text-red-500">
                                    {errors.division_id.message}
                                </div>
                            )}
                        </div>
                        <div className="flex items-center justify-between py-3 mb-6 bg-white">
                            <div className="px-4 text-lg font-bold text-blue-900">
                                Page {pagenumb}
                            </div>

                            <div className="inline-flex px-4 gap-x-4">
                                {loadingPage || chapterPageStore.Loading ? (
                                    <Loading></Loading>
                                ) : (
                                    <>
                                        <NavNextPrevDetailChapter
                                            disableNavigation={
                                                disableNavPrevChapter
                                            }
                                            label="Prev"
                                            onClickNavigation={
                                                onClickNavPrevChapter
                                            }
                                        ></NavNextPrevDetailChapter>
                                        <NavNextPrevDetailChapter
                                            disableNavigation={
                                                disableNavNextChapter
                                            }
                                            label="Next"
                                            onClickNavigation={
                                                onClickNavNextChapter
                                            }
                                        ></NavNextPrevDetailChapter>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="px-4 mb-6">
                            <label
                                htmlFor="order"
                                className="block mb-2 text-sm font-semibold text-blue-900"
                            >
                                Order
                            </label>
                            <input
                                min={1}
                                type="number"
                                name="order"
                                id="order"
                                onWheel={disableOnWheel}
                                {...register(`order`)}
                                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 md:w-1/6 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            />
                        </div>
                        <div className="px-4 mb-6">
                            <label
                                htmlFor="title"
                                className="block mb-2 text-sm font-semibold text-blue-900"
                            >
                                Chapter Title
                            </label>
                            <input
                                type="text"
                                name="title"
                                id="title"
                                {...register("title", {
                                    required: "Title is required",
                                })}
                                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 md:w-1/2 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            />
                            {errors?.title && (
                                <div className="mt-2 text-xs font-semibold text-red-500">
                                    {errors?.title.message}
                                </div>
                            )}
                        </div>
                        <div className="w-full pl-4 mb-6 md:w-11/12">
                            <label
                                htmlFor="content"
                                className="block mb-2 text-sm font-semibold text-blue-900"
                            >
                                Content
                            </label>
                            <Controller
                                name="content"
                                control={control}
                                defaultValue=""
                                render={({ onChange, value }) => (
                                    <Editor
                                        {...register("content", {
                                            required: "Please fill the content",
                                        })}
                                        cloudChannel="dev"
                                        name="content"
                                        init={{
                                            selector: "textarea",
                                            height: "480",
                                            skin: false,
                                            plugins:
                                                "link image textpattern lists advlist anchor",
                                            menubar:
                                                "file edit view insert format",
                                            toolbar:
                                                "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link image",
                                            statusbar: false,
                                            /* enable title field in the Image dialog*/
                                            image_title: true,
                                            /* enable automatic uploads of images represented by blob or data URIs*/
                                            automatic_uploads: true,
                                            /*
                                            URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                            images_upload_url: 'postAcceptor.php',
                                            here we add custom filepicker only to Image dialog
                                            */
                                            file_picker_types: "image",
                                            /* and here's our custom image picker*/

                                            file_picker_callback: function (
                                                cb,
                                                value,
                                                meta
                                            ) {
                                                var input =
                                                    document.createElement(
                                                        "input"
                                                    );
                                                input.setAttribute(
                                                    "type",
                                                    "file"
                                                );
                                                input.setAttribute("accept", [
                                                    "image/webp",
                                                    "image/png",
                                                    "image/jpg",
                                                    "image/jpeg",
                                                ]);

                                                /*
                                                Note: In modern browsers input[type="file"] is functional without
                                                even adding it to the DOM, but that might not be the case in some older
                                                or quirky browsers like IE, so you might want to add it to the DOM
                                                just in case, and visually hide it. And do not forget do remove it
                                                once you do not need it anymore.
                                            */

                                                input.onchange = function () {
                                                    var file = this.files[0];
                                                    clearErrors("content");

                                                    var filePath = this.value;
                                                    var allowedExtensions =
                                                        /(\.jpg|\.jpeg|\.png|\.webp)$/i;
                                                    if (
                                                        !allowedExtensions.exec(
                                                            filePath
                                                        )
                                                    ) {
                                                        setError("content", {
                                                            message:
                                                                "Only JPEG, JPG, PNG & WEBP allowed!",
                                                        });
                                                        error(
                                                            errors?.content &&
                                                                errors.content
                                                                    .message
                                                        );
                                                        return;
                                                    }

                                                    if (file.size > 2000000) {
                                                        setError("content", {
                                                            message:
                                                                "Maximum image size 2Mb",
                                                        });
                                                        error(
                                                            errors?.content &&
                                                                errors.content
                                                                    .message
                                                        );
                                                        return;
                                                    }

                                                    var reader =
                                                        new FileReader();
                                                    reader.onload =
                                                        function () {
                                                            /*
                                                    Note: Now we need to register the blob in TinyMCEs image blob
                                                    registry. In the next release this part hopefully won't be
                                                    necessary, as we are looking to handle it internally.
                                                */
                                                            var id =
                                                                "blobid" +
                                                                new Date().getTime();
                                                            var blobCache =
                                                                tinymce
                                                                    .activeEditor
                                                                    .editorUpload
                                                                    .blobCache;
                                                            var base64 =
                                                                reader.result.split(
                                                                    ","
                                                                )[1];
                                                            var blobInfo =
                                                                blobCache.create(
                                                                    id,
                                                                    file,
                                                                    base64
                                                                );
                                                            blobCache.add(
                                                                blobInfo
                                                            );

                                                            /* call the callback and populate the Title field with the file name */
                                                            cb(
                                                                blobInfo.blobUri(),
                                                                {
                                                                    title: file.name,
                                                                }
                                                            );
                                                        };
                                                    reader.readAsDataURL(file);
                                                };

                                                input.click();
                                            },
                                        }}
                                        value={content}
                                        onEditorChange={handleEditorChange}
                                    />
                                )}
                            />
                            {errors?.content && (
                                <div className="mt-2 text-xs font-semibold text-red-500">
                                    {errors?.content.message}
                                </div>
                            )}
                        </div>
                        <div className="flex justify-between w-full pl-4 md:w-11/12">
                            <a
                                href="/admin/chapter"
                                className="w-24 py-2 text-sm font-semibold tracking-wider text-center text-white bg-gray-400 rounded hover:bg-gray-700"
                            >
                                Cancel
                            </a>
                            <div>
                                <button
                                    type="submit"
                                    className="w-24 py-2 text-sm font-semibold tracking-wider text-white bg-green-600 rounded hover:bg-green-700"
                                >
                                    Save
                                </button>
                                {/* <button onClick={() => addNewpage()} className="w-24 px-4 py-2 text-xs font-semibold tracking-wider text-white bg-red-600 rounded hover:bg-red-700">New Page</button> */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default EditChapter;
