import React, { Component } from "react";
import Pagination from "rc-pagination";
import { Link } from "react-router-dom";
import Modal from "../../../component/Modal";

import addIcon from "../../../assets/icon_add.svg";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import moment from "moment/moment";
import "moment/locale/id";
import {
    getListAnnouncement,
    deleteAnnouncement,
} from "../../../actions/announcement";
import Table from "../../../component/Table";
import Select from "../../../component/Select";
import Layout from "../../Layout";
import Search from "../../../component/Search";
import { toastError, toastSuccess } from "../../../component/Toast";
import { getIsAdmin, getPermission } from "../../../services/storage.service";
import capitalize from "../../../utils/Capitalize";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            items: [],
            options: [
                { label: "Sort Latest", value: "desc" },
                { label: "Sort Oldest", value: "asc" },
            ],
            selectedOption: { label: "Sort Latest", value: "desc" },
            optionShow: false,
            searchKeyword: "",
            currentPage: 1,
            totalPages: null,
            deleteId: null,
            authorized: true,
            canCRUD: false,
            canView: false,
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount() {
        //check if user can view or crud at this section
        this.setState({
            canCRUD:
                getPermission().indexOf("can_create_announcement") > -1 &&
                getPermission().indexOf("can_edit_announcement") > -1,
            canView: getPermission().indexOf("can_view_announcements") > -1,
        });

        this.fetchAnnouncement();
    }

    fetchAnnouncement = async (data) => {
        const { dispatch, newsList } = this.props;
        dispatch(
            getListAnnouncement({
                id: data?.current ?? "",
                approval: getIsAdmin() ? "0,2,3,4" : "",
                sort: data?.sort,
                keyword: data?.keyword,
            })
        )
            .unwrap()
            .then((res) => {
                this.setState({
                    currentPage: data?.current,
                    totalPages: newsList?.meta?.total,
                });
            })
    };

    deleteItem = async () => {
        const { dispatch, announcementList } = this.props;
        this.setState({
            show: false,
        });
        dispatch(deleteAnnouncement({ id: this.state.deleteId }))
            .unwrap()
            .then((data) => {
                toastSuccess(capitalize(data.message));
                this.fetchAnnouncement({
                    current:
                        announcementList?.data?.length === 1 &&
                        announcementList?.meta?.current_page > 0
                            ? announcementList?.meta?.current_page - 1
                            : announcementList?.meta?.current_page,
                    sort: this.state.selectedOption.value,
                    keyword: this.state.searchKeyword,
                });
            })
            .catch((err) => {
                toastError(capitalize(err.message));
            });
    };

    editItem = (id) => {
        const { history } = this.props;
        history.push("/admin/announcement/" + id);
    };

    approvalItem = (id) => {
        const { history } = this.props;
        history.push("/admin/announcement/approval/" + id);
    };

    changeOption = async (selected) => {
        //protects too many requests for the same from the user
        if (this.state.selectedOption.value === selected.value) return;

        await this.setState({
            optionShow: false,
            selectedOption: selected,
        });

        this.fetchAnnouncement({
            current: this.state.currentPage,
            sort: selected.value,
            keyword: this.state.searchKeyword,
        });
    };

    onSearch = (e) => {
        e.preventDefault();
        this.fetchAnnouncement({
            sort: this.state.selectedOption.value,
            keyword: this.state.searchKeyword,
        });
    };

    showModal = (id) => {
        this.setState({ show: true, deleteId: id });
    };

    hideModal = () => {
        this.setState({ show: false, deleteId: null });
    };

    showSelect = () => {
        this.setState({ optionShow: !this.state.optionShow });
    };

    render() {
        const { announcementList, loading } = this.props;
        // Columns for Table component
        const columns = [
            { name: "id", width: "5%" },
            { name: "Announcement Title", width: "30%" },
            { name: "Page View", width: "10%" },
            { name: "Creator", width: "15%" },
            { name: "Last Update", width: "15%" },
            { name: "Status", width: "10%" },
            { name: "Pop-up", width: "5%" },
        ];

        // Data for Table component
        const data =
        announcementList?.data?.length !== 0 ?
        announcementList?.data?.map((item) => ({
                id: item.id,
                items: [
                    item?.id,
                    item?.title,
                    item?.viewer ?? 0,
                    item?.user?.name,
                    moment(item?.updated_at).format("LL"),
                    <span className={`status-approval status-${item?.approval_status_description.toLowerCase()}`}>{item?.approval_status_description}</span>,
                    item?.is_display_popup ? "Yes" : "No",
                ],
                approval_status: item?.approval_status
            })) : [];

        return (
            <Layout>
                <ToastContainer />
                <div className="container flex justify-center py-5">
                    <div className="px-3 py-2 rounded-md bg-[#f8fafd] flex justify-center gap-5">
                        <Search
                            value={this.state.searchKeyword}
                            onChange={(e) =>
                                this.setState({
                                    searchKeyword: e.target.value,
                                })
                            }
                            onSubmit={this.onSearch}
                            placeholder="Search by title"
                        />
                        <Select
                            options={this.state.options}
                            onClick={this.showSelect}
                            onChange={(selected) => this.changeOption(selected)}
                            show={this.state.optionShow}
                        />
                    </div>
                </div>
                <hr />
                <div className="container min-h-screen py-5 px-14">
                    {/* only show if user can crud */}
                    {this.state.canCRUD === true && (
                        <Link
                            to="/admin/announcement/create"
                            className="inline-flex items-center px-4 py-3 mb-5 font-semibold tracking-wider text-white bg-red-700 rounded text-md hover:bg-red-900"
                        >
                            <img src={addIcon} alt="Add New"></img>
                            <div className="pl-2 ml-2 border-l-2 border-white">
                                Create Announcement
                            </div>
                        </Link>
                    )}
                    {/* only show if user can view */}
                    {this.state.canView === true && (
                        <>
                            <Table
                                name="announcement"
                                columns={columns}
                                data={data}
                                actions
                                editAction={(id) => this.editItem(id)}
                                deleteAction={(id) => {
                                    this.showModal(id);
                                }}
                                approvalAction={(id) => this.approvalItem(id)}
                                canCRUD={this.state.canCRUD}
                                isAdmin={getIsAdmin()}
                                isLoading={loading}
                                className="mb-6"
                            />
                            {announcementList?.meta?.last_page > 1 && (
                                <div className="flex justify-end w-full">
                                    <Pagination
                                        current={
                                            announcementList?.meta
                                                ?.current_page ?? 1
                                        }
                                        onChange={(current) =>
                                            this.fetchAnnouncement({
                                                current: current,
                                                sort: this.state.selectedOption
                                                    .value,
                                                keyword:
                                                    this.state.searchKeyword,
                                            })
                                        }
                                        total={announcementList?.meta?.total}
                                        showTitle={false}
                                    />
                                </div>
                            )}
                        </>
                    )}

                    <Modal show={this.state.show} handleClose={this.hideModal}>
                        <div className="text-center">
                            <p className="px-8 text-gray-500">
                                Do you really want to delete this announcement? This
                                process cannot be undone
                            </p>
                            <div className="p-3 mt-5 space-x-4 text-center md:block">
                                <button
                                    onClick={this.hideModal}
                                    className="px-5 py-2 mb-2 text-sm font-medium tracking-wider text-gray-600 bg-white border rounded shadow-sm md:mb-0 hover:bg-gray-100"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={this.deleteItem}
                                    className="px-5 py-2 mb-2 text-sm font-medium tracking-wider text-white bg-red-700 border border-red-500 rounded shadow-sm md:mb-0 hover:bg-red-900"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    const { announcementDelete, announcementList, loading } =
        state.announcement;
    return {
        announcementDelete,
        announcementList,
        loading,
    };
}

export default connect(mapStateToProps)(Index);
