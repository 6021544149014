import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api.service";

export const getAllNews = createAsyncThunk("news/all", async (arg) => {
    const { data } = await api.get(
        `/api/news/all?approval_status=${arg.approval ?? ''}&sort[created]=desc`
    );

    return data;
});

export const getListNews = createAsyncThunk("news/list", async (arg) => {
    const { data } = await api.get(
        `/api/news?approval_status=${arg.approval ?? ''}&page=${arg.id}&sort[created]=${arg.sort ?? "desc"}&search=${
            arg.keyword ?? ""
        }`
    );

    return data;
});

export const getDetailNews = createAsyncThunk("news/detail", async (arg) => {
    const { data } = await api.get(`/api/news/${arg.id}`);

    return data;
});

export const getRandomNews = createAsyncThunk(
    "news/random",
    async ({ limit }) => {
        const { data } = await api.get(`/api/news/random?approval_status=4&limit=${limit}`);

        return data;
    }
);

export const getLatestNews = createAsyncThunk("news/latest", async () => {
    const { data } = await api.get(`/api/news/latest`);

    return data;
});

export const deleteNews = createAsyncThunk("news/delete", async (arg) => {
    const { data } = await api.delete(`/api/news/${arg.id}`);

    return data;
});

export const addNews = createAsyncThunk("news/add", async (arg) => {
    const { data } = await api.post(`/api/news`, arg.data);

    return data;
});

export const editNews = createAsyncThunk("news/edit", async (arg) => {
    const { data } = await api.post(`/api/news/${arg.id}`, arg.data);

        return data;
    }
);

export const updateApprovalNews = createAsyncThunk("news/approval", async (arg) => {
    const { data } = await api.post(`/api/news/${arg.id}/approval`, arg.data);

        return data;
    }
);

