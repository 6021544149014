import axios from 'axios';
import React, { Component } from 'react';
import partyIcon from '../../assets/icon_party.svg';
import Layout from '../Layout';
import Modal from '../../component/Modal';
import imgDataNotFound from '../../assets/images/data-not-found.png';
import Loading from '../../component/Loading';

class FreshDetailDivision extends Component {
	constructor(props) {
		super(props);
		this.state = {
			indexItems: 1,
			progressVal: 0,
			itemsLenght: 0,
			items: [],
			isFinished: false,
			isLoading: false,
			isLoadingChapterLoad: false,
		};
	}

	componentDidMount() {
		let id = this.props.match.params.post_id;
		let progress = Number(localStorage.getItem('chapter'));
		const AuthStr = 'Bearer '.concat(localStorage.getItem('token'));
		this.setState({ isLoadingChapterLoad: true });
		axios
			.get(`${process.env.REACT_APP_BASE_API}/api/chapter/page/all?chapter_category=0&division_id=` + id, {
				headers: { Authorization: AuthStr },
			})
			.then((response) => {
				const journeyLength = response.data.data.length;
				this.setState({ items: response.data.data });
				this.setState({ itemsLenght: journeyLength });
				if (
					localStorage.getItem('chapter') === 'null' ||
					Number(localStorage.getItem('chapter')) === 0 ||
					Number(localStorage.getItem('chapter')) > journeyLength
				) {
					this.setState({ indexItems: 1 });
					this.setState({ progressVal: 100 / this.state.itemsLenght });
				} else {
					this.setState({ indexItems: Number(localStorage.getItem('chapter')) });
					this.setState({ progressVal: (100 / this.state.itemsLenght) * progress });
				}
				this.setState({ isLoadingChapterLoad: false });
			});

		if (!localStorage.getItem('logged')) {
			this.props.history.push('/');
		}
	}

	handleNextClick = () => {
		this.setState({ progressVal: this.state.progressVal + 100 / this.state.itemsLenght });
		this.setState({ indexItems: this.state.indexItems + 1 });

		if (Number(localStorage.getItem('chapter')) !== 0) {
			localStorage.removeItem('chapter');
			localStorage.setItem('chapter', this.state.indexItems + 1);
			this.updateData(this.state.indexItems + 1);
		}
	};

	handlePrevClick = () => {
		this.setState({ progressVal: this.state.progressVal - 100 / this.state.itemsLenght });
		this.setState({ indexItems: this.state.indexItems - 1 });

		if (Number(localStorage.getItem('chapter')) !== 0) {
			localStorage.removeItem('chapter');
			localStorage.setItem('chapter', this.state.indexItems - 1);
			this.updateData(this.state.indexItems - 1);
		}
	};

	finishClick = () => {
		localStorage.removeItem('chapter');
		localStorage.setItem('chapter', 0);
		this.updateData(0);
		this.setState({ isFinished: true });
	};

	updateData(data) {
		this.setState({ isLoading: true });
		const requestChapterUpdate = {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify({ page: data }),
		};

		fetch(
			`${process.env.REACT_APP_BASE_API}/api/user/` + localStorage.getItem('user') + '/journey-update',
			requestChapterUpdate
		)
			.then((res) => res.json())
			.then((result) => {
				this.setState({ isLoading: false });
			});
	}

	afterFinishClick = () => {
		this.setState({ isFinished: false });
		this.props.history.push('/home');
	};

	render() {
		if (this.state.isLoadingChapterLoad) {
			return (
				<Layout className="bg-[#f7fafc]">
					<div className="w-9/12 mx-auto text-center pt-1 flex justify-center items-center">
						<Loading />
					</div>
				</Layout>
			);
		}
		if (this.state.items && this.state.items.length < 1) {
			return (
				<Layout className="bg-[#f7fafc]">
					<div className="w-9/12 mx-auto text-center pt-1">
						<Modal className="flex flex-col justify-center items-center" show>
							<img className="w-96" src={imgDataNotFound} alt="Data not found" />
							<div className="text-center">
								<h2 className="px-8 text-gray-500 font-bold">Uups.. User Journey Not Found!</h2>
								<div className="p-3 mt-5 space-x-4 text-center md:block">
									<button
										onClick={this.afterFinishClick}
										className="px-5 py-2 mb-2 text-sm font-medium tracking-wider text-white bg-red-700 border border-red-500 rounded shadow-sm md:mb-0 hover:bg-red-900"
									>
										Back
									</button>
								</div>
							</div>
						</Modal>
					</div>
				</Layout>
			);
		}
		return (
			<Layout className="bg-[#f7fafc]">
				<div className="w-9/12 mx-auto text-center pt-1">
					{!this.state.isFinished ? (
						<div>
							<div className="relative mt-8">
								<div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-grey-ebebeb">
									<div
										style={{ width: `${this.state.progressVal}%` }}
										className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center from-red-cc2323-to-eb9898 rounded"
									></div>
								</div>
							</div>

							{this.state.items.map((item, index) => (
								<div key={item.id}>
									{this.state.indexItems === index + 1 ? (
										<div>
											<p className="font-bold text-xl text-gray-385f80 my-8">{item.title}</p>
											<div className="bg-white w-full p-10 overflow-y-auto h-80 rounded border mb-8">
												<div dangerouslySetInnerHTML={{ __html: item.content }}></div>
											</div>
										</div>
									) : (
										<div></div>
									)}
								</div>
							))}

							{this.state.isLoading ? (
								<div className="flex justify-center bg-transparent p-3">
									<svg
										className="animate-spin h-5 w-5 text-gray-500"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
									>
										<circle
											className="opacity-25"
											cx="12"
											cy="12"
											r="10"
											stroke="currentColor"
											strokeWidth="4"
										></circle>
										<path
											className="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										></path>
									</svg>
								</div>
							) : (
								<>
									{this.state.indexItems > 1 && this.state.indexItems <= this.state.itemsLenght ? (
										<button
											className="bg-gray-300 py-3 mb-6 px-8 hover:bg-gray-400 text-white rounded font-medium shadow-xl mx-3"
											onClick={this.handlePrevClick}
										>
											Prev
										</button>
									) : (
										''
									)}

									{this.state.indexItems !== this.state.itemsLenght ? (
										<button
											className="bg-red-c22125 py-3 mb-6 px-8 hover:bg-red-700 text-white rounded font-medium shadow-xl mx-3"
											onClick={this.handleNextClick}
										>
											Next
										</button>
									) : (
										<button
											className="bg-green-400 py-3 mb-6 px-8 hover:bg-green-500 text-white rounded font-medium shadow-xl mx-3"
											onClick={this.finishClick}
										>
											Finished
										</button>
									)}
								</>
							)}
						</div>
					) : (
						<div className="bg-white w-full p-8 md:p-10 mt-12 rounded border mb-8 flex flex-col items-center justify-center">
							<div className="flex flex-col flex-wrap items-center justify-center w-full md:w-508px">
								<img src={partyIcon} alt="Congratulations" className="mb-3"></img>
								<h1 className="text-red-c22125 text-2xl md:text-4xl mb-4 font-bold">
									Congratulations!
								</h1>
								<div className="w-56 h-1 bg-orange-ecac58 mb-6"></div>
								<div className="flex flex-col gap-6">
									<h6 className="text-gray-385f80 font-semibold">
										You have finished the information content journey!
									</h6>
									<p className="text-gray-385f80 leading-8">
										Remember to ask fellow teammate when you don't know how to do. And also Just why
										in our Slack channel when you face an obstacle, our friendly teammate will help
										you gladdy.{' '}
									</p>
								</div>
							</div>
							<button
								className="bg-red-c22125 py-3 px-8 hover:bg-red-700 text-white rounded font-medium shadow-xl mt-8"
								onClick={this.afterFinishClick}
							>
								Next
							</button>
						</div>
					)}
				</div>
			</Layout>
		);
	}
}

export default FreshDetailDivision;
