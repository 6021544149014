import { Component } from 'react';
import Footer from '../component/footer';
import Header from '../component/Header';
import { getIsAdmin } from '../services/storage.service';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			journey: null,
		};
	}

	componentDidMount() {
		this.setState({ journey: Number(localStorage.getItem('chapter')) });
	}
	render() {
		const { journey } = this.state;
		return (
			<>
				<div className={`min-h-screen ${this.props.className}`}>
					{journey === 0 || getIsAdmin() ? (
						<>
							<div className="w-full mb-16">
								<Header />
							</div>
							<div>{this.props.children}</div>
						</>
					) : (
						<div className="pt-16">{this.props.children}</div>
					)}
				</div>
				<Footer />
			</>
		);
	}
}

export default Layout;
