import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
// Import TinyMce
import tinymce from "tinymce";
// CSS
import "tinymce/themes/silver";
import "tinymce/skins/ui/oxide/skin.css";
// Default Icons
import "tinymce/icons/default";
// Plugins
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/advlist";
import {
    editAnnouncement,
    getDetailAnnouncement,
    getListAnnouncement,
} from "../../../../actions/announcement";
import { getUser } from "../../../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../Layout";
import iconInfo from "../../../../assets/icon_info.svg";
import Loading from "../../../../component/Loading";
import capitalize from "../../../../utils/Capitalize";
import { toastError, toastSuccess } from "../../../../component/Toast";

const InfoAnnouncement = () => {
    const [announcementDetail, setAnnouncementDetail] = useState({
        picture: null,
        imgData: null,
        content: null,
        creator: "",
        roleId: null,
        oldImg: "",
        approvalStatus: 2,
        popup: 0,
    });
    const dispatch = useDispatch();
    const announcement = useSelector((state) => state.announcement);
    const auth = useSelector((state) => state.auth);
    const params = useParams();

    const history = useHistory();
    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        control,
        reset,
        getValues,
        clearErrors,
        setError,
    } = useForm();

    useEffect(() => {
        if (Object.keys(auth.user).length === 0) {
            dispatch(getUser());
        }

        dispatch(getDetailAnnouncement({ id: params.id }))
            .unwrap()
            .then((res) => {
                setValue("title", res.data.title);
                setValue("content", res.data.content);
                setValue("popup", res.data.is_display_popup);
                setAnnouncementDetail((prev) => ({
                    ...prev,
                    content: res.data.content,
                    creator: res.data.user.name,
                    oldImg: res.data.image,
                    roleId: res.data.role_category.id,
                }));
            })
    }, []);

    //handling image change
    const handleChange = (e) => {
        clearErrors("img");

        let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp)$/i;
        let filePath = e.target.value;
        if (!allowedExtensions.exec(filePath)) {
            setError("img", {
                message: "Only JPEG, JPG, PNG & WEBP allowed!",
            });
            setAnnouncementDetail((prev) => ({
                ...prev,
                imgData: null,
            }));
            return;
        }

        if (e.target?.files[0]?.size > 2000000) {
            setError("img", {
                message: "Maximum image size 2Mb",
            });
            setAnnouncementDetail((prev) => ({
                ...prev,
                imgData: null,
            }));
            return;
        }

        setValue("image", e.target.files[0]); //manually set value image for form
        setAnnouncementDetail((prev) => ({
            ...prev,
            picture: e.target.files[0],
        }));
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setAnnouncementDetail((prev) => ({
                ...prev,
                imgData: reader.result,
            })); //set img data with url so it can showed after open
        });
        reader.readAsDataURL(e.target.files[0]);
    };

    //handling tinymce on change
    const handleEditorChange = (change) => {
        setAnnouncementDetail((prev) => ({ ...prev, content: change }));
        setValue("content", change);
        clearErrors("content");
    };

    const onSubmit = async (data) => {
        let fd = new FormData();
        fd.append("_method", "PUT");
        fd.append("image", data.image ? data.image : "");
        fd.append("title", data.title);
        fd.append("content", data.content);
        fd.append("approval_status", Number(announcementDetail.approvalStatus));
        fd.append("role_category", announcementDetail.roleId);
        fd.append("is_display_popup", data.popup ? 1 : 0);

        // dispatch create announcement and store data
        dispatch(editAnnouncement({ id: params.id, data: fd }))
            .unwrap()
            .then((data) => {
                toastSuccess(
                    capitalize(data.message),
                    {
                        onClose: () => {
                            history.push("/admin/announcement");
                        },
                    }
                );
                reset();
                setAnnouncementDetail((prev) => ({
                    ...prev,
                    imgData: null,
                }));
                dispatch(
                    getListAnnouncement(
                        announcement.announcementList
                            ? announcement.announcementList?.meta?.current_page
                            : 1
                    )
                );
            })
            .catch((err) => {
                toastError(capitalize("Error! update announcement failed"));
            });
    };

    const onDeleteImage = () => {
        setAnnouncementDetail((prev) => ({
            ...prev,
            oldImg: null,
            picture: null,
            imgData: null,
        }));
    };

    return (
        <Layout>
            <div className="min-h-scrren bg-[#f7fafc]">
                <ToastContainer />
                <div className="container p-14">
                    <h1 className="mb-5 text-xl font-bold">
                        Edit Announcement
                    </h1>
                    <div className="bg-gray-200 shadow-lg card">
                        <div className="justify-between py-3 bg-white px-7">
                            <Link
                                to="/admin/announcement"
                                className="flex text-[#385f80] text-xl items-center font-bold gap-1 w-max"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                                    />
                                </svg>
                                Back
                            </Link>
                        </div>
                        <div className="py-12 bg-gray-200 px-7">
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="w-11/12"
                            >
                                <div className="mb-6 md:w-1/2">
                                    <label
                                        htmlFor="division"
                                        className="block mb-2 text-sm font-semibold text-blue-900"
                                    >
                                        Creator
                                    </label>
                                    <span className="text-gray-500">
                                        {announcementDetail.creator}
                                    </span>
                                </div>
                                <div className="mb-6 md:w-1/2">
                                    <label
                                        htmlFor="division"
                                        className="block mb-2 text-sm font-semibold text-blue-900"
                                    >
                                        Announcement Title
                                    </label>
                                    <input
                                        type="text"
                                        name="title-announcement"
                                        id="title-announcement"
                                        {...register("title", {
                                            required: "Title is required",
                                        })}
                                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                                    />
                                    {errors?.title && (
                                        <div className="text-xs font-semibold text-red-500">
                                            {errors.title.message}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-6 md:w-1/2">
                                    <label
                                        htmlFor="image"
                                        className="block mb-2 text-sm font-semibold text-blue-900"
                                    >
                                        Cover Image
                                    </label>
                                    <p className="mb-2 text-xs italic">
                                        (Max Size: 2Mb; Extension: jpeg, jpg,
                                        png, webp)
                                    </p>
                                    <div className="flex items-center gap-3">
                                        <label
                                            htmlFor="image"
                                            className="px-6 py-3 text-white bg-green-600 rounded cursor-pointer"
                                        >
                                            File Upload
                                        </label>
                                        {announcementDetail.picture &&
                                            errors.img === undefined && (
                                                <span className="text-gray-500">
                                                    {getValues("image.name")}
                                                </span>
                                            )}
                                    </div>
                                    <input
                                        type="file"
                                        accept="image/webp, image/png, image/jpg, image/jpeg"
                                        hidden
                                        name="image"
                                        id="image"
                                        onChange={handleChange}
                                    />
                                    {errors?.img && (
                                        <div className="mt-2 text-xs font-semibold text-red-500">
                                            {errors.img.message}
                                        </div>
                                    )}
                                </div>
                                <div className="flex mb-6 md:w-1/2">
                                    {announcementDetail.picture ? (
                                        <img
                                            src={announcementDetail.imgData}
                                            alt={getValues.image}
                                            className="max-w-full"
                                        />
                                    ) : (
                                        announcementDetail.oldImg && (
                                            <img
                                                src={announcementDetail.oldImg}
                                                alt="Banner announcement"
                                                className="max-w-full"
                                            />
                                        )
                                    )}
                                    {announcementDetail.oldImg !== null ||
                                    (announcementDetail.picture &&
                                        errors.img === undefined) ? (
                                        <div className="relative w-8">
                                            <button
                                                className="absolute top-0 right-0 flex items-center justify-center p-1 text-red-700 border border-black bg-opacity-80"
                                                type="button"
                                                onClick={onDeleteImage}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={3.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6 18L18 6M6 6l12 12"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="mb-6">
                                    <label
                                        htmlFor="pop-up"
                                        className="block mb-2 text-sm font-semibold text-blue-900"
                                    >
                                        Mark as Pop-Up
                                    </label>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input
                                            id="pop-up"
                                            type="checkbox"
                                            {...register("popup")}
                                            className="sr-only peer"
                                        />
                                        <div className="w-11 h-6 bg-[#b0bec5] peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 "></div>
                                        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 after:content-['Off'] peer-checked:after:content-['On']" />
                                    </label>
                                </div>
                                <div className="mb-6 md:w-max bg-[#c22125] flex px-6 py-4 rounded-md items-center gap-6 h-24">
                                    <div className="items-center w-12 h-12">
                                        <img
                                            src={iconInfo}
                                            alt="Pop-Up Info"
                                            className="w-full h-full"
                                        />
                                    </div>
                                    <div className="h-full w-[3px] rounded bg-white">
                                        {" "}
                                        &nbsp;
                                    </div>
                                    <div className="flex text-sm text-white">
                                        <ul className="flex flex-col ml-6 list-disc">
                                            <li>
                                                When this parameter is active,
                                                then this announcement will
                                                appear as pop-up when user
                                                login.
                                            </li>
                                            <li>
                                                Pop-up will be triggered once a
                                                day.
                                            </li>
                                            <li>
                                                Only 1 announcement that can be
                                                made into pop-up.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="w-full mb-6">
                                    <label
                                        htmlFor="content"
                                        className="block mb-2 text-sm font-semibold text-blue-900"
                                    >
                                        Content
                                    </label>
                                    <Controller
                                        name="content"
                                        control={control}
                                        defaultValue=""
                                        render={({ onChange, value }) => (
                                            <Editor
                                                {...register("content", {
                                                    required:
                                                        "Please fill the content",
                                                })}
                                                cloudChannel="dev"
                                                name="content"
                                                init={{
                                                    selector: "textarea",
                                                    height: "480",
                                                    skin: false,
                                                    plugins:
                                                        "link image textpattern lists advlist anchor",
                                                    menubar:
                                                        "file edit view insert format",
                                                    toolbar:
                                                        "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link image",
                                                    statusbar: false,
                                                    /* enable title field in the Image dialog*/
                                                    image_title: true,
                                                    /* enable automatic uploads of images represented by blob or data URIs*/
                                                    automatic_uploads: true,
                                                    file_picker_types: "image",
                                                    /* and here's our custom image picker*/

                                                    file_picker_callback:
                                                        function (
                                                            cb,
                                                            value,
                                                            meta
                                                        ) {
                                                            let input =
                                                                document.createElement(
                                                                    "input"
                                                                );
                                                            input.setAttribute(
                                                                "type",
                                                                "file"
                                                            );
                                                            input.setAttribute(
                                                                "accept",
                                                                [
                                                                    "image/webp",
                                                                    "image/png",
                                                                    "image/jpg",
                                                                    "image/jpeg",
                                                                ]
                                                            );

                                                            input.onchange =
                                                                function () {
                                                                    let file =
                                                                        this
                                                                            .files[0];
                                                                    clearErrors(
                                                                        "content"
                                                                    );

                                                                    let filePath =
                                                                        this
                                                                            .value;
                                                                    let allowedExtensions =
                                                                        /(\.jpg|\.jpeg|\.png|\.webp)$/i;
                                                                    if (
                                                                        !allowedExtensions.exec(
                                                                            filePath
                                                                        )
                                                                    ) {
                                                                        setError(
                                                                            "content",
                                                                            {
                                                                                message:
                                                                                    "Only JPEG, JPG, PNG & WEBP allowed!",
                                                                            }
                                                                        );
                                                                        toastError(
                                                                            errors?.content &&
                                                                                errors
                                                                                    .content
                                                                                    .message
                                                                        );
                                                                        return;
                                                                    }

                                                                    if (
                                                                        file.size >
                                                                        2000000
                                                                    ) {
                                                                        setError(
                                                                            "content",
                                                                            {
                                                                                message:
                                                                                    "Maximum image size 2Mb",
                                                                            }
                                                                        );
                                                                        toastError(
                                                                            errors?.content &&
                                                                                errors
                                                                                    .content
                                                                                    .message
                                                                        );
                                                                        return;
                                                                    }

                                                                    let reader =
                                                                        new FileReader();
                                                                    reader.onload =
                                                                        function () {
                                                                            let id =
                                                                                "blobid" +
                                                                                new Date().getTime();
                                                                            let blobCache =
                                                                                tinymce
                                                                                    .activeEditor
                                                                                    .editorUpload
                                                                                    .blobCache;
                                                                            let base64 =
                                                                                reader.result.split(
                                                                                    ","
                                                                                )[1];
                                                                            let blobInfo =
                                                                                blobCache.create(
                                                                                    id,
                                                                                    file,
                                                                                    base64
                                                                                );
                                                                            blobCache.add(
                                                                                blobInfo
                                                                            );

                                                                            /* call the callback and populate the Title field with the file name */
                                                                            cb(
                                                                                blobInfo.blobUri(),
                                                                                {
                                                                                    title: file.name,
                                                                                }
                                                                            );
                                                                        };
                                                                    reader.readAsDataURL(
                                                                        file
                                                                    );
                                                                };

                                                            input.click();
                                                        },
                                                }}
                                                value={
                                                    announcementDetail.content
                                                }
                                                onEditorChange={
                                                    handleEditorChange
                                                }
                                            />
                                        )}
                                    />
                                    {errors?.content && (
                                        <div className="mt-2 text-xs font-semibold text-red-500">
                                            {errors?.content.message}
                                        </div>
                                    )}
                                </div>

                                <div className="flex justify-between">
                                    <a
                                        href="/admin/announcement"
                                        className="w-24 px-4 py-2 text-sm font-semibold tracking-wider text-center text-white bg-gray-400 rounded hover:bg-gray-700"
                                    >
                                        Cancel
                                    </a>
                                    <button
                                       className={`w-24 px-4 py-2 text-sm font-semibold tracking-wider text-white rounded text-center flex justify-center items-center bg-green-600 hover:bg-green-700 disabled:bg-gray-400 disabled:cursor-not-allowed`}
                                       disabled={
                                           Object.keys(errors).length !== 0 ||
                                           announcement.loading
                                       }
                                    >
                                        {announcement.loading ? (
                                            <Loading color="text-white" size={5} />
                                        ) : (
                                            "Publish"
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default InfoAnnouncement;
