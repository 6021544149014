import React, { Component } from "react";
import Header from "../../../component/Header";
import Pagination from "../../../component/Pagination";
import { Link } from "react-router-dom";
import Modal from "../../../component/Modal";

import addIcon from "../../../assets/icon_add.svg";
import editIcon from "../../../assets/icon_edit.svg";
import trashIcon from "../../../assets/icon_trash.svg";
import { ToastContainer, toast } from "react-toastify";
import striptags from "striptags";
import DropDown from "../../../assets/icon_drop_down.svg";

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Breadcrumb from "../../../component/Breadcrumb";
import Layout from "../../Layout";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            items: [],
            divisions: [],
            selectedDivision: 1,
            types: [
                { label: "Division Journey", value: 0 },
                { label: "Division Chapter", value: 1 },
            ],
            selectedType: { id: 0, name: "Division Journey" },
            optionShow: false,
            currentPage: null,
            totalPages: null,
            deleteId: null,
            authorized: true,
            canCRUD: false,
            canView: false,
            isLoading: false,
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount() {
        //check if user can view or crud at this section
        this.setState({
            canCRUD:
                localStorage
                    .getItem("permissions")
                    .indexOf("can_create_chapter") > -1,
        });
        this.setState({
            canCRUD:
                localStorage
                    .getItem("permissions")
                    .indexOf("can_edit_chapter") > -1,
        });
        this.setState({
            canView:
                localStorage
                    .getItem("permissions")
                    .indexOf("can_view_chapters") > -1,
        });

        axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_API}/api/division/all`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((response) => {
                if (response.data.code !== 401) {
                    this.setState({ divisions: response.data.data });
                    this.changeDivision(response.data.data[0].id);
                } else {
                    this.setState({ divisions: [] });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        this.fetchChapter()
    }

    onPageChanged = (data) => {
        if (this.state.authorized === true) {
            const { currentPage } = data;

            axios
                .get(
                    `${process.env.REACT_APP_BASE_API}/api/chapter/page?page=${currentPage}&chapter_category=${this.state.selectedType.id}&division_id=${this.state.selectedDivision}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    this.setState({
                        items: response.data.data,
                        currentPage: response.data.meta.currentPage,
                        totalPages: response.data.meta.total,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    deleteItem = () => {
        axios({
            method: "DELETE",
            url: `${process.env.REACT_APP_BASE_API}/api/chapter/page/${this.state.deleteId}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((response) => {
                if (response.data.code === 401) {
                    this.setState({ show: false });
                    this.unauthorized();
                } else {
                    this.setState({
                        show: false,
                        deleteId: null,
                        items: this.state.items.filter(
                            (item) => item.id !== this.state.deleteId
                        ),
                    });
                    this.success();
                }
            })
            .catch(function (response) {
                console.log(response);
            });
    };

    success = () =>
        toast.success("Chapter page has been deleted", {
            position: toast.POSITION.TOP_CENTER,
        });

    unauthorized = () =>
        toast.warning("You aren't authorized to do this", {
            position: toast.POSITION.TOP_CENTER,
        });

    showModal = (id) => {
        this.setState({ show: true, deleteId: id });
    };

    hideModal = () => {
        this.setState({ show: false, deleteId: null });
    };

    changeDivision = async (id) => {
        //protects too many requests for the same from the user
        if (this.state.selectedDivision === id) return

        await this.setState({
            selectedDivision: id,
        });
        this.fetchChapter();
    };

    showSelect = () => {
        this.setState({ optionShow: !this.state.optionShow })
    }

    changeType = async (option) => {
        //protects too many requests for the same from the user
        if (this.state.selectedType.id === option.value) return

        await this.setState({
            optionShow: false,
            selectedType: {
                id: option.value,
                name: option.label
            }
        })
        this.fetchChapter();
    }

    //fetch chapter
    fetchChapter = async () => {
        this.setState({ isLoading: true });
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_API}/api/chapter/page?chapter_category=${this.state.selectedType.id}&division_id=${this.state.selectedDivision}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((response) => {
                this.setState({
                    items: response.data.data,
                    currentPage: response.data.meta.current_page,
                    totalPages: response.data.meta.total,
                    isLoading: false,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    render() {
        const { items, totalPages, divisions } = this.state;
        const breadcrumb = [
            { pageTitle: 'Home', pageHref: '/admin' },
            { pageTitle: 'Chapter', pageHref: '' },
        ]
        if (totalPages === null) return null;

        return (
            <Layout>
                <ToastContainer />
                <div className="min-h-screen p-12">
                    <Breadcrumb data={breadcrumb} />
                    <h1 className="mb-5 text-xl font-bold">Admin Chapter</h1>
                    {/* only show if user can crud */}
                    {this.state.canCRUD === true && (
                        <div className="mb-5">
                            <Link
                                to="/admin/chapter/create"
                                className="inline-flex items-center px-4 py-3 font-semibold tracking-wider text-white bg-red-700 rounded text-md hover:bg-red-900"
                            >
                                <img src={addIcon} alt="Add New"></img>
                                <div className="pl-2 ml-2 border-l-2 border-white">
                                    New
                                </div>
                            </Link>
                        </div>
                    )}

                    <div className="py-4 bg-white shadow-lg card">
                        <div className="flex items-center max-w-full px-6 py-3 my-4 overflow-y-auto bg-gray-200 card-ribbon flex-nowrap">
                            {divisions.map((division) => (
                                <button
                                    key={division.id}
                                    className={`p-3 mr-3 bg-white rounded border w-24 division-card  ${
                                        division.id ==
                                        this.state.selectedDivision
                                            ? "bg-red-500 text-white"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        this.changeDivision(division.id);
                                    }}
                                >
                                    {division.name}
                                </button>
                            ))}
                        </div>
                        <div className="relative mx-6 mb-5 w-44">
                            <button
                                type="button"
                                className="flex items-center justify-between w-full p-2 bg-white rounded ring-1 ring-gray-300"
                                onClick={() => this.showSelect()}
                            >
                                <span>{this.state.selectedType.name}</span>
                                <img src={DropDown} alt="Drop Down"></img>
                            </button>

                            {this.state.optionShow && (
                                <ul className="absolute w-full mt-2 rounded z-2 bg-gray-50 ring-1 ring-gray-300">
                                    {this.state.types?.map((option, index) => (
                                        <li
                                            key={index}
                                            className="p-2 cursor-pointer select-none hover:bg-gray-200"
                                            onClick={() =>
                                                this.changeType(option)
                                            }
                                        >
                                            {option.label}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="w-full px-4 overflow-x-auto">
                            {/* only show if user can view */}
                            {this.state.canView === true && (
                                <table className="w-full mx-auto overflow-hidden bg-white divide-y divide-gray-300 whitespace-nowrap">
                                    <thead className="bg-gray-50">
                                        <tr className="text-left text-gray-600">
                                            <th
                                                width="15%"
                                                className="px-6 py-4 text-sm font-semibold uppercase"
                                            >
                                                Division
                                            </th>
                                            <th
                                                width="20%"
                                                className="px-6 py-4 text-sm font-semibold uppercase"
                                            >
                                                Chapter Title
                                            </th>
                                            <th
                                                width="10%"
                                                className="px-6 py-4 text-sm font-semibold uppercase"
                                            >
                                                Category
                                            </th>
                                            <th
                                                width="35%"
                                                className="px-6 py-4 text-sm font-semibold uppercase"
                                            >
                                                Content
                                            </th>
                                            <th
                                                width="5%"
                                                className="px-6 py-4 text-sm font-semibold uppercase"
                                            >
                                                Order
                                            </th>
                                            <th
                                                width="15%"
                                                className="px-6 py-4 text-sm font-semibold uppercase"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {this.state.isLoading ? (
                                            <tr>
                                                <td colSpan={ 6 } className="text-center">
                                                    <div className="flex items-center justify-center p-3 bg-transparent h-52">
                                                        <svg
                                                            className="w-10 h-10 text-gray-500 animate-spin"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                className="opacity-25"
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                                stroke="currentColor"
                                                                strokeWidth="4"
                                                            ></circle>
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {items.map((item) => (
                                                    <tr key={item.id}>
                                                        <td className="px-6 py-4">
                                                            <div className="flex items-center space-x-3">
                                                                <div>
                                                                    <p className="">
                                                                        {
                                                                            item.chapter
                                                                                ?.division
                                                                                ?.name
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 overflow-hidden text-ellipsis max-w-0 whitespace-nowrap">
                                                            {item.title}
                                                        </td>
                                                        <td className="px-6 py-4">
                                                            {
                                                                item.chapter
                                                                    ?.category_description
                                                            }
                                                        </td>
                                                        <td className="relative block px-6 py-8 overflow-hidden text-ellipsis max-w-0 whitespace-nowrap lg:py-4 lg:table-cell lg:static">
                                                            {striptags(item.content)}
                                                        </td>
                                                        <td className="px-6 py-4 text-center">
                                                            {item.order}
                                                        </td>
                                                        {/* only show if user can crud */}
                                                        {this.state.canCRUD ===
                                                            true && (
                                                            <td className="flex items-center px-6 py-4">
                                                                <Link
                                                                    to={`/admin/chapter/${item.id}`}
                                                                >
                                                                    <img
                                                                        src={editIcon}
                                                                        alt="Edit"
                                                                    ></img>
                                                                </Link>
                                                                <button
                                                                    className="ml-2"
                                                                    onClick={() => {
                                                                        this.showModal(
                                                                            item.id
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={trashIcon}
                                                                        alt="Remove"
                                                                    ></img>
                                                                </button>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className="m-5">
                            <Modal
                                show={this.state.show}
                                handleClose={this.hideModal}
                            >
                                <div className="text-center">
                                    <p className="px-8 text-gray-500">
                                        Do you really want to delete this page?
                                        This process cannot be undone
                                    </p>
                                    <div className="p-3 mt-5 space-x-4 text-center md:block">
                                        <button
                                            onClick={this.hideModal}
                                            className="px-5 py-2 mb-2 text-sm font-medium tracking-wider text-gray-600 bg-white border rounded shadow-sm md:mb-0 hover:bg-gray-100"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={this.deleteItem}
                                            className="px-5 py-2 mb-2 text-sm font-medium tracking-wider text-white bg-red-700 border border-red-500 rounded shadow-sm md:mb-0 hover:bg-red-900"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                            {!this.state.isLoading && (
                                <>
                                    {/* only show if user can view */}
                                    {this.state.canView === true && (
                                        <Pagination
                                            totalRecords={totalPages}
                                            pageLimit={10}
                                            pageNeighbours={1}
                                            data={this.state.divisions}
                                            onPageChanged={this.onPageChanged}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Index;
