import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import logoutIcon from "../assets/icon_logout.svg";
import { Disclosure } from "@headlessui/react";

const linkNavigation = [
    { id: 1, name: "Home", href: "/home" },
    { id: 2, name: "News", href: "/news" },
    { id: 3, name: "Announcement", href: "/announcement" },
    { id: 4, name: "Event", href: "/event" },
    { id: 5, name: "Role Information", href: "/division" },
    { id: 6, name: "Admin Menu", href: "/admin" },
];
class Header extends Component {
    authLogOut() {
        const lastShown = localStorage.getItem('lastShown')
        localStorage.clear();
        localStorage.setItem('lastShown', lastShown)
        window.location.href = "/login";
    }

    render() {
        return (
            <Disclosure as="nav" className="fixed top-0 z-10 w-full bg-white shadow-md">
                <>
                    <div className="container mx-auto px-14">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
                                <div className="flex items-center shrink-0">
                                    <p className="font-sans text-3xl tracking-tighter text-red-600 pr-14">
                                        <b>DEV INFO</b>
                                    </p>
                                </div>
                                <div className="items-center justify-between flex-1 hidden md:flex">
                                    <div className="flex flex-row py-2">
                                        <div className="hidden sm:block sm:ml-auto">
                                            <div className="flex gap-12 text-gray-400">
                                                {linkNavigation.map((item) => (
                                                    <NavLink
                                                        key={item.id}
                                                        to={item.href}
                                                        className={`font-sans text-sm font-bold hover:text-red-600`}
                                                        activeClassName="text-red-600"
                                                    >
                                                        {item.name}
                                                    </NavLink>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center pl-14">
                                        {/* <div className="relative p-4 mr-6 bg-white rounded-lg shadow-lg">
                                                <FontAwesomeIcon
                                                    icon={faBell}
                                                />
                                                <BadgeRight amount="9"></BadgeRight>
                                            </div> */}
                                        <NavLink
                                            to="/"
                                            role="menuitem"
                                            onClick={this.authLogOut}
                                            className="flex w-full items-center gap-1 text-sm leading-5 bg-[#e8ebf1] hover:bg-[#d1d4da] text-gray-700 h-16 px-6 py-5 font-bold transition-all duration-300"
                                        >
                                            <img
                                                src={logoutIcon}
                                                alt="logout"
                                            />
                                            Log Out
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Disclosure>
        );
    }
}

export default Header;
