import React, { Component } from 'react';
import Header from '../../../component/Header';
import Pagination from '../../../component/Pagination';
import {Link} from 'react-router-dom';
import Modal from '../../../component/Modal'

import addIcon from '../../../assets/icon_add.svg';
import editIcon from '../../../assets/icon_edit.svg';
import trashIcon from '../../../assets/icon_trash.svg';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Layout from '../../Layout';
class Index extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            items: [],
            currentPage: 1,
            totalPages: null,
            deleteId: null,
            authorized: true,
            canCRUD: false,
            canView: false
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    //fetch data on mount
    componentDidMount() {
        //check if user can view or crud at this section
        this.setState({ canCRUD: ((localStorage.getItem('permissions')).indexOf('can_edit_role') > -1) })
        this.setState({ canView: ((localStorage.getItem('permissions')).indexOf('can_view_roles') > -1) })

        axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_API}/api/role/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        })
        .then(response => {
            if(response.data.code !== 401){
                this.setState({ items: response.data.data, currentPage: response.data.meta.current_page, totalPages: response.data.meta.total });
            }else{
                this.setState({ items: [], currentPage: 1, totalPages: 1, authorized: false })
            }
        })
        .catch(response => console.log(response))
    };

    deleteItem = () => {
            axios({
                method: "DELETE",
                url: `${process.env.REACT_APP_BASE_API}/api/role/`,
                data: {"role_id": this.state.deleteId},
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            })
            .then(response => {
                if(response.data.code === 401){
                    this.setState({ show: false })
                    this.unauthorized();
                }else{
                    //when delete is successful hide modal, and filter deleted item from table
                    this.setState({ show: false, deleteId: null, items: this.state.items.filter(item => item.id !== this.state.deleteId) });
                    this.success();
                }
            })
            .catch(function (response) {
                console.log(response);
            })
        
    };

    success = () => toast.success("Access Permission Type has been deleted", {
        position: toast.POSITION.TOP_CENTER
    });

    unauthorized = () => toast.warning("You aren't authorized to do this", {
        position: toast.POSITION.TOP_CENTER
    });

    //fetch new data when change page
    onPageChanged = data => {
        const { currentPage } = data;

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_API}/api/role?page=${currentPage}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                if(response.data.code !== 401){
                    this.setState({ items: response.data.data, currentPage: response.data.meta.current_page, totalPages: response.data.meta.total });
                }else{
                    this.setState({ items: [], currentPage: 1, totalPages: 1, authorized: false })
                }
            });
    };

    //set state on show modal
    showModal = (id) => {
        this.setState({ show: true, deleteId: id });
    };

    //set state on hide modal
    hideModal = () => {
        this.setState({ show: false, deleteId: null });
    };

    render() {
        const { items, totalPages } = this.state;

        if (totalPages === null) return null;

        return (
            <Layout>
                <ToastContainer />
                <div className="min-h-screen p-12">
                    <nav className="mb-5 text-xs font-bold text-black" aria-label="Breadcrumb">
                        <ol className="inline-flex p-0 list-none">
                            <li className="flex items-center">
                                <p className="text-gray-500">Access Permission</p>
                            </li>
                        </ol>
                    </nav>
                    <h1 className="mb-5 text-xl font-bold">
                        Access Permission
                    </h1>
                    <div className="py-4 bg-white shadow-lg card">
                        {/* only show if user can crud */}
                        {this.state.canCRUD === true &&
                        <div className="px-6 py-3 my-4 bg-gray-200 card-ribbon">
                            <Link to="/admin/role/create" className="inline-flex items-center px-4 py-3 font-semibold tracking-wider text-white bg-red-700 rounded text-md hover:bg-red-900">
                                <img src={addIcon} alt="Add New"></img>
                                <div className="pl-2 ml-2 border-l-2 border-white">New</div>
                            </Link>
                        </div>
                        }
                        <div className='w-full px-4 overflow-x-auto'>
                            {/* only show if user can view */}
                            {this.state.canView === true &&
                            <table className='w-full mx-auto overflow-hidden bg-white divide-y divide-gray-300 whitespace-nowrap'>
                                <thead className="bg-gray-50">
                                    <tr className="text-left text-gray-600">
                                        <th width="5%" className="hidden px-6 py-4 text-sm font-semibold uppercase lg:table-cell">
                                            NO
                                        </th>
                                        <th width="80%" className="hidden px-6 py-4 text-sm font-semibold uppercase lg:table-cell">
                                            Name
                                        </th>
                                        <th width="15%" className="hidden px-6 py-4 text-sm font-semibold uppercase lg:table-cell">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {items.map((item, index) => (
                                    <tr key={item.id} className="flex-row flex-wrap mb-10 lg:table-row lg:flex-row lg:flex-no-wrap lg:mb-0">
                                        <td className="relative block px-6 py-8 lg:py-4 lg:table-cell lg:static">
                                        <span className="absolute top-0 left-0 px-2 py-1 text-xs font-bold uppercase bg-blue-200 lg:hidden">NO</span>
                                            {(this.state.currentPage - 1) * 10 + index + 1 }
                                        </td>
                                        <td className="relative block px-6 py-8 lg:py-4 lg:table-cell lg:static">
                                        <span className="absolute top-0 left-0 px-2 py-1 text-xs font-bold uppercase bg-blue-200 lg:hidden">Name</span>
                                            {item.name}
                                        </td>
                                        {/* only show if user can crud */}
                                        {this.state.canCRUD === true &&
                                        <td className="flex px-6 py-8 text-center">
                                        <span className="absolute top-0 left-0 px-2 py-1 text-xs font-bold uppercase bg-blue-200 lg:hidden">Action</span>
                                            <Link to={`/admin/role/${item.id}`}>
                                                <img src={editIcon} alt="Edit"></img>
                                            </Link>
                                            <button className="ml-2" onClick={ () => {this.showModal(item.id)}}>
                                                <img src={trashIcon} alt="Remove"></img>
                                            </button>
                                        </td>
                                        }
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                            }
                        </div>
                        <Modal show={this.state.show} handleClose={this.hideModal}>
                            <div className="text-center">
                                <p className="px-8 text-gray-500">Do you really want to delete this Access Permission Type? This process cannot be undone</p>
                                <div className="p-3 mt-5 space-x-4 text-center md:block">
                                    <button onClick={this.hideModal} className="px-5 py-2 mb-2 text-sm font-medium tracking-wider text-gray-600 bg-white border rounded shadow-sm md:mb-0 hover:bg-gray-100">
                                        Cancel
                                    </button>
                                    <button onClick={this.deleteItem} className="px-5 py-2 mb-2 text-sm font-medium tracking-wider text-white bg-red-700 border border-red-500 rounded shadow-sm md:mb-0 hover:bg-red-900">Delete</button>
                                </div>
                            </div>
                        </Modal>
                        {/* only show if user can view */}
                        {this.state.canView === true &&
                        <div className="m-5">
                        <Pagination totalRecords={totalPages} pageLimit={10} pageNeighbours={1} onPageChanged={this.onPageChanged} />
                        </div>
                        }
                    </div>
                </div>
            </Layout>
        )
    }
}

export default Index;