import React, { Component } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";

import Logo from "./assets/logo_aqi.svg";
import Loading from "./component/Loading";
import { connect } from "react-redux";
import { login } from "./actions/auth";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            undefinedEmail: false,
            emailGoogle: "",
        };
        this.responseGoogle.bind(this);
    }

    checkUserType = (permissions) => {
        let adminPermissions = [
            "can_edit_user",
            "can_edit_chapter",
            "can_edit_chapter_page",
            "can_edit_division",
            "can_edit_role",
            "can_edit_event",
            "can_edit_news",
            "can_edit_announcement",
            "can_approve",
        ];
        return permissions.some((r) => adminPermissions.indexOf(r) >= 0);
    };

    errorHandle = (response) => {
        console.log(response);
    };

    responseGoogle = (response) => {
        const { dispatch } = this.props;
        let profile = jwtDecode(response.credential);
        this.setState({ emailGoogle: profile.email });
        const requestOptions = {
            email: this.state.emailGoogle,
        };
        dispatch(login({ data: requestOptions }))
            .unwrap()
            .then((response) => {
                if (response) {
                    localStorage.setItem(
                        "chapter",
                        response?.user?.chapter_finish
                    );
                    localStorage.setItem(
                        "division",
                        response?.user?.division_id
                    );
                    localStorage.setItem("token", response?.access_token);
                    localStorage.setItem("user", response?.user?.id);
                    localStorage.setItem("logged", true);
                    localStorage.setItem(
                        "role_id",
                        response?.user?.role?.id
                    );
                    localStorage.setItem(
                        "role",
                        response?.user?.role?.name
                    );

                    //get permissions and move it to local storage
                    let infoPermissionRaw =
                        response?.user?.role?.permissions;
                    let infoPermission = [];
                    if (infoPermissionRaw) {
                        infoPermissionRaw.map((permission) =>
                            infoPermission.push(permission.name)
                        );
                    }
                    localStorage.setItem(
                        "permissions",
                        JSON.stringify(infoPermission)
                    );

                    if (response?.user?.role?.name === "admin") {
                        localStorage.setItem("is_admin", true);
                        this.props.history.push("/home");
                    } else {
                        if (response?.user?.chapter_finish !== 0) {
                            this.props.history.push(
                                "/fresh-detail-division/" +
                                    response?.user?.division_id
                            );
                        } else {
                            this.props.history.push("/home");
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                if (error?.response?.status === 401) {
                    this.setState({ undefinedEmail: true });
                }
                this.setState({ undefinedEmail: true });
            });
    };

    render() {
        return (
            <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
            >
                <div className="items-center flex-none login-dev-info lg:flex">
                    <div className="container w-full mx-auto lg:w-2/5">
                        {this.state.undefinedEmail ? (
                            <div
                                className="flex items-center px-4 py-3 mb-5 text-sm font-bold text-white bg-blue-500"
                                role="alert"
                            >
                                <svg
                                    className="w-4 h-4 mr-2 fill-current"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                                </svg>
                                <p>Login failed, please try again.</p>
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <div className="flex flex-col items-center w-full h-auto py-20 text-center bg-white login-card">
                            <article className="max-w-full prose mb-14">
                                <h3>
                                    Welcome to{" "}
                                    <span className="red">AQI Portal</span>
                                </h3>
                            </article>
                            {this.props.loading ? (
                                <Loading color="text-blue-600" />
                            ) : (
                                <GoogleLogin
                                    onSuccess={this.responseGoogle}
                                    onError={this.errorHandle}
                                    theme="filled_blue"
                                    logo_alignment="left"
                                    locale="en_UK"
                                />
                            )}
                        </div>
                    </div>
                    <div className="fixed w-full text-xs text-center powered bottom-16">
                        Powered By &nbsp;:&nbsp;{" "}
                        <img src={Logo} className="inline" alt="Logo Aqi"></img>
                    </div>
                </div>
            </GoogleOAuthProvider>
        );
    }
}

function mapStateToProps(state) {
    const { loading } = state.auth;
    return {
        loading,
    };
}

export default connect(mapStateToProps)(Login);
