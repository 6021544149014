import React from "react";
import PropTypes from "prop-types";
import editIcon from "../assets/icon_edit.svg";
import trashIcon from "../assets/icon_trash.svg";
import Loading from "../component/Loading"

const Table = (props) => {
    const { columns, data } = props;
    return (
        <table
            width="100%"
            className={`${props.className} w-full rounded-md overflow-hidden mx-auto bg-white text-[#385f80] border-[#c4c4c4] border`}
        >
            <thead className="bg-[#f6dcde]">
                <tr>
                    {columns?.map((column, colIndex) => (
                        <th
                            key={colIndex}
                            width={column.width}
                            title={column.name}
                            className="max-w-lg px-4 py-4 text-sm font-semibold capitalize border-[#c4c4c4] border-r"
                        >
                            {column.name}
                        </th>
                    ))}
                    {props.actions && props.canCRUD && (
                        <th
                            width="10%"
                            title="Actions"
                            className="max-w-lg px-4 py-4 text-sm font-semibold capitalize border-[#c4c4c4] border-r"
                        >
                            Actions
                        </th>
                    )}
                </tr>
            </thead>
            <tbody className="table-body">
                {props.isLoading ? (
                    <tr>
                        <td
                            colSpan={
                                props.actions
                                    ? columns.length + 1
                                    : columns.length
                            }
                            className="text-center"
                        >
                            <div className="flex items-center justify-center p-3 bg-transparent h-52">
                                <Loading />
                            </div>
                        </td>
                    </tr>
                ) : (
                    <>
                        {data && data?.length > 0 ? (
                            data?.map((rows, rowIndex) => (
                                <tr key={rowIndex}>
                                    {rows.items.map((item, itemIndex) => (
                                        <td
                                            key={itemIndex}
                                            width="10%"
                                            className="w-10 px-4 py-4 overflow-hidden text-sm text-center border-[#c4c4c4] border-r text-ellipsis max-w-0 whitespace-nowrap"
                                        >
                                            {item}
                                        </td>
                                    ))}
                                    {props.actions && props.canCRUD ? (
                                        rows.approval_status === 2 && props.isAdmin ? (
                                            <td className="flex justify-center px-6 py-4">
                                                <button
                                                    onClick={() =>
                                                        props?.approvalAction(
                                                            rows.id
                                                        )
                                                    }
                                                    title="Approval"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                                                        />
                                                    </svg>
                                                </button>
                                            </td>
                                        )  : (
                                        <td className="flex justify-center px-6 py-4">
                                            <button
                                                onClick={() =>
                                                    props?.editAction(rows.id)
                                                }
                                                title="Edit"
                                            >
                                                <img
                                                    src={editIcon}
                                                    alt="Edit"
                                                ></img>
                                            </button>
                                            <button
                                                className="ml-2"
                                                onClick={() =>
                                                    props?.deleteAction(rows.id)
                                                }
                                                title="Delete"
                                            >
                                                <img
                                                    src={trashIcon}
                                                    alt="Remove"
                                                ></img>
                                            </button>
                                        </td>
                                    ) ) : null}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={
                                        props.actions
                                            ? columns.length + 1
                                            : columns.length
                                    }
                                    className="text-center"
                                >
                                    <div className="flex items-center justify-center p-3 bg-transparent h-52">
                                        <span>
                                            No such {props.name} was found
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </>
                )}
            </tbody>
        </table>
    );
};

Table.propTypes = {
    name: PropTypes.string,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array,
    actions: PropTypes.bool,
    editAction: PropTypes.func,
    deleteAction: PropTypes.func,
    canCRUD: PropTypes.bool,
    isAdmin: PropTypes.bool,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
};

export default Table;
