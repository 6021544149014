import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getDetailAnnouncement } from "../../actions/announcement";
import Layout from "../Layout";

class DetailAnnouncement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            announcement: {},
            isLoading: false,
        };
    }

    getAnnouncement = async () => {
        const { dispatch, match } = this.props;
        const params = match.params;
        await dispatch(getDetailAnnouncement({ id: params.announcement_id }))
            .unwrap()
            .then((res) => {
                this.setState({ announcement: res.data });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    componentDidMount() {
        this.getAnnouncement();
    }

    showPopupImage() {
        
    }

    render() {
        const { announcement } = this.state;
        return (
            <Layout>
                <div className="container px-14">
                    <div className="flex flex-col w-full">
                        {this.props.loading ? (
                            <div className="animate-pulse flex flex-col w-full gap-3 px-10 pt-10 pb-5">
                                <div className="h-80 w-full rounded-lg bg-gray-300"></div>
                                <div className="flex items-center justify-between w-full text-[#385f80]">
                                    <div className="w-7/12 h-6 bg-gray-300 rounded-full"></div>
                                    <div className="px-6 py-5 text-base bg-gray-200 rounded-md">
                                        <div className="h-4 w-44 bg-gray-300 rounded-full"></div>
                                    </div>
                                </div>
                                <hr />
                                <div className="mt-5 flex flex-col gap-2">
                                    <div className="text-sm leading-7 w-full bg-gray-300 h-3 rounded-full" />
                                    <div className="text-sm leading-7 w-full bg-gray-300 h-3 rounded-full" />
                                    <div className="text-sm leading-7 w-full bg-gray-300 h-3 rounded-full" />
                                    <div className="text-sm leading-7 w-11/12 bg-gray-300 h-3 rounded-full" />
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col w-full gap-3 px-10 pt-10 pb-5">
                                <div className="flex justify-center">
                                    <img
                                        src={announcement.image}
                                        alt={announcement.title}
                                        className="rounded-lg w-fit"
                                    />
                                </div>
                                <div className="flex items-center justify-between w-full text-[#385f80]">
                                    <h1 className="w-8/12 text-2xl font-bold">
                                        {announcement.title}
                                    </h1>
                                    <div className="px-6 py-5 text-base bg-gray-200 rounded-md">
                                        {announcement.user?.name} /{" "}
                                        {moment(announcement.created_at).format(
                                            "LL"
                                        )}
                                    </div>
                                </div>
                                <hr />
                                <div
                                    className="text-sm leading-7 text-[#2a374d] mt-5 w-full break-words"
                                    dangerouslySetInnerHTML={{
                                        __html: announcement.content,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    const { loading } = state.announcement;
    return {
        loading,
    };
}

export default connect(mapStateToProps)(DetailAnnouncement);
