import propTypes from "prop-types";

const Modal = ({ handleClose, show, children, size = "md", className }) => {
    const showHideClassName = show
        ? "relative modal flex justify-center items-center z-10 transition duration-300"
        : "modal display-none";

    return (
        <div className={showHideClassName} onClick={handleClose}>
            <section
                className={`relative w-full ${
                    size === "md" ? "max-w-lg" : "max-w-2xl"
                } max-h-screen p-5 mx-auto my-auto bg-white shadow-lg rounded-xl ${className}`}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </section>
        </div>
    );
};

Modal.propTypes = {
    size: propTypes.oneOf(["md", "lg"]),
};

export default Modal;
