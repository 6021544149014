import React, { useState } from 'react';
import Header from '../../../../component/Header';
import AsyncSelect from 'react-select/async';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import { useParams, useHistory } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Breadcrumb from '../../../../component/Breadcrumb';
import Layout from '../../../Layout';


const customStyles = {
    option: (provided, state) => ({
      ...provided
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: 'flex',
      background: '#fff',
      border: '1px solid rgb(209, 213, 219)',
      padding: '2px .1rem'
    }),
    menu: () => ({
    //   none of react-select's styles are passed to <Control />
      padding: '0',
      background: '#fff',
      position: 'absolute',
      top: '100%',
      boxShadow: "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)",
      width: '100%',
      zIndex: '500'
    }),
};

//temporary variable for division list
let responseDivision = [];

//options for division list
const options = [];

//options data for role select
const roleOptions = [
];

const InfoUser = () => {
    const history = useHistory();
    const success = () => toast.success("User has been edited!", {
        position: toast.POSITION.TOP_CENTER,
        onClose: props => history.push("/admin/user")
    });

    const unauthorized = () => toast.warning("You aren't authorized", {
        position: toast.POSITION.TOP_CENTER,
    });

    //state for selected division
    let [selectedDivision, setSelectedDivision] = useState();

    //state for selected role
    let [selectedRole, setSelectedRole] = useState();

    //state for error catching from server
    var [err, setErr] = useState({});

    //state if form has error form server
    var [hasError, setHasError] = useState(false);

    //get user id from params
    const { id } = useParams();

    //declare function from react hook form
    const { handleSubmit, register, formState: { errors }, control, setValue } = useForm();

    //fetching role
    const fetchRole = React.useCallback(() => {
        axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API}/api/role`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            })
            .then(response => {
                if (response.data.code !== 401) {
                    let roleRaw = response.data.data
                    if (roleRaw.length > 0) {
                        //restructure response for division select
                        roleRaw.forEach(role => {
                        let roleDivide = {}
                        roleDivide.value = role.id
                        roleDivide.label = role.name
                        roleOptions.push(roleDivide)
                        })
                    }
                }
            })
    }, [])
    
    //function to fetch division list
    const fetchDivision = React.useCallback(() => {
        axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API}/api/division/all`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            })
            .then(response => {
                if (response.data.code !== 401) {
                    responseDivision = response.data.data
                    if (responseDivision.length > 0) {
                        responseDivision.forEach(division => {
                        let divisionDivide = {}
                        divisionDivide.value = division.id
                        divisionDivide.label = division.name
                        options.push(divisionDivide)
                        })
                    }
                }
            });
    }, []);

    //function to fetch user info
    const fetchInfo = React.useCallback(() => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_BASE_API}/api/admin/user/${id}`,
            params: { "user_id": id },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        })
        .then((response) => {
            setValue('email', response.data.data.email)
            setValue('name', response.data.data.name)
            setValue('division_id', response.data.data.division_id)

            //assign default selected division
            const defaultSelect = {value: response.data.data.division_id, label: response.data.data.division.name}
            setSelectedDivision(defaultSelect)

            //assign default selected role
            const defaultSelectRole = {value: response.data.data.roles[0].id, label: response.data.data.roles[0].name}
            handleChangeRole(defaultSelectRole)
        })
        .catch((error) => {
            console.log(error);
        })
    }, []);


    //fetching division list on page load
    React.useEffect(() => {
        fetchDivision()
    }, [fetchDivision]);

    //fetching user info on page load
    React.useEffect(() => {
        fetchInfo()
    }, [fetchInfo]);

    //fetching role list on page load
    React.useEffect(() => {
        fetchRole()
    }, []);

    const onSubmit = (data) => {
            axios({
                method: "put",
                url: `${process.env.REACT_APP_BASE_API}/api/admin/user/${id}`,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            })
            .then(function (response){
                if (response.data.code !== 401) {
                    success();
                }else{
                    unauthorized();
                }
            })
            .catch(error => {
                if (error.response) {
                    setErr(error.response.data.errors);
                    setHasError(true);
                }
            });
    };

    //handling selected division
    const handleChangeDivision = (change) => {
        setSelectedDivision(change)
        setValue("division_id", change.value);
    };

    //handling selected role
    const handleChangeRole = (change) =>{
        setSelectedRole(change)
        setValue("role", change.label);
    };

    //function to render error from server
    function ErrorComponent() {
        return Object.keys(err).map((visit, index) => 
            <ul className="list-none">
                <li className="p-3 mb-4 text-white bg-red-500 radius-sm" key={index}>{err[visit]}</li>
            </ul>
        )
    };

    const breadcrumb = [
        { pageTitle: 'Home', pageHref: '/admin' },
        { pageTitle: 'User', pageHref: '/admin/user' },
        { pageTitle: 'Create', pageHref: '' },
    ]

    return (
        <Layout>
            <ToastContainer />
            <div className="min-h-screen p-12">
                <Breadcrumb data={breadcrumb} />
                <h1 className="mb-5 text-xl font-bold">
                    Admin User Info
                </h1>
                <div className="px-4 py-6 bg-gray-200 shadow-lg card">
                    {hasError && <ErrorComponent></ErrorComponent>}
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-1/5">
                        <div className="mb-6">
                            <label for="email" className="block mb-2 text-sm font-semibold text-blue-900">Email</label>
                            <input 
                                type="text" 
                                name="email" 
                                id="email" 
                                {...register("email", { 
                                    required: 'Email is required', 
                                    pattern: { 
                                        //check if use aqi email
                                        value: /([a-zA-Z0-9]+)([.{1}])?([a-zA-Z0-9]+)@aqi([.])co([.])id/g, 
                                        message: "Please use AQI email."} 
                                })} 
                                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                            {errors?.email && <div>{errors.email.message}</div>}
                        </div>
                        <div className="mb-6">
                            <label for="name" className="block mb-2 text-sm font-semibold text-blue-900">Name</label>
                            <input 
                                type="text" 
                                name="name" 
                                id="name" 
                                {...register("name", { 
                                    required: 'Name is required'
                                })}  
                                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                            {errors?.name && <div>{errors.name.message}</div>}
                        </div>
                        <div className="mb-6">
                            <label for="division" className="block mb-2 text-sm font-semibold text-blue-900">Role</label>
                            <Controller
                                control={control}
                                name="division_id"
                                render={({ field }) => (
                                    <AsyncSelect 
                                        {...field}
                                        className="w-full" 
                                        styles={customStyles} 
                                        defaultOptions={options} {...register("division_id", { required: 'Please choose role' })} 
                                        onChange={handleChangeDivision}
                                        value={selectedDivision}
                                        />
                                )}
                            />
                            {errors?.division_id && <div>{errors.division_id.message}</div>}
                        </div>
                        <div className="mb-6">
                            <label for="role" className="block mb-2 text-sm font-semibold text-blue-900">Access Permission Type</label>
                            <Controller
                                control={control}
                                name="role"
                                options={roleOptions} 
                                render={({ onChange, value }) => (
                                    <AsyncSelect 
                                        className="w-full" 
                                        isSearchable={false}
                                        styles={customStyles} 
                                        defaultOptions={roleOptions} {...register("role", { required: 'Please choose Access Permission Type' })} 
                                        onChange={handleChangeRole}
                                        value={selectedRole}
                                        />
                                )}
                            />
                            {errors?.role && <div>{errors.role.message}</div>}
                        </div>
                        <div className="flex justify-between">
                            <a href="/admin/user" className="w-24 px-4 py-2 text-sm font-semibold tracking-wider text-center text-white bg-gray-400 rounded hover:bg-gray-700">Cancel</a>
                            <button type="submit" className="w-24 px-4 py-2 text-sm font-semibold tracking-wider text-white bg-green-600 rounded hover:bg-green-700">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default InfoUser