import { Link } from "react-router-dom";

const DashboardCard = ({title, children, url}) => {
    return (
        <Link to={url} className="flex flex-col items-center w-full p-6 text-red-600 bg-white shadow-md md:w-2/3 rounded-xl hover:shadow-lg hover:text-white hover:bg-red-600">
            {children}
            <div className="text-xl text-center">
                { title }
            </div>
        </Link>
    );
}
export default DashboardCard