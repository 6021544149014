import axios from "axios";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import Layout from "../Layout";
import Loading from "../../component/Loading";

function NewlineText(props) {
    const text = props.text;
    const newText = text ? text.split("\n").map((str, i) => <p key={i}>{str}</p>) : "";
    return newText;
}

class DetailDivision extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indexDetailChapter: 0,
            itemsChapter: [],
            idDiv: "",
            title: "",
            desc: "",
            img: "",
            isLoading: false,
        };

        if (
            (localStorage.getItem("is_admin") === null ||
                localStorage.getItem("is_admin") === "false") &&
            localStorage.getItem("chapter") != 0
        ) {
            this.props.history.push(
                "/fresh-detail-division/" + localStorage.getItem("division")
            );
        }
    }

    componentDidMount() {
        let id = this.props.match.params.post_id;
        this.setState({
            idDiv: id,
            isLoading: true,
        });

        const AuthStr = "Bearer ".concat(localStorage.getItem("token"));
        axios
            .get(`${process.env.REACT_APP_BASE_API}/api/division/` + id, {
                headers: { Authorization: AuthStr },
            })
            .then((response) => {
                this.setState({ desc: response.data.data.description });
                this.setState({ img: response.data.data.image });
                this.setState({ title: response.data.data.name });
            });

        axios
            .get(
                `${process.env.REACT_APP_BASE_API}/api/chapter/page/all?chapter_category=1&division_id=` +
                    id,
                { headers: { Authorization: AuthStr } }
            )
            .then((response) => {
                this.setState({ itemsChapter: response.data.data });
                this.setState({ isLoading: false });
            });
    }

    chapterList = (data) => {
        this.setState({ indexDetailChapter: data });
    };

    render() {
        return (
            <Layout className="bg-[#f7fafc]">
                <div className="mx-5 md:mx-36 py-10">
                    <div className="grid grid-cols-7 gap-4">
                        <div className="col-span-4 md:col-span-2">
                            <div className="w-full mx-auto rounded-md bg-white py-4">
                                <Link
                                    to={
                                        "/fresh-detail-division/" +
                                        this.state.idDiv
                                    }
                                    className="bg-red-ffefef hover:bg-red-700 text-red-c22125 hover:text-white py-2 px-14 font-bold tracking-tighter rounded-full w-full block text-center"
                                >
                                    START JOURNEY &nbsp;&nbsp;{" "}
                                    <i className="fas fa-arrow-circle-right"></i>
                                </Link>

                                <div className="p-4 my-2">
                                    <p className="font-bold tracking-tighter text-grey-52627e">
                                        Chapter List
                                    </p>
                                </div>

                                <div>
                                    <ul>
                                        <li>
                                            <button
                                                onClick={() =>
                                                    this.chapterList(0)
                                                }
                                                className="hover:bg-gray-200 font-bold tracking-tighter text-grey-52627e px-4 py-3 cursor-pointer flex items-center w-full text-left"
                                            >
                                                <span className="px-2 py-1 bg-grey-52627e text-white">
                                                    1
                                                </span>{" "}
                                                &nbsp;{" "}
                                                <div className="ml-3">
                                                    Job Description
                                                </div>
                                            </button>
                                        </li>
                                        {this.state.itemsChapter.map(
                                            (item, index) => (
                                                <li
                                                    key={item.id}
                                                    onClick={() =>
                                                        this.chapterList(
                                                            index + 1
                                                        )
                                                    }
                                                >
                                                    <button className="hover:bg-gray-200 font-bold tracking-tighter text-grey-52627e px-4 py-3 cursor-pointer flex items-center w-full text-left">
                                                        <span className="px-2 py-1 bg-grey-52627e text-white">
                                                            {index + 2}
                                                        </span>{" "}
                                                        &nbsp;{" "}
                                                        <div className="ml-3">
                                                            {item.title}
                                                        </div>
                                                    </button>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-4 md:col-span-5">
                            <div className="w-full mx-auto rounded-md bg-white py-4">
                                {this.state.isLoading ? (
                                    <>
                                        <div className="flex justify-center items-center bg-transparent p-3 h-52">
                                            <Loading />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {this.state.indexDetailChapter === 0 ? (
                                            <>
                                                <div className="bg-grey-e8ebf1 text-center w-full">
                                                    <p className="text-gray-2a374d tracking-tighter p-4 font-bold text-base uppercase">
                                                        {this.state.title}
                                                    </p>
                                                </div>

                                                <div className="p-4 my-2">
                                                    <div
                                                        className="bg-local w-full h-96 rounded mb-5"
                                                        style={{
                                                            backgroundImage: `url(${this.state.img})`,
                                                        }}
                                                    ></div>
                                                    <NewlineText
                                                        text={this.state.desc}
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            this.state.itemsChapter.map(
                                                (item, index) =>
                                                    this.state
                                                        .indexDetailChapter ===
                                                    index + 1 ? (
                                                        <>
                                                            <div
                                                                className="bg-grey-e8ebf1 text-center w-full"
                                                                key={item.id}
                                                            >
                                                                <p className="text-gray-2a374d tracking-tighter p-4 font-bold text-base uppercase">
                                                                    {item.title}
                                                                </p>
                                                            </div>

                                                            <div className="p-4 my-2">
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item.content,
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div></div>
                                                    )
                                            )
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default DetailDivision;
