import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getDetailEvent, getRandomEvent } from "../../actions/event";
import ListItem from "../../component/ListItem";
import Loading from "../../component/Loading";
import SectionHeader from "../../component/SectionHeader";
import Layout from "../Layout";
import { BsFileEarmarkFill } from "react-icons/bs";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Modal from "../../component/Modal";
import { FaTimes } from "react-icons/fa";
import { AiOutlineCloudDownload } from "react-icons/ai";
class DetailEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: {},
            attachmentName: {},
            attachmentExt: {},
            randomEvent: [],
            isLoading: false,
            showDocPreview: false,
        };
    }

    getEvent = async () => {
        const { dispatch, match } = this.props;
        const params = match.params;
        await dispatch(getDetailEvent({ id: params.event_id }))
            .unwrap()
            .then((res) => {
                let attachmentSplit = res.data.attachment?.split("/");
                let attachmentName = attachmentSplit
                    ? attachmentSplit[attachmentSplit?.length - 1]
                    : "-";
                let attachmentExt = attachmentName.split(".");
                this.setState({
                    event: res.data,
                    attachmentName: attachmentName,
                    attachmentExt: attachmentExt
                        ? attachmentExt[attachmentExt?.length - 1]
                        : "-",
                });
            });
    };

    getRandomEvent = async () => {
        const { dispatch } = this.props;
        await dispatch(getRandomEvent({ limit: 2 }))
            .unwrap()
            .then((res) => {
                this.setState({ randomEvent: res.data });
            });
    };

    componentDidMount() {
        this.getEvent();
        this.getRandomEvent();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params !== prevProps.match.params) {
            this.getEvent();
            this.getRandomEvent();
        }
    }
    render() {
        const { event, showDocPreview } = this.state;
        const ekstensionsList = /(zip|rar|7z)$/i;
        return (
            <Layout>
                <div className="container px-14">
                    <div className="flex flex-col w-full">
                        {this.props.loading ? (
                            <div className="flex flex-col w-full gap-3 px-10 pt-10 pb-5 animate-pulse">
                                <div className="w-full bg-gray-300 rounded-lg h-80"></div>
                                <div className="flex items-center justify-between w-full text-[#385f80]">
                                    <div className="w-7/12 h-6 bg-gray-300 rounded-full"></div>
                                    <div className="px-6 py-5 text-base bg-gray-200 rounded-md">
                                        <div className="h-4 bg-gray-300 rounded-full w-44"></div>
                                    </div>
                                </div>
                                <hr />
                                <div className="flex flex-col gap-2 mt-5">
                                    <div className="w-full h-3 text-sm leading-7 bg-gray-300 rounded-full" />
                                    <div className="w-full h-3 text-sm leading-7 bg-gray-300 rounded-full" />
                                    <div className="w-full h-3 text-sm leading-7 bg-gray-300 rounded-full" />
                                    <div className="w-11/12 h-3 text-sm leading-7 bg-gray-300 rounded-full" />
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col w-full gap-3 px-10 pt-10 pb-5">
                                <div className="flex justify-center">
                                    <img
                                        src={event.image}
                                        alt={event.title}
                                        className="rounded-lg w-fit"
                                    />
                                </div>
                                <div className="flex items-center justify-between w-full text-[#385f80]">
                                    <h1 className="w-8/12 text-2xl font-bold">
                                        {event.title}
                                    </h1>
                                    <div className="px-6 py-2 text-base bg-[#c22125] rounded-md">
                                        <div className="flex items-center gap-3 font-bold text-white">
                                            <i className="text-2xl fas fa-calendar-check"></i>
                                            <span className="text-sm">
                                                {moment(
                                                    event?.started_at
                                                ).format("Do MMM YY")}{" "}
                                                -{" "}
                                                {moment(event?.ended).format(
                                                    "Do MMM YY"
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div
                                    className="text-sm leading-7 text-[#2a374d] mt-5 w-full break-words"
                                    dangerouslySetInnerHTML={{
                                        __html: event.content,
                                    }}
                                />
                                <div className="text-sm leading-7 text-[#2a374d] flex flex-col">
                                    <span className="font-bold leading-7 text-[#c22125]">
                                        Attachment
                                    </span>
                                    {event.attachment ? (
                                        <>
                                            {ekstensionsList.exec(
                                                this.state.attachmentExt
                                            ) ? (
                                                <a
                                                    href={event.attachment}
                                                    className="flex items-center gap-2 hover:underline w-fit"
                                                    title={
                                                        this.state
                                                            .attachmentName
                                                    }
                                                >
                                                    <BsFileEarmarkFill
                                                        size={22}
                                                        color="#2a374d"
                                                    />
                                                    {this.state.attachmentName}
                                                </a>
                                            ) : (
                                                <button
                                                    className="flex items-center gap-2 hover:underline w-fit"
                                                    title={
                                                        this.state
                                                            .attachmentName
                                                    }
                                                    onClick={() =>
                                                        this.setState({
                                                            showDocPreview: true,
                                                        })
                                                    }
                                                >
                                                    <BsFileEarmarkFill
                                                        size={22}
                                                        color="#2a374d"
                                                    />
                                                    {this.state.attachmentName}
                                                </button>
                                            )}
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <hr />
                <div className="container my-5 px-14">
                    <SectionHeader title="Incoming Events" withLink={false} />
                    <div className="flex justify-between">
                        {this.props.loading ? (
                            <div className="flex items-center justify-center w-full mt-5">
                                <Loading />
                            </div>
                        ) : (
                            <ListItem
                                name="event"
                                data={this.state.randomEvent}
                                itemLink="/event"
                                withReadMore={false}
                                className="w-6/12 h-36"
                                contentRow={2}
                            />
                        )}
                    </div>
                </div>
                <Modal
                    className="overflow-y-auto preview-modal max-w-7xl h-[90vh]"
                    show={showDocPreview}
                    handleClose={() => this.setState({ showDocPreview: false })}
                >
                    <div className="flex justify-end">
                        <button
                            onClick={() =>
                                this.setState({ showDocPreview: false })
                            }
                        >
                            <FaTimes />
                        </button>
                    </div>
                    <DocViewer
                        className="max-h-[90%] overflow-y-auto"
                        documents={[{ uri: event?.attachment }]}
                        pluginRenderers={DocViewerRenderers}
                    />
                    <div className="static flex justify-end gap-2 mt-4">
                        <button
                            onClick={() =>
                                this.setState({ showDocPreview: false })
                            }
                            className="flex items-center justify-center w-24 px-4 py-2 text-sm font-semibold tracking-wider text-center text-white bg-red-600 rounded hover:bg-red-700"
                        >
                            Back
                        </button>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={event.attachment}
                            className="flex items-center justify-center gap-1 px-4 py-2 text-sm font-semibold tracking-wider text-center text-white bg-green-600 rounded hover:bg-green-700"
                        >
                            <AiOutlineCloudDownload size="16px" />
                            Download
                        </a>
                    </div>
                </Modal>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    const { loading } = state.event;
    return {
        loading,
    };
}

export default connect(mapStateToProps)(DetailEvent);
