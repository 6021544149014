import { createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { getUser, login } from "../actions/auth";

const initialState = {
    loading: false,
    data: {},
    user: {},
    error: null,
    success: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, { payload }) => {
                state.data = payload
                state.user = payload.user
            })
            .addCase(getUser.fulfilled, (state, { payload }) => {
                state.user = payload
            })
            .addMatcher(isPending, (state) => {
                state.loading = true
            })
            .addMatcher(isFulfilled, (state) => {
                state.loading = false
            })
            .addMatcher(isRejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })
    },
});

export default authSlice.reducer;
