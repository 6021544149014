import React, { Component } from "react";
import Pagination from "rc-pagination";
import { Link } from "react-router-dom";
import Modal from "../../../component/Modal";

import addIcon from "../../../assets/icon_add.svg";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { SearchIcon } from "@heroicons/react/outline";
import { connect } from "react-redux";
import moment from "moment/moment";
import "moment/locale/id";
import { getListNews, deleteNews } from "../../../actions/news";
import Table from "../../../component/Table";
import Select from "../../../component/Select";
import Layout from "../../Layout";
import capitalize from "../../../utils/Capitalize";
import { toastError, toastSuccess } from "../../../component/Toast";
import { getIsAdmin, getPermission } from "../../../services/storage.service";
import Search from "../../../component/Search";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            items: [],
            options: [
                { label: "Sort Latest", value: "desc" },
                { label: "Sort Oldest", value: "asc" },
            ],
            selectedOption: { label: "Sort Latest", value: "desc" },
            optionShow: false,
            searchKeyword: "",
            currentPage: 1,
            totalPages: null,
            deleteId: null,
            authorized: true,
            canCRUD: false,
            canView: false,
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount() {
        //check if user can view or crud at this section
        this.setState({
            canCRUD:
                getPermission().indexOf("can_create_news") > -1 &&
                getPermission().indexOf("can_edit_news") > -1,
            canView: getPermission().indexOf("can_view_news") > -1,
        });

        this.fetchNews();
    }

    fetchNews = async (data) => {
        const { dispatch, newsList } = this.props;

        dispatch(
            getListNews({
                id: data?.current ?? "",
                approval: getIsAdmin() ? "0,2,3,4" : "",
                sort: data?.sort,
                keyword: data?.keyword,
            })
        )
            .unwrap()
            .then((res) => {
                this.setState({
                    currentPage: data?.current,
                    totalPages: newsList?.meta?.total,
                });
            });
    };

    deleteItem = async () => {
        const { dispatch, newsList } = this.props;
        this.setState({
            show: false,
        });
        dispatch(deleteNews({ id: this.state.deleteId }))
            .unwrap()
            .then((data) => {
                toastSuccess(capitalize(data.message));
                this.fetchNews({
                    current:
                        newsList?.data?.length === 1 &&
                        newsList?.meta?.current_page > 0
                            ? newsList?.meta?.current_page - 1
                            : newsList?.meta?.current_page,
                    sort: this.state.selectedOption.value,
                    keyword: this.state.searchKeyword,
                });
            })
            .catch((err) => {
                toastError(capitalize(err.message));
            });
    };

    editItem = (id) => {
        const { history } = this.props;
        history.push("/admin/news/" + id);
    };

    approvalItem = (id) => {
        const { history } = this.props;
        history.push("/admin/news/approval/" + id);
    };

    changeOption = async (selected) => {
        //protects too many requests for the same from the user
        if (this.state.selectedOption.value === selected.value) return;

        await this.setState({
            optionShow: false,
            selectedOption: selected,
        });

        this.fetchNews({
            current: this.state.currentPage,
            sort: selected.value,
            keyword: this.state.searchKeyword,
        });
    };

    onSearch = (e) => {
        e.preventDefault();
        this.fetchNews({
            sort: this.state.selectedOption.value,
            keyword: this.state.searchKeyword,
        });
    };

    showModal = (id) => {
        this.setState({ show: true, deleteId: id });
    };

    hideModal = () => {
        this.setState({ show: false, deleteId: null });
    };

    showSelect = () => {
        this.setState({ optionShow: !this.state.optionShow });
    };

    render() {
        const { newsList, loading } = this.props;
        // Columns for Table component
        const columns = [
            { name: "id", width: "5%" },
            { name: "News Title", width: "35%" },
            { name: "Page View", width: "10%" },
            { name: "Creator", width: "15%" },
            { name: "Last Update", width: "15%" },
            { name: "Status", width: "10%" },
        ];

        // Data for Table component
        const data =
            newsList?.data?.length !== 0 ?
            newsList?.data?.map((item) => ({
                id: item.id,
                items: [
                    item?.id,
                    item?.title,
                    item?.viewer ?? 0,
                    item?.user?.name,
                    moment(item?.updated_at).format("LL"),
                    <span
                        className={`status-approval status-${item?.approval_status_description.toLowerCase()}`}
                    >
                        {item?.approval_status_description}
                    </span>,
                ],
                approval_status: item?.approval_status,
            })) : [];

        return (
            <Layout>
                <ToastContainer />
                <div className="container flex justify-center py-5">
                    <div className="px-3 py-2 rounded-md bg-[#f8fafd] flex justify-center gap-5">
                        <form
                            className="flex items-center h-full gap-5"
                            onSubmit={this.onSearch}
                        >
                           <Search
                            value={this.state.searchKeyword}
                            onChange={(e) =>
                                this.setState({
                                    searchKeyword: e.target.value,
                                })
                            }
                            onSubmit={this.onSearch}
                            placeholder="Search by title"
                        />
                        </form>
                        <Select
                            options={this.state.options}
                            onClick={this.showSelect}
                            onChange={(selected) => this.changeOption(selected)}
                            show={this.state.optionShow}
                        />
                    </div>
                </div>
                <hr />
                <div className="container min-h-screen py-5 px-14">
                    {/* only show if user can crud */}
                    {this.state.canCRUD === true && (
                        <Link
                            to="/admin/news/create"
                            className="inline-flex items-center px-4 py-3 mb-5 font-semibold tracking-wider text-white bg-red-700 rounded text-md hover:bg-red-900"
                        >
                            <img src={addIcon} alt="Add New"></img>
                            <div className="pl-2 ml-2 border-l-2 border-white">
                                New
                            </div>
                        </Link>
                    )}
                    {this.state.canView === true && (
                        <Table
                            name="news"
                            columns={columns}
                            data={data}
                            actions
                            editAction={(id) => this.editItem(id)}
                            deleteAction={(id) => {
                                this.showModal(id);
                            }}
                            approvalAction={(id) => this.approvalItem(id)}
                            canCRUD={this.state.canCRUD}
                            isAdmin={getIsAdmin()}
                            isLoading={loading}
                            className="mb-6"
                        />
                    )}

                    {/* only show if user can view */}
                    {this.state.canView === true &&
                        newsList?.meta?.last_page > 1 && (
                            <div className="flex justify-end w-full">
                                <Pagination
                                    current={newsList?.meta?.current_page ?? 1}
                                    onChange={(current) =>
                                        this.fetchNews({
                                            current: current,
                                            sort: this.state.selectedOption
                                                .value,
                                            keyword: this.state.searchKeyword,
                                        })
                                    }
                                    total={newsList?.meta?.total}
                                    showTitle={false}
                                />
                            </div>
                        )}

                    <Modal show={this.state.show} handleClose={this.hideModal}>
                        <div className="text-center">
                            <p className="px-8 text-gray-500">
                                Do you really want to delete this news? This
                                process cannot be undone
                            </p>
                            <div className="p-3 mt-5 space-x-4 text-center md:block">
                                <button
                                    onClick={this.hideModal}
                                    className="px-5 py-2 mb-2 text-sm font-medium tracking-wider text-gray-600 bg-white border rounded shadow-sm md:mb-0 hover:bg-gray-100"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={this.deleteItem}
                                    className="px-5 py-2 mb-2 text-sm font-medium tracking-wider text-white bg-red-700 border border-red-500 rounded shadow-sm md:mb-0 hover:bg-red-900"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    const { newsDelete, newsList, loading } = state.news;
    return {
        newsDelete,
        newsList,
        loading,
    };
}

export default connect(mapStateToProps)(Index);
