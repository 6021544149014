import React, { Component } from 'react';
import Header from '../../../component/Header';
import Pagination from '../../../component/Pagination';
import {Link} from 'react-router-dom';
import Modal from '../../../component/Modal'

import addIcon from '../../../assets/icon_add.svg';
import editIcon from '../../../assets/icon_edit.svg';
import trashIcon from '../../../assets/icon_trash.svg';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Breadcrumb from '../../../component/Breadcrumb';
import Layout from '../../Layout';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            items: [],
            divisions: [],
            selectedDivision: 1,
            currentPage: 1,
            totalPages: null,
            deleteId: null,
            authorized: true,
            canCRUD: false,
            canView: false,
            isLoading: false,
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    //fetch data on mount
    componentDidMount() {
        //check if user can view or crud at this section
        this.setState({ canCRUD: ((localStorage.getItem('permissions')).indexOf('can_edit_user') > -1) })
        this.setState({ canView: ((localStorage.getItem('permissions')).indexOf('can_view_user') > -1) })
        axios({
            method: 'GET',
            url:`${process.env.REACT_APP_BASE_API}/api/division/all`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        })
        .then(response => {
            if(response.data.code !== 401){
                this.setState({ divisions: response.data.data })
                this.changeDivision(response.data.data[0].id)
            }else{
                this.setState({ divisions: [] })
            }
            
        })
        .catch((error) => {
            console.log(error);
        })
        this.fetchUser()
    };

    deleteItem = () => {
            axios({
                method: "DELETE",
                url: `${process.env.REACT_APP_BASE_API}/api/admin/user/`,
                data: {"user": this.state.deleteId},
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            })
            .then(response => {
                if(response.data.code === 401){
                    this.setState({ show: false })
                    this.unauthorized();
                }else{
                    //when delete is successful hide modal, and filter deleted item from table
                    this.setState({ show: false, deleteId: null, items: this.state.items.filter(item => item.id !== this.state.deleteId) });
                    this.success();
                }
            })
            .catch(function (response) {
                console.log(response);
            })
        
    };

    success = () => toast.success("User has been deleted", {
        position: toast.POSITION.TOP_CENTER
    });

    unauthorized = () => toast.warning("You aren't authorized to do this", {
        position: toast.POSITION.TOP_CENTER
    });

    //fetch new data when change page
    onPageChanged = (data) => {
        if(this.state.authorized === true){
            const { currentPage } = data;
            axios({
                method: 'GET',
                url:`${process.env.REACT_APP_BASE_API}/api/admin/user?page=${currentPage}&division_id=${this.state.selectedDivision}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            })
            .then(response => {
                this.setState({ items: response.data.data, currentPage: response.data.meta.current_page, totalPages: response.data.meta.total });
            });
        }else{
            this.setState({ items: [], currentPage: 1, totalPages: 1 })
        }
        
    };

    //set state on show modal
    showModal = (id) => {
        this.setState({ show: true, deleteId: id });
    };

    changeDivision = async (id) => {
        //protects too many requests for the same from the user
        if (this.state.selectedDivision === id) return

        await this.setState({
            selectedDivision:id,
         }); 
        this.fetchUser()
    }

    //fetch user
    fetchUser = async () => {
        this.setState({isLoading: true})
        axios({
            method: 'GET',
            url:`${process.env.REACT_APP_BASE_API}/api/admin/user?division_id=${this.state.selectedDivision}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        })
        .then(response => {
            this.setState({isLoading: false})
            this.setState({ items: response.data.data, currentPage: response.data.meta.current_page, totalPages: response.data.meta.total })
        })
        .catch((error) => {
            console.log(error);
        })
    }

    //set state on hide modal
    hideModal = () => {
        this.setState({ show: false, deleteId: null });
    };

    render() {
        const { items, totalPages, divisions } = this.state;
        if (totalPages === null) return null;
        const breadcrumb = [
            { pageTitle: 'Home', pageHref: '/admin' },
            { pageTitle: 'User', pageHref: '' },
        ]

        return (
            <Layout>
                <ToastContainer />
                <div className="min-h-screen p-12">
                    <Breadcrumb data={breadcrumb} />
                    <h1 className="mb-5 text-xl font-bold">
                        Admin User
                    </h1>
                    <div className="mb-5">
                        <Link to="/admin/user/create" className="inline-flex items-center px-4 py-3 font-semibold tracking-wider text-white bg-red-700 rounded text-md hover:bg-red-900">
                            <img src={addIcon} alt="Add New"></img>
                            <div className="pl-2 ml-2 border-l-2 border-white">New</div>
                        </Link>

                    </div>
                    <div className="py-4 bg-white shadow-lg card">
                        {/* only show if user can crud */}
                        {this.state.canCRUD === true &&
                        <div className="flex items-center max-w-full px-6 py-3 my-4 overflow-y-auto bg-gray-200 card-ribbon flex-nowrap">
                            {divisions.map(division => (
                                <button className={`p-3 mr-3 bg-white rounded border w-24 division-card  ${division.id == this.state.selectedDivision ? "bg-red-500 text-white" : ""}`} onClick={ () => {this.changeDivision(division.id)}}>
                                    {division.name}
                                </button>
                            ))}
                        </div>
                        }
                        <div className='w-full px-4 overflow-x-auto'>
                            {/* only show if user can view */}
                            {this.state.canView === true &&
                            <table className='w-full mx-auto overflow-hidden bg-white divide-y divide-gray-300 whitespace-nowrap'>
                                <thead className="bg-gray-50">
                                    <tr className="text-left text-gray-600">
                                        <th className="hidden px-6 py-4 text-sm font-semibold uppercase lg:table-cell">
                                            Email
                                        </th>
                                        <th className="hidden px-6 py-4 text-sm font-semibold uppercase lg:table-cell">
                                            Nama
                                        </th>
                                        <th className="hidden px-6 py-4 text-sm font-semibold uppercase lg:table-cell">
                                            Role
                                        </th>
                                        <th className="hidden px-6 py-4 text-sm font-semibold uppercase lg:table-cell">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {this.state.isLoading ? (
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                <div className="flex items-center justify-center p-3 bg-transparent h-52">
                                                    <svg
                                                        className="w-10 h-10 text-gray-500 animate-spin"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        ></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {items.map(item => (
                                                <tr key={item.id} className="flex-row flex-wrap mb-10 lg:table-row lg:flex-row lg:flex-no-wrap lg:mb-0">
                                                    <td className="relative block px-6 py-8 lg:py-4 lg:table-cell lg:static">
                                                    <span className="absolute top-0 left-0 px-2 py-1 text-xs font-bold uppercase bg-blue-200 lg:hidden">Email</span>
                                                        <div className="flex items-center space-x-3">
                                                            <div>
                                                                <p className="">
                                                                    {item.email}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="relative block px-6 py-8 lg:py-4 lg:table-cell lg:static">
                                                    <span className="absolute top-0 left-0 px-2 py-1 text-xs font-bold uppercase bg-blue-200 lg:hidden">Nama</span>
                                                        {item.name}
                                                    </td>
                                                    <td className="relative block px-6 py-8 lg:py-4 lg:table-cell lg:static">
                                                    <span className="absolute top-0 left-0 px-2 py-1 text-xs font-bold uppercase bg-blue-200 lg:hidden">Role</span>
                                                        {item.division ? item.division.name : ''}
                                                    </td>
                                                    {/* only show if user can crud */}
                                                    {this.state.canCRUD === true &&
                                                    <td className="flex px-6 py-8 text-center">
                                                        <span className="absolute top-0 left-0 px-2 py-1 text-xs font-bold uppercase bg-blue-200 lg:hidden">Action</span>
                                                        <Link to={`/admin/user/${item.id}`}>
                                                            <img src={editIcon} alt="Edit"></img>
                                                        </Link>
                                                        <button className="ml-2" onClick={ () => {this.showModal(item.id)}}>
                                                            <img src={trashIcon} alt="Remove"></img>
                                                        </button>
                                                    </td>
                                                    }
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                </tbody>
                            </table>
                            }
                       </div>
                        <Modal show={this.state.show} handleClose={this.hideModal}>
                            <div className="text-center">
                                <p className="px-8 text-gray-500">Do you really want to delete this user? This process cannot be undone</p>
                                <div className="p-3 mt-5 space-x-4 text-center md:block">
                                    <button onClick={this.hideModal} className="px-5 py-2 mb-2 text-sm font-medium tracking-wider text-gray-600 bg-white border rounded shadow-sm md:mb-0 hover:bg-gray-100">
                                        Cancel
                                    </button>
                                    <button onClick={this.deleteItem} className="px-5 py-2 mb-2 text-sm font-medium tracking-wider text-white bg-red-700 border border-red-500 rounded shadow-sm md:mb-0 hover:bg-red-900">Delete</button>
                                </div>
                            </div>
                        </Modal>
                        {!this.state.isLoading && (
                            <>
                                {/* only show if user can view */}
                                {this.state.canView === true &&
                                    <div className="m-5">
                                        <Pagination totalRecords={totalPages} pageLimit={10} pageNeighbours={1} onPageChanged={this.onPageChanged} />
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
            </Layout>
        )
    }
}

export default Index;