import { createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { addNews, deleteNews, getAllNews, getLatestNews, getListNews, getRandomNews } from "../actions/news";

const initialState = {
    loading: false,
    newsData: [],
    newsList: {},
    newsRandom: [],
    newsLatest: {},
    newsDelete: {},
    newsAdd: {},
    error: null,
    success: false,
};

const newsSlice = createSlice({
    name: "news",
    initialState,
    reducers: {
        addItem(state, action) {
            state.newsList.push(action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllNews.fulfilled, (state, { payload }) => {
                state.newsData = payload.data.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                );
            })
            .addCase(getListNews.fulfilled, (state, { payload }) => {
                state.newsList = payload;
            })
            .addCase(getRandomNews.fulfilled, (state, { payload }) => {
                state.newsRandom = payload;
            })
            .addCase(getLatestNews.fulfilled, (state, { payload }) => {
                state.newsLatest = payload;
            })
            .addCase(deleteNews.fulfilled, (state, { payload }) => {
                state.newsDelete = payload;
            })
            .addCase(addNews.fulfilled, (state, { payload }) => {
                state.newsAdd = payload;
            })
            .addMatcher(isPending, (state) => {
                state.loading = true
            })
            .addMatcher(isFulfilled, (state) => {
                state.loading = false
            })
            .addMatcher(isRejected, (state, {payload}) => {
                state.loading = false;
                state.error = payload;
            })
    },
});

export default newsSlice.reducer;
