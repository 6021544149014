import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api.service";

export const login = createAsyncThunk("auth", async (arg) => {
    const { data } = await api.post(
        `/api/auth/login`, arg.data
    );

    return data;
});

export const getUser = createAsyncThunk("auth/user", async () => {
    const { data } = await api.get(
        `/api/user`
    );
    
    return data;
});