import React from 'react'
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

const Breadcrumb = (props) => {
  return (
    <nav aria-label="breadcrumb" className="mb-8">
			<ol
				className={['flex flex-wrap list-none text-sm', props.className].join(' ')}
			>
				{props.data.map((item, index) => (
					<li
						key={`breadcrumb-${index}`}
						className={`breadcrumb-item before:bg-slate-900 before:mx-2 first:before:content-none${
							index === props.data.length - 1 ? ' text-[#c22125] font-semibold' : ''
						}`}
					>
						{index === props.data.length - 1 ? (
							item.pageTitle
						) : (
							<Link className="font-semibold text-slate-500 hover:underline" to={item.pageHref}>
								{item.pageTitle}
							</Link>
						)}
					</li>
				))}
			</ol>
		</nav>
	);
}

Breadcrumb.propTypes = {
	data: propTypes.array,
	className: propTypes.string,
};

export default Breadcrumb