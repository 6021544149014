import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
}

class Pagination extends Component {
    constructor(props) {
        super(props);
        const { totalRecords = null, pageLimit = 30, pageNeighbours = 1 } = props;
        
        this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;
        this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;

        // pageNeighbours can be: 0, 1 or 2
        this.pageNeighbours = typeof pageNeighbours === 'number'
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;

        this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

        this.state = { currentPage: 1 };


    }

    
    
    /**
     * Let's say we have 10 pages and we set pageNeighbours to 2
     * Given that the current page is 6
     * The pagination control will look like the following:
     *
     * (1) < {4 5} [6] {7 8} > (10)
     *
     * (x) => terminal pages: first and last page(always visible)
     * [x] => represents current page
     * {...x} => represents page neighbours
     */
    fetchPageNumbers = () => {
        const totalPages = this.totalPages;
        const currentPage = this.state.currentPage;
        const pageNeighbours = this.pageNeighbours;

        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = (this.pageNeighbours * 1) + 1;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
        const startPage = Math.max(2, currentPage - pageNeighbours);
        const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
        let pages = range(startPage, endPage);

        const showLeft = currentPage < 3;
        const beforeEnd = currentPage === (totalPages - 1)
        switch (true) {

            case(showLeft && currentPage === 1):{
                const extraPages = currentPage + 2;
                pages = [1, ...pages, extraPages]
                break;
            }

            case(showLeft):{
                pages = [1, ...pages]
                break;
            }

            case(!showLeft && !beforeEnd && currentPage !== totalPages):
                default: {
                    pages = [...pages]
                    break;
                }
            

            case(beforeEnd):{
                pages = [...pages, totalPages]
                break;
            }

            case(currentPage === totalPages):{
                const extraPages = currentPage - 2;
                pages = [extraPages, ...pages, totalPages]
                break;
            }
        }

        /**
         * hasLeftSpill: has hidden pages to the left
         * hasRightSpill: has hidden pages to the right
         * spillOffset: number of hidden pages either to the left or to the right
         */
        // const hasLeftSpill = startPage > 2;
        // const hasRightSpill = (totalPages - endPage) > 1;
        // const spillOffset = totalNumbers - (pages.length + 1);

        // switch (true) {
        //     // handle: (1) < {5 6} [7] {8 9} (10)
        //     case (hasLeftSpill && !hasRightSpill): {
        //     const extraPages = range(startPage - spillOffset, startPage - 1);
        //     pages = [...pages, totalPages];
        //     break;
        //     }

        //     // handle: (1) {2 3} [4] {5 6} > (10)
        //     case (!hasLeftSpill && hasRightSpill): {
        //     const extraPages = range(endPage + 1, endPage + spillOffset);
        //     console.log(extraPages);
        //     pages = [1, ...pages];
        //     break;
        //     }

        //     // handle: (1) < {4 5} [6] {7 8} > (10)
        //     case (hasLeftSpill && hasRightSpill):
        //     default: {
        //     pages = [...pages];
        //     break;
        //     }
        // }

        return [...pages];
        }

        return range(1, totalPages);
    }

    render() {
        // if (!this.totalRecords || this.totalPages === 0) return null;
        const { currentPage } = this.state;
        const pages = this.fetchPageNumbers();
    
        return (
        <Fragment>
            <nav aria-label="Division">
                <ul className="flex flex-row items-center justify-end pagination">
                    <li className="page-item">
                        <button className="px-3 py-2 page-link disabled:opacity-50 " disabled={currentPage <= 1} href="#" aria-label="First" onClick={this.handleClick(1)}>
                            <span aria-hidden="true">First</span>
                            <span className="sr-only">First</span>
                        </button>
                    </li>
                    <li className="px-3 py-2 page-item">
                        <button className="page-link disabled:opacity-50" disabled={currentPage <= 1} href="#" aria-label="Previous" onClick={this.handleMoveLeft}>
                            <span aria-hidden="true">Prev</span>
                            <span className="sr-only">Previous</span>
                        </button>
                    </li>
                    { pages.map((page, index) => {
        
                    return (
                        <li key={index} className={`page-item${ currentPage === page ? ' active bg-red-700 focus:outline-none active:outline-none text-white rounded' : ' text-gray-300'}`}>
                            <button className="px-3 py-2 page-link focus:outline-none active:outline-none" href="#" onClick={ this.handleClick(page) }>{ page }</button>
                        </li>
                    );
                    
                    }) }
                    <li className="page-item">
                        <button className="px-3 py-2 page-link" disabled={currentPage >= this.totalPages} href="#" aria-label="Next" onClick={this.handleMoveRight}>
                            <span aria-hidden="true">Next</span>
                            <span className="sr-only">Next</span>
                        </button>
                    </li>
                    <li className="page-item">
                        <button className="px-3 py-2 page-link" disabled={currentPage >= this.totalPages} href="#" aria-label="Last" onClick={this.handleClick(this.totalPages)}>
                            <span aria-hidden="true">Last</span>
                            <span className="sr-only">Last</span>
                        </button>
                    </li>
                </ul>
            </nav>
        </Fragment>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        this.totalRecords = typeof this.props.totalRecords === 'number' ? this.props.totalRecords : 0;
        this.pageLimit = typeof this.props.pageLimit === 'number' ? this.props.pageLimit : 30;
        this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);
        if(prevProps.totalRecords !== this.totalRecords){
            this.gotoPage(1);
        }
    }

    componentDidMount() {
        this.gotoPage(1);
    }
    
    gotoPage = page => {
        const { onPageChanged = f => f } = this.props;
        const currentPage = Math.max(0, Math.min(page, this.totalPages));
        const paginationData = {
        currentPage,
        totalPages: this.totalPages,
        pageLimit: this.pageLimit,
        totalRecords: this.totalRecords
        };
        return this.setState({ currentPage }, () => onPageChanged(paginationData));
    }
    
    handleClick = page => evt => {
        evt.preventDefault();
        this.gotoPage(page);
    }
    
    handleMoveLeft = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - 1);
    }
    
    handleMoveRight = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + 1);
    }
}

Pagination.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number,
    pageNeighbours: PropTypes.number,
    onPageChanged: PropTypes.func,
};

export default Pagination;