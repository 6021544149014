import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

function IdleTimer({
    onIdle,
    idleTime = 1,
}) {
    const [isIdle, setIsIdle] = useState();

    const handleOnIdle = () => {
        setIsIdle(true);
        onIdle();
    };
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * idleTime,
        onIdle: handleOnIdle,
        debounce: 500,
    });
    return {
        getRemainingTime,
        getLastActiveTime,
        isIdle,
    };
}

export default IdleTimer;
