import axios from "axios";
import { getToken } from "./storage.service";

const main = axios.create({
	baseURL: `${process.env.REACT_APP_BASE_API}`,
});

main.interceptors.request.use(
	function (AxiosRequestConfig) {
		// Do something before request is sent
		const userToken = `Bearer ${getToken() || ''}`
		AxiosRequestConfig.headers = {
			Authorization: userToken,
			Accept: 'application/json',
			'Refferer-Policy': 'no-referrer-when-downgrade'
		};

		return AxiosRequestConfig;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

main.interceptors.response.use(
	response => response,
	error => {
		if (error?.response?.status === 401) {
			window.location.href = "/login";
		}
	}
);

export default main
