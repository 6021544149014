import { Component } from "react";
import { connect } from "react-redux";
import ListItem from "../../component/ListItem";
import Loading from "../../component/Loading";
import SectionHeader from "../../component/SectionHeader";
import { getAllNews } from "../../actions/news";

class IndexNews extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        if (this.props.newsData.length === 0) dispatch(getAllNews({ approval: 4 }));
    }
    render() {
        const { newsData, loading } = this.props;
        const newsDataSlice = newsData.slice(0, 5);

        return (
            <div className="w-8/12">
                <SectionHeader title="News" link="/news" />
                {loading ? (
                    <div className="flex items-center justify-center mt-5">
                        <Loading />
                    </div>
                ) : (
                    <ListItem
                        data={newsDataSlice}
                        itemLink="/news"
                        name="News"
                        className="h-48"
                        contentRow={3}
                        withReadMore={false}
                    />
                )}
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { newsData, loading } = state.news;
    return {
        newsData,
        loading,
    };
}
export default connect(mapStateToProps)(IndexNews);
