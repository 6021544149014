import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const BannerItem = (props) => {
    const { name } = props;
    const removeImage = (str) => {
        let content = str?.includes("img");
        let removeNbsp = str?.replace(/&nbsp;/g, " ");
        let newStr = removeNbsp?.replace(/<\/?[^>]+(>|$)/g, "");
        if (content) {
            const replaceImage = newStr?.replace(
                /<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
                ""
            );
            return replaceImage;
        }
        return newStr;
    };
    return (
        <>
            {props.loading ? (
                <div className="animate-pulse flex w-full h-[400px] mt-10 rounded-md overflow-hidden">
                    <div className="w-6/12 bg-gray-400" />
                    <div className="w-6/12 py-14 pl-12 pr-7 bg-[#f4f4f4]">
                        <div className="flex flex-col gap-4 mb-6">
                            <div className="w-6/12 h-5 mb-2 bg-gray-400 rounded-full" />
                            <div className="flex flex-col gap-2 mb-3">
                                <div className="bg-gray-400 rounded-full h-7 w-12/12" />
                                <div className="w-11/12 bg-gray-400 rounded-full h-7" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="h-4 bg-gray-300 rounded-full w-12/12" />
                                <div className="h-4 bg-gray-300 rounded-full w-12/12" />
                                <div className="h-4 bg-gray-300 rounded-full w-12/12" />
                                <div className="w-11/12 h-4 bg-gray-300 rounded-full" />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex w-full h-[400px] mt-10 rounded-md overflow-hidden">
                    <div className="w-6/12 flex justify-center items-center bg-[#f4f4f4]">
                        <img
                            src={props?.data?.image}
                            alt={props?.data?.title}
                            className="max-h-full max-w-full"
                        ></img>
                    </div>
                    <div className="w-6/12 bg-[#f4f4f4] py-14 pl-12 pr-7 text-[#385f80]">
                        <div className="flex flex-col gap-4 mb-6">
                            <div className="flex gap-2 mb-2 text-sm">
                                <strong>Latest {name ? name : ""}</strong>
                                <span>•</span>
                                <span>
                                    {props?.data?.user?.name} /{" "}
                                    {moment(props?.data?.created_at).fromNow()}
                                </span>
                            </div>
                            <h1 className="overflow-hidden text-[26px] font-bold text-ellipsis line-clamp-2 group-hover:underline">
                                {props?.data?.title}
                            </h1>
                            <div className="relative w-full overflow-hidden">
                                <p
                                    className="overflow-hidden text-sm leading-7 break-words text-ellipsis line-clamp-3"
                                    name="content"
                                    dangerouslySetInnerHTML={{
                                        __html: removeImage(
                                            props?.data?.content
                                        ),
                                    }}
                                ></p>
                            </div>
                        </div>
                        <Link
                            to={`${props.itemLink + "/" + props?.data?.id}`}
                            className="flex items-center gap-1 text-sm font-semibold hover:text-[#c22125] w-max"
                        >
                            Read More
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                />
                            </svg>
                        </Link>
                    </div>
                </div>
            )}
        </>
    );
};

export default BannerItem;
