import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FaChevronCircleRight } from "react-icons/fa";

const SectionHeader = ({ title, link, withLink = true }) => {
    return (
        <div className="flex flex-row items-center justify-between border-b-2 border-gray-200">
            <div className="py-3 font-semibold text-blue-900 bg-gray-200 px-11">
                {title}
            </div>
            {withLink && (
                <Link
                    to={link}
                    className="flex flex-row items-center gap-2 px-3 text-sm font-semibold text-gray-600 transition-all duration-300 hover:text-gray-800 hover:gap-3"
                >
                    <span>See All</span>
                    <FaChevronCircleRight />
                </Link>
            )}
        </div>
    );
};

SectionHeader.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
    withLink: PropTypes.bool,
};

export default SectionHeader;
