import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getDetailNews, getRandomNews } from "../../actions/news";
import ListItem from "../../component/ListItem";
import Loading from "../../component/Loading";
import SectionHeader from "../../component/SectionHeader";
import Layout from "../Layout";

class DetailNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: {},
            randomNews: [],
            isLoading: false,
        };
    }

    getNews = async () => {
        const { dispatch, match } = this.props;
        const params = match.params;
        await dispatch(getDetailNews({ id: params.news_id }))
            .unwrap()
            .then((res) => {
                this.setState({ news: res.data });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    getRandomNews = async () => {
        const { dispatch } = this.props;
        await dispatch(getRandomNews({ limit: 2 }))
            .unwrap()
            .then((res) => {
                this.setState({ randomNews: res.data });
            });
    };

    componentDidMount() {
        this.getNews();
        this.getRandomNews();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params !== prevProps.match.params) {
            this.getNews();
            this.getRandomNews();
        }
    }

    render() {
        const { news } = this.state;
        return (
            <Layout>
                <div className="container px-14">
                    <div className="flex flex-col w-full">
                        {this.props.loading ? (
                            <div className="animate-pulse flex flex-col w-full gap-3 px-10 pt-10 pb-5">
                                <div className="h-80 w-full rounded-lg bg-gray-300"></div>
                                <div className="flex items-center justify-between w-full text-[#385f80]">
                                    <div className="w-7/12 h-6 bg-gray-300 rounded-full"></div>
                                    <div className="px-6 py-5 text-base bg-gray-200 rounded-md">
                                        <div className="h-4 w-44 bg-gray-300 rounded-full"></div>
                                    </div>
                                </div>
                                <hr />
                                <div className="mt-5 flex flex-col gap-2">
                                    <div className="text-sm leading-7 w-full bg-gray-300 h-3 rounded-full" />
                                    <div className="text-sm leading-7 w-full bg-gray-300 h-3 rounded-full" />
                                    <div className="text-sm leading-7 w-full bg-gray-300 h-3 rounded-full" />
                                    <div className="text-sm leading-7 w-11/12 bg-gray-300 h-3 rounded-full" />
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col w-full gap-3 px-10 pt-10 pb-5">
                                <div className="flex justify-center">
                                    <img
                                        src={news.image}
                                        alt={news.title}
                                        className="rounded-lg w-fit"
                                    />
                                </div>
                                <div className="flex items-center justify-between w-full text-[#385f80]">
                                    <h1 className="w-8/12 text-2xl font-bold">
                                        {news.title}
                                    </h1>
                                    <div className="px-6 py-5 text-base bg-gray-200 rounded-md">
                                        {news.user?.name} /{" "}
                                        {moment(news.created_at).format("LL")}
                                    </div>
                                </div>
                                <hr />
                                <div
                                    className="text-sm leading-7 text-[#2a374d] mt-5 w-full break-words"
                                    dangerouslySetInnerHTML={{
                                        __html: news.content,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <hr />
                <div className="container my-5 px-14">
                    <SectionHeader title="Other News" withLink={false} />
                    <div className="flex justify-between">
                        {this.props.loading ? (
                            <div className="w-full flex items-center justify-center mt-5">
                                <Loading />
                            </div>
                        ) : (
                            <ListItem
                                name="news"
                                data={this.state.randomNews}
                                itemLink="/news"
                                withReadMore={false}
                                className="w-6/12 h-36"
                                contentRow={2}
                            />
                        )}
                    </div>
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    const { loading } = state.news;
    return {
        loading,
    };
}

export default connect(mapStateToProps)(DetailNews);
