import { configureStore } from '@reduxjs/toolkit'
import newsReducer from '../reducers/newsSlice'
import announcementReducer from '../reducers/announcementSlice'
import authReducer from '../reducers/authSlice'
import eventReducer from '../reducers/eventSlice'
import chapterPageReducer from '../reducers/chapterPageSlice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    news: newsReducer,
    announcement: announcementReducer,
    event: eventReducer,
    chapterPage:chapterPageReducer
  },
})
export default store