import React, { useState } from 'react';
import Header from '../../../../component/Header';
import AsyncSelect from 'react-select/async';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import axios from 'axios';
import { useHistory } from 'react-router';
import { Editor } from "@tinymce/tinymce-react";
// Import TinyMce
import tinymce from 'tinymce';
// Default Icons
import "tinymce/icons/default";
// Theme
import "tinymce/themes/silver";
// Plugins
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/advlist";

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Breadcrumb from '../../../../component/Breadcrumb';
import Layout from '../../../Layout';
import { disableOnWheel } from "../../../../utils/DisableOnWheel";

const customStyles = {
    option: (provided, state) => ({
      ...provided
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: 'flex',
      background: '#fff',
      border: '1px solid rgb(209, 213, 219)',
      padding: '2px .1rem'
    }),
    menu: () => ({
    //   none of react-select's styles are passed to <Control />
      padding: '0',
      background: '#fff',
      position: 'absolute',
      top: '100%',
      boxShadow: "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)",
      width: '100%',
      zIndex: '5'
    }),
};

let responseData = [];
//options data for categories select
const categories = [
    {value: '0', label: 'Division Journey'},
    {value: '1', label: 'Division Chapter'}
];
//options data for division select
let options = [];

let sendData = [];

const CreateChapter = () => {

    const history = useHistory();

    const success = () => toast.success("Chapter saved!", {
        position: toast.POSITION.TOP_CENTER,
        onClose: props => history.push("/admin/chapter")
    });

    const unauthorizedChapter = () => toast.warning("You aren't authorized to create chapter", {
        position: toast.POSITION.TOP_CENTER,
    });

    const unauthorizedPage = () => toast.warning("You aren't authorized to create page", {
        position: toast.POSITION.TOP_CENTER,
    });

    const error = (message) => toast.warning(message, {
        position: toast.POSITION.TOP_CENTER,
    });

    //state for error catching from server
    var [err, setErr] = useState({});

    //state if form has error form server
    var [hasError, setHasError] = useState(false);

    // declare fetching division list data
    const fetchData = React.useCallback(() => {
        axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API}/api/division/all`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            })
            .then(response => {
                if(response.data.code !== 401){
                    responseData = response.data.data
                    if (responseData.length > 0) {
                        //restructure response for division select
                        responseData.forEach(division => {
                        let divisionDivide = {}
                        divisionDivide.value = division.id
                        divisionDivide.label = division.name
                        options.push(divisionDivide)
                        })
                    }
                }
            });
    }, []);

    // fetching division list data
    React.useEffect(() => {
        options = []
        fetchData()
    }, [fetchData]);

    const { handleSubmit, register, formState: { errors }, control, setValue, reset, clearErrors,
    setError } = useForm();

    const { fields, append } = useFieldArray({
        control,
        name: "page"
      });

    // append first page
    React.useEffect(() => {
        append()
    }, [append]);

    //append new page
    const addNewpage = () => {
        append()
    };

    //declare onsubmit function, when submitting form
    const onSubmit = (data) => {
        let fd = new FormData();

        fd.append("category", data.category)
        fd.append("division_id", data.division_id)

        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_API}/api/chapter`,
            data: fd,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        })
        .then(function (response){
            //check if authorized to create chapter
            if(response.data.code !== 401){
                let chapterid = response.data.data.id
                breakdownPage(data.page, chapterid);
            }else{
                unauthorizedChapter()
            }
        })
        .catch(function (err) {
            console.log(err);
        })
    }

    //reconstruct page one by one before send to server
    const breakdownPage = async (data, id) => {
        let i;
        // let promises = [];
        for(i=0; i < data.length; i++){
            let page = {};
            page.chapter_id = id;
            page.order = data[i].order;
            page.title = data[i].title;
            page.content = data[i].content;
            page.page = i+1;
            await sendData.push(page)

            if(i === data.length - 1){
                sendPage()
            }
        }
    }

    //fn to send page
    const sendPage = (i) => {
        let num = i || 0;
        if(num < sendData.length){
            axios ({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_API}/api/chapter/page`,
                data: sendData[num],
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            })
            .then(response => {
                //check if authorized to create chapter page
                if(response.data.code !== 401){
                    //loop to sendpage again
                    sendPage(num+1)
                    if( num === sendData.length - 1){
                        success();
                        reset();
                        append();
                        sendData = [];
                    }
                } else {
                    unauthorizedPage();
                }
            })
            .catch(error => {
                if (error.response.data.errors) {
                    setErr(error.response.data.errors);
                } else {
                    setErr(error);
                }
                setHasError(true);
            });

        }
    }

    const handleChange = (change) => {
        setValue("division_id", change.value);
    };

    const handleChangeCategory = (change) => {
        setValue("category", change.value);
    };

    //function to render error from server
    function ErrorComponent() {
        return Object.keys(err).map((visit, index) =>
            <ul className="list-none">
                <li className="p-3 mb-4 text-white bg-red-500 radius-sm" key={index}>{err[visit]}</li>
            </ul>
        )
    };

    const breadcrumb = [
        { pageTitle: 'Home', pageHref: '/admin' },
        { pageTitle: 'Chapter', pageHref: '/admin/chapter' },
        { pageTitle: 'Create', pageHref: '' },
    ]

    return (
        <Layout>
            <ToastContainer />
            <div className="min-h-screen p-12">
                <Breadcrumb data={breadcrumb} />
                <h1 className="mb-5 text-xl font-bold">
                    Admin Chapter Create
                </h1>
                <div className="py-6 bg-gray-200 shadow-lg card">
                    {hasError && <ErrorComponent></ErrorComponent>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="px-4 mb-6">
                            <label htmlFor="category" className="block mb-2 text-sm font-semibold text-blue-900">Category</label>
                            <Controller
                                control={control}
                                name="category"
                                value='0'
                                render={({ onChange, value }) => (
                                    <AsyncSelect
                                        className="w-full md:w-1/4"
                                        styles={customStyles}
                                        defaultOptions={categories} {...register("category", { required: 'Please choose category' })}
                                        value={categories.find(c => c.value === value)}
                                        onChange={handleChangeCategory}
                                        />
                                )}
                            />
                            {errors?.category && <div className="mt-2 text-xs font-semibold text-red-500">{errors.category.message}</div>}
                        </div>
                        <div className="px-4 mb-6">
                            <label htmlFor="division" className="block mb-2 text-sm font-semibold text-blue-900">Role</label>
                            <Controller
                                control={control}
                                name="division_id"
                                render={({ onChange, value }) => (
                                    <AsyncSelect
                                        className="w-full md:w-1/4"
                                        styles={customStyles}
                                        defaultOptions={options} {...register("division_id", { required: 'Please choose role' })}
                                        value={options.find(c => c.value === value)}
                                        onChange={handleChange}
                                        />
                                )}
                            />
                            {errors?.division_id && <div className="mt-2 text-xs font-semibold text-red-500">{errors.division_id.message}</div>}
                        </div>
                        {fields.map((item, index) => (
                            <div key={item.id}>
                            <div className="py-3 mb-6 bg-white">
                                <div className="px-4 text-lg font-bold text-blue-900">
                                        Page {index + 1}
                                </div>
                            </div>
                            <div className="px-4 mb-6">
                                <label htmlFor="order" className="block mb-2 text-sm font-semibold text-blue-900">Order</label>
                                <input
                                    min={1}
                                    type="number"
                                    name="order"
                                    id="order"
                                    onWheel={disableOnWheel}
                                    {...register(`page.${index}.order`)}
                                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 md:w-1/6 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300" />
                            </div>
                            <div className="px-4 mb-6">
                                <label htmlFor="title" className="block mb-2 text-sm font-semibold text-blue-900">Chapter Title</label>
                                <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    {...register(`page.${index}.title`, {
                                        required: 'Title is required'
                                    })}
                                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 md:w-1/2 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300" />
                                {errors?.['page']?.[index]?.['title'] && <div className="mt-2 text-xs font-semibold text-red-500">{errors?.['page']?.[index]?.['title']?.['message']}</div>}
                            </div>
                            <div className="w-full pl-4 mb-6 md:w-11/12">
                                <label htmlFor="content" className="block mb-2 text-sm font-semibold text-blue-900">Content</label>
                                <Controller
                                    name="content"
                                    control={control}
                                    defaultValue=""
                                    render={({ onChange, value }) => (
                                        <Editor
                                            {...register(`page.${index}.content`, { required: 'Please fill the content' })}
                                            cloudChannel="dev"
                                            name="content"
                                            init={{
                                            selector: "textarea",
                                            height : "480",
                                            plugins: "link image textpattern lists advlist anchor",
                                            menubar: 'file edit view insert format',
                                            toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link image',
                                            statusbar: false,
                                            /* enable title field in the Image dialog*/
                                            image_title: true,
                                            /* enable automatic uploads of images represented by blob or data URIs*/
                                            automatic_uploads: true,
                                            /*
                                            URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                            images_upload_url: 'postAcceptor.php',
                                            here we add custom filepicker only to Image dialog
                                            */
                                            file_picker_types: 'image',
                                            /* and here's our custom image picker*/
                                            file_picker_callback: function (cb, value, meta) {
                                            var input = document.createElement('input');
                                            input.setAttribute('type', 'file');
                                            input.setAttribute('accept', ['image/webp', 'image/png', 'image/jpg', 'image/jpeg']);


                                            /*
                                                Note: In modern browsers input[type="file"] is functional without
                                                even adding it to the DOM, but that might not be the case in some older
                                                or quirky browsers like IE, so you might want to add it to the DOM
                                                just in case, and visually hide it. And do not forget do remove it
                                                once you do not need it anymore.
                                            */

                                            input.onchange = function () {
                                                var file = this.files[0];
                                                clearErrors(`page.${index}.content`);

                                                var filePath = this.value
                                                var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp)$/i;
                                                if (!allowedExtensions.exec(filePath)){
                                                    setError(`page.${index}.content`, {
                                                        message: "Only JPEG, JPG, PNG & WEBP allowed!",
                                                    });
                                                    error(errors?.['page']?.[index]?.['content'] && errors?.['page']?.[index]?.['content']?.['message'])
                                                    return;
                                                }

                                                if (file.size > 2000000) {
                                                    setError(`page.${index}.content`, {
                                                        message: "Maximum image size 2Mb",
                                                    });
                                                    error(errors?.['page']?.[index]?.['content'] && errors?.['page']?.[index]?.['content']?.['message'])
                                                    return;
                                                }

                                                var reader = new FileReader();
                                                reader.onload = function () {
                                                /*
                                                    Note: Now we need to register the blob in TinyMCEs image blob
                                                    registry. In the next release this part hopefully won't be
                                                    necessary, as we are looking to handle it internally.
                                                */
                                                var id = 'blobid' + (new Date()).getTime();
                                                var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                                                var base64 = reader.result.split(',')[1];
                                                var blobInfo = blobCache.create(id, file, base64);
                                                blobCache.add(blobInfo);

                                                /* call the callback and populate the Title field with the file name */
                                                cb(blobInfo.blobUri(), { title: file.name });
                                                };
                                                reader.readAsDataURL(file);
                                            };

                                                input.click();
                                            },
                                            }}
                                            onEditorChange={e => setValue(`page.${index}.content`, e)}
                                        />
                                    )}
                                    />
                                {errors?.['page']?.[index]?.['content'] && <div className="mt-2 text-xs font-semibold text-red-500">{errors?.['page']?.[index]?.['content']?.['message']}</div>}
                            </div>
                            </div>
                        ))}
                        <div className="flex justify-between w-full pl-4 md:w-11/12">
                            <a href="/admin/chapter" className="w-24 py-2 text-sm font-semibold tracking-wider text-center text-white bg-gray-400 rounded hover:bg-gray-700">Cancel</a>
                            <div>
                                <button type="submit" className="w-24 py-2 mr-3 text-sm font-semibold tracking-wider text-white bg-green-600 rounded hover:bg-green-700">Save</button>
                                <button type="button" onClick={() => addNewpage()} className="w-24 py-2 text-sm font-semibold tracking-wider text-white bg-red-600 rounded hover:bg-red-700">New Page</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default CreateChapter