import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api.service";

export const getAllChapterPagesByChapterId = createAsyncThunk(
    "chapterPage/allByChapterId",
    async (chapterId) => {
        const { data } = await api.get(
            `/api/chapter/page/all?chapter_id=${chapterId}`
        );

        return data;
    }
);

export const getAllChapterPagesByDivisionAndCategory = createAsyncThunk(
    "chapterPage/allByDivisionAndCategory",
    async ({divisionId,categoryId}) => {
        const { data } = await api.get(
            `/api/chapter/page/all?chapter_category=${categoryId}&division_id=${divisionId}`
        );

        return data;
    }
);
