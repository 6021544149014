import React from "react";

function Footer() {
    return (
        <footer className="flex items-center justify-center w-full py-6 bg-gray-200 text-[#385f80]">
            <span className="text-xs sm:text-center">
                Copyright © AQI, 2022. All Right Reserved.
            </span>
        </footer>
    );
}

export default Footer;
