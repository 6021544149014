import React, { useState } from "react";
import Header from "../../../../component/Header";
import axios from "axios";
import { useParams, useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Layout from "../../../Layout";

const InfoDivision = () => {
    const history = useHistory();
    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        getValues,
        clearErrors,
        setError,
    } = useForm();

    const [picture, setPicture] = useState(null);
    //state for new image url
    const [imgData, setImgData] = useState(null);
    //state for fetched image from server
    const [oldImg, setOldImg] = useState(null);

    const { id } = useParams();

    const success = () =>
        toast.success("Role saved!", {
            position: toast.POSITION.TOP_CENTER,
            onClose: (props) => history.push("/admin/division"),
        });

    const unauthorizedSee = () =>
        toast.warning("You aren't authorized to view this", {
            position: toast.POSITION.TOP_CENTER,
        });

    const unauthorized = () =>
        toast.warning("You aren't authorized to do this", {
            position: toast.POSITION.TOP_CENTER,
        });

    const handleChange = (e) => {
        clearErrors("image");

        let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp)$/i;
        let filePath = e.target.value
        if(!allowedExtensions.exec(filePath)){
            setError("image", {
                message: "Only JPEG, JPG, PNG & WEBP allowed!",
            });
            setImgData(null);
            return;
        }

        if (e.target?.files[0]?.size > 2000000) {
            setError("image", {
                message: "Maximum image size 2Mb",
            });
            setImgData(null);
            return;
        }

        setValue("image", e.target.files[0]);

        setPicture(e.target.files[0]);

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setImgData(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
    };

    const fetchData = React.useCallback(() => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_BASE_API}/api/division/${id}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }).then((response) => {
            if (response.data.code !== 401) {
                setOldImg(response.data.data.image);
                setValue("name", response.data.data.name);
                setValue("description", response.data.data.description);
            } else {
                unauthorizedSee();
            }
        });
    }, []);

    const onSubmit = (data) => {
        let fd = new FormData();
        fd.append("_method", "PUT");
        fd.append("name", data.name);
        fd.append("description", data.description);
        fd.append("image", data.image ? data.image : "");

        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_API}/api/division/${id}`,
            data: fd,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
            .then(function (response) {
                if (response.data.code !== 401) {
                    success();
                } else {
                    unauthorized();
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                }
            });
    };

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Layout>
            <ToastContainer />
            <div className="min-h-screen p-12">
                <nav
                    className="mb-5 text-xs font-bold text-black"
                    aria-label="Breadcrumb"
                >
                    <ol className="inline-flex p-0 list-none">
                        <li className="flex items-center">
                            <a href="/admin/division">Division</a>
                            <svg
                                className="w-3 h-3 mx-3 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                            >
                                <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
                            </svg>
                        </li>
                        <li>
                            <p className="text-gray-500" aria-current="page">
                                Division Info
                            </p>
                        </li>
                    </ol>
                </nav>
                <h1 className="mb-5 text-xl font-bold">Division Info</h1>
                <div className="px-4 py-6 bg-gray-200 shadow-lg card">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="w-full md:w-1/2"
                    >
                        <div className="mb-6">
                            <label
                                htmlFor="division"
                                className="block mb-2 text-sm font-semibold text-blue-900"
                            >
                                Role
                            </label>
                            <input
                                type="text"
                                name="division"
                                id="division"
                                {...register("name", {
                                    required: "Role name is required",
                                })}
                                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md md:w-1/3 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                            />
                            {errors?.name && (
                                <div className="mt-2 text-xs font-semibold text-red-500">
                                    {errors.name.message}
                                </div>
                            )}
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="description"
                                className="block mb-2 text-sm font-semibold text-blue-900"
                            >
                                Description
                            </label>
                            <textarea
                                type="text"
                                {...register("description", {
                                    required: 'Role description is required'
                                })}
                                rows="5"
                                name="description"
                                id="description"
                                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                            ></textarea>
                            {errors?.description && (
                                <div className="text-xs font-semibold text-red-500">
                                    {errors.description.message}
                                </div>
                            )}
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="image"
                                className="text-sm font-semibold text-blue-900 blocks"
                            >
                                Role Image
                            </label>
                            <p className="mb-2 text-xs italic">(Max Size: 2Mb; Extension: jpeg, jpg, png, webp)</p>

                            <input
                                accept="image/webp, image/png, image/jpg, image/jpeg"
                                type="file"
                                name="image"
                                id="image"
                                onChange={handleChange}
                                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                            />
                            {errors?.image && (
                                <div className="mt-2 text-xs font-semibold text-red-500">
                                    {errors.image.message}
                                </div>
                            )}
                        </div>
                        <div className="mb-6">
                            {picture ? (
                                <img src={imgData} alt={getValues.image} />
                            ) : (
                                <img src={oldImg} alt="testa" />
                            )}
                        </div>
                        <div className="flex justify-between">
                            <a
                                href="/admin/division"
                                className="w-24 px-4 py-2 text-sm font-semibold tracking-wider text-center text-white bg-gray-400 rounded hover:bg-gray-700"
                            >
                                Cancel
                            </a>
                            <button className={`w-24 px-4 py-2 text-sm font-semibold tracking-wider text-white rounded ${Object.keys(errors).length === 0 ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-400 cursor-default'}`} disabled={Object.keys(errors).length !== 0}>
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default InfoDivision;
