import { Component } from "react";
import { connect } from "react-redux";
import { getAllAnnouncement } from "../../actions/announcement";
import ListItem from "../../component/ListItem";
import Loading from "../../component/Loading";
import SectionHeader from "../../component/SectionHeader";
import iconAnnouncement from "../../assets/icon_announcement.webp";

class IndexAnnouncement extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        if (this.props.announcementData.length === 0)
            dispatch(getAllAnnouncement({ approval: 4 }));
    }
    render() {
        const { announcementData, loading } = this.props;
        const announcementDataSlice = announcementData.slice(0, 3);

        return (
            <div>
                <div className="relative">
                    <SectionHeader title="Announcement" link="/announcement" />
                    <img
                        src={iconAnnouncement}
                        alt="Announcement"
                        className="absolute -left-[25px] -bottom-[5px] h-16"
                    />
                </div>
                {loading ? (
                    <div className="flex items-center justify-center mt-5">
                        <Loading />
                    </div>
                ) : (
                    <ListItem
                        data={announcementDataSlice}
                        itemLink="/announcement"
                        name="Announcement"
                        withReadMore={false}
                        withContent={false}
                        className="h-24"
                    />
                )}
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { announcementData, loading } = state.announcement;
    return {
        announcementData,
        loading,
    };
}
export default connect(mapStateToProps)(IndexAnnouncement);
