import React, { Component } from "react";
import axios from "axios";
import Layout from "./Layout";
import { getLatestNews } from "../actions/news";
import { connect } from "react-redux";
import Slider from "react-slick";
import BannerItem from "../component/BannerItem";
import Announcement from "./announcement/IndexAnnouncement";
import News from "./news/IndexNews";
import Event from "./event/IndexEvent";
import Modal from "../component/Modal";
import {
    getLatestAnnouncement,
    getPopUpAnnouncement,
} from "../actions/announcement";
import { Link } from "react-router-dom";
class Homepages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            division: {},
            loading: false,
            announcementPopUp: {},
            showModalAnnouncement: false,
        };
        if (
            localStorage.getItem("is_admin") !== "true" &&
            Number(localStorage.getItem("chapter")) !== 0
        ) {
            this.props.history.push(
                "/fresh-detail-division/" + localStorage.getItem("division")
            );
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const AuthStr = "Bearer ".concat(localStorage.getItem("token"));
        const header = {
            headers: {
                Authorization: AuthStr,
            },
        };

        this.setState({
            loading: this.props.loadingNews || this.props.loadingAnnouncement,
        });

        this.getDivision(header);
        dispatch(getLatestNews());
        dispatch(getLatestAnnouncement());
        dispatch(getPopUpAnnouncement())
            .unwrap()
            .then((res) => {
                if (res.status === 'error') return this.setState({ announcementPopUp: {} });

                this.setState({ announcementPopUp: res.data })
            })

        let lastShown = parseInt(localStorage.getItem("lastShown")); //EDIT: Added parseInt
        let maxTime = 1000 * 60 * 60 * 24; //ms (24 hour)
        //if lastShown is undefined or enough time has passed
        if (Boolean(lastShown) === false || lastShown + maxTime <= Date.now()) {
            //store the time to check next time the page is loaded
            this.setState({ showModalAnnouncement: true });
            localStorage.setItem("lastShown", Date.now());
        }
    }

    // fetch division
    getDivision = (header) => {
        this.setState({ loading: true });
        const divisionId = localStorage.getItem("division");
        axios
            .get(
                `${process.env.REACT_APP_BASE_API}/api/division/` + divisionId,
                header
            )
            .then((response) => {
                this.setState({ loading: false });
                this.setState({ division: response.data.data });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    render() {
        const { newsLatest, announcementLatest } = this.props;
        const { loading, announcementPopUp, showModalAnnouncement } =
            this.state;
        const sliderData = [
            { name: "news", data: newsLatest?.data, link: "/news" },
            {
                name: "announcement",
                data: announcementLatest?.data,
                link: "/announcement",
            },
        ];
        // get top five news
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
        };
        return (
            <Layout>
                <div className="container mb-6 px-14">
                    <Slider {...settings}>
                        {sliderData.filter(list => list.data !== undefined).map((item, index) => (
                            <BannerItem
                                key={index}
                                name={item.name}
                                data={item.data}
                                itemLink={item.link}
                                loading={loading}
                            />
                        ))}
                    </Slider>

                    <div className="container flex flex-row w-full gap-12 mt-20">
                        <News />
                        <div className="flex flex-col w-4/12 gap-12">
                            <Announcement />
                            <Event />
                        </div>
                    </div>
                </div>
                {!loading && showModalAnnouncement && Object.keys(announcementPopUp).length > 0 && (
                    <Modal
                        size="lg"
                        show={showModalAnnouncement}
                        handleClose={() =>
                            this.setState({ showModalAnnouncement: false })
                        }
                    >
                        <Link
                            to={`/announcement/${announcementPopUp.id}`}
                            className="group"
                        >
                            <img
                                className="mb-3"
                                src={announcementPopUp?.image}
                                alt={announcementPopUp?.title}
                            />
                            <div className="flex items-center justify-between w-full text-[#385f80]">
                                <h1 className="w-8/12 overflow-hidden text-lg font-bold line-clamp-2 group-hover:underline">
                                    {announcementPopUp?.title}
                                </h1>
                                <div className="px-3 py-2 text-sm font-bold bg-gray-200 rounded-md">
                                    ANNOUNCEMENT!
                                </div>
                            </div>
                        </Link>
                    </Modal>
                )}
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    const { newsData, newsLatest, loadingNews } = state.news;
    const { announcementData, announcementLatest, loadingAnnouncement } =
        state.announcement;
    return {
        newsLatest,
        newsData,
        announcementLatest,
        announcementData,
        loadingNews,
        loadingAnnouncement,
    };
}

export default connect(mapStateToProps)(Homepages);
